export const MOBILE = { id: `mobile`, label: `Mobile Number *` };

export const POLICY_NO = { id: `policyNo`, label: `Policy Number *` };

export const OTP = { id: `otp`, label: `Enter One Time Password` };

export const policy_no_regex = new RegExp(
  `^P/([0-9]{6})/([0-9]{2})/([0-9]{4})/([0-9]{6})$`,
  `i`
);
export const bancs_policy_no_regex = new RegExp(
  `^([0-9]{13,15})$`,`i`
);

export const phone_no_regex = new RegExp(
  /^[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-/\s.]?[0-9]{4}$/
);

export const getPolicyDetails = res => {
  let { policyIdentification, insurers, proposerIdentificationDetail,sessionPolicyToken } = res;
  try {
    return {
      displayCard: true,
      expiryDate: policyIdentification.policyEndDate.split(' ')[0],
      createdDate: policyIdentification.policyStartDate.split(' ')[0],
      body: policyIdentification.policyNumber,
      policyNumberEncrypted: policyIdentification.policyNumberEncrypted,
      headerDescription: policyIdentification.productCode,
      headerTitle: policyIdentification.productName,
      sumInsured: policyIdentification.sumAssured,
      emiPolicy: policyIdentification.installmentYN === 'Y',
      peopleCovered: insurers.policyInsuredDetail,
      dataSourceFrom:res.dataSourceFrom,
      polSysID: policyIdentification.polSysID,
      polSysIDEncrypted: policyIdentification.polSysIDEncrypted,
      mailId: proposerIdentificationDetail.mailId,
      mailIdEncrypted: proposerIdentificationDetail.mailIdEncrypted,
      sessionPolicyToken
    };
  } catch (exception) {
    return {
      displayCard: false
    };
  }
};

export const toggleSelfServiceDialog = 'toggleSelfServiceDialog';

export const CHANGE_STAGE = 'change stage';

export const SET_MOBILE = 'set mobile';

export const SET_POLICY_NO = 'set policy no';

export const SET_PAYLOAD = 'set payload';

export const SET_CAPTCHA = 'set captcha';

export const ERROR_MESSAGES = {
  default: ``,
  network_problem: `Network Problem. Please try again later`,
  invalid_otp: `Invalid OTP`,
  no_policies: `No Policies Found`,
  invalid_mobile: `Invalid Mobile Number`,
  invalid_policy_dob: `Invalid Policy Number/Date of Birth`,
  invalid_date: `Invalid Date`,
  timeout_otp: `You have exceeded the maximum number of OTP entries, please retry after 30 minutes.`,
};

export const STAGES = {
  BASIC_DETAILS: `DOWNLOAD POLICY DOCUMENTS`,
  OTP: `OTP Validation`,
  SEND_EMAIL: `Send Documents to Email`,
  DOWNLOAD_DOCS: `Download Documents Now`
};

export const BasicInitialState = {
  mobile: ``,
  policyNo: ``,
  dob: ``,
  captchaVerified: false,
  loading: false,
  errorMessage: ERROR_MESSAGES.default,
  nextStage: ``
};

export const SelfServiceInitialState = {
  currentStage: STAGES.BASIC_DETAILS,
  BasicInitialState,
  nextStage: ``
};

export const ICMRProtocolInitialState = {
  currentStage: STAGES.BASIC_DETAILS,
  BasicInitialState,
  nextStage: ``
};

export const DOC_TYPES = {
  POLICY_SCHEDULE: `Policy_Schedule`
};

export const ICON_LIST = {
  [STAGES.SEND_EMAIL]: `/email_icon.svg`,
  [STAGES.DOWNLOAD_DOCS]: `/app_download.svg`
};

export const snackBarTimer = 3000;

export const dateSeperator = '-';
