import React from "react";

// nodejs library that concatenates classes
import classNames from "classnames";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import PropTypes from "prop-types";
import GridContainer from "components/Grid/GridContainer";
import aboutUsStyle from "ps-assets/jss/aboutUsStyle.jsx";
import {withPrefix } from "gatsby";

class ClaimStatusContent extends React.Component {
  render() {
    let { classes, data } = this.props;
    var DateOfAdmission= new  Date(data.admissionDate)
    var DateOfIntimation= new  Date(data.dateOfIntimation)
    return (
      <div>
        <div>
          <GridContainer
            className={classNames(classes.mrAuto, classes.mlAuto)}
            style={{ fontSize: `13px` }}
          >
          
            <GridItem xs={12} sm={4} md={4}>
              <div>
              <img src={withPrefix("/megamenu_file_a_grievance.svg")} style={{marginRight: '2px'}} className={classes.claimimage}/>
              <b style={{fontSize: '16px'}} >Primary Details</b>
              <br/>
              <div style={{marginTop: '5%'}}>
              <b>Claim No</b>
              <div style={{ minHeight: 20 }}>{data ? data.claimNo:""}</div>
              </div>
              </div>
              <div style={{marginTop: '10%'}} >
              <img src={withPrefix("/megaMenu-myself.svg")} style={{marginRight: '2px'}} className={classes.claimimage}/>
              <b style={{fontSize: '16px'}}>Patient Details</b>
              <br/>
              <div style={{marginTop: '5%'}}>
              <b>Patient Name</b>
              <div style={{ minHeight: 20 }}>{data ? data.insuredPatientName:""}</div>
              </div>
              </div>
              <div style={{marginTop: '10%'}} >
              <img src={withPrefix("/topHeader-networkHospital.svg")} className={classes.claimimage}/>
              <b style={{fontSize: '16px'}}>Admission Details</b>
              <br/>
              <div style={{marginTop: '5%'}}>
              <b>Date Of Intimation</b>
              <div style={{ minHeight: 20 }}>{data && data.dateOfIntimation ? DateOfIntimation.toLocaleDateString(): "-"}</div>
              </div>
              </div>
              <b>Insured Name</b>
              <div style={{ minHeight: 20 }}>{data ? data.proposerName:""}</div>
              <b>Network/Non-Network</b>
              <div style={{ minHeight: 20 }}>{data ? data.hospitalType:""}</div>
             {/*  <b>SM Name</b>
              <div style={{ minHeight: 20 }}>{data &&  data.smName &&  data.smName.length>0 ? data.smName: "-"}</div>
              <b>Hospital Code</b>
              <div style={{ minHeight: 20 }}>{data &&  data.hospitalCode&&  data.hospitalCode.length>0 ? data.hospitalCode: "-"}</div> */}
              <b>Reason for Admission</b>
              <div style={{ minHeight: 20 }}>{data ? data.reasonForAdmission: ""}</div>
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <div style={{marginTop: '15%'}} >
              <b>ID Card No</b>
              <div style={{ minHeight: 20 }}>{data ? data.healthCardNo: ""}</div>
              </div>
              <div style={{marginTop: '24%'}} >
              <b>Policy-No/Adv Receipt No.</b>
              <div style={{ minHeight: 20 }}>{data ? data.policyNo: ""}</div>
              </div>
              <div style={{marginTop: '24%'}} >
              <b>Hospital Name</b>
              <div style={{ minHeight: 20 }}>{data ? data.hospitalName: ""}</div>
              </div>
              <b>Date Of Admission</b>
              <div style={{ minHeight: 20 }}>{data && data.admissionDate ? DateOfAdmission.toLocaleDateString(): ""}</div>
              <b>CPU Code</b>
              <div style={{ minHeight: 20 }}>{data ? data.cpuCode: ""}</div>
              <b>Diagnosis</b>
              <div style={{ minHeight: 20 }}>{data ? data.diagnosis: ""}</div>
              {/* <b>Agent/Broker Code</b>
              <div style={{ minHeight: 20 }}>{data && data.brokerCode && data.brokerCode.length>0 ? data.brokerCode: "-"}</div> */}
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
            <div style={{marginTop: '15%'}} >
              <b>Product Name</b>
              <div style={{ minHeight: 20 }}>{data ? data.productName: ""}</div>
              </div>
              <div style={{marginTop: '15%'}} >
              <b>Policy-Issuing Office</b>
              <div style={{ minHeight: 20 }}>{data ? data.policyIssuringOffice: ""}</div>
              </div>
              <div style={{marginTop: '25%'}} >
              {/* <b>City Of Hospital</b>
              <div style={{ minHeight: 20 }}>{data && data.cityOfHospital&& data.cityOfHospital.length>0 ? data.cityOfHospital: "-"}</div> */}
              </div>
              <b>Hospital Address</b>
              <div style={{ minHeight: 20 }}>{data ? data.hospitalAddress: ""}</div>
              <b>Field Visit Doctors Name</b>
              <div style={{ minHeight: 20 }}>{data ? data.fieldVisitDoctorsName: ""}</div>
             {/*  <b>SM Code</b>
              <div style={{ minHeight: 20 }}>{data && data.SMCode && data.SMCode.length>0 ? data.SMCode: "-"}</div>
              <b>Agent/Broker Name</b>
              <div style={{ minHeight: 20 }}>{data ? data.brokerName: ""}</div> */}
            </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }
}

export default withStyles(aboutUsStyle)(ClaimStatusContent);

ClaimStatusContent.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object
};
