/* eslint-disable */
import React, { ReactDOM } from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import headerLinksStyle from "assets/jss/material-kit-pro-react/components/headerLinksStyle.jsx";
import {
  browserDocument,
  browserLocalStorage,
} from "util/localStorageHelper";
// import  '../Header/Headerstyle.css'
import "ps-assets/css/Headerstyle.css"
import { Link } from "gatsby";
import Callback from "ps-components/Callback/Callback";
import Drawer from '@material-ui/core/Drawer';
import Menu from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import Accordion from "components/Accordion/Accordion";
import treedata from "../../jsons/plansmobileview.json";
import accidentMV from "../../jsons/accidentMV.json";
import travelMV from "../../jsons/travelMV.json";
import corporateMV from "../../jsons/corporateMV.json";
import treedata6 from "../../jsons/customermobileview.json";
import treedata3 from "../../jsons/claimmobileview.json";
import ClaimsMV from "../../jsons/ClaimsMV.json";
import COVID19MV from "../../jsons/COVID19MV.json";
import PolicyContactMV from "../../jsons/PolicyContactMV.json";
import ClaimsContactMV from "../../jsons/ClaimsContactMV.json";
import PartnersContactMV from "../../jsons/PartnersContactMV.json";
import FineHospitalsMV from "../../jsons/FineHospitalsMV.json";
import HealthcheckupMV from "../../jsons/HealthcheckupMV.json";
import AgentsPArtnersMV from "../../jsons/AgentsPArtnersMV.json";
import IntermediariesPartnersMV from "../../jsons/IntermediariesPartnersMV.json";
import ServiceProvidersPartnersMV from "../../jsons/ServiceProvidersPartnersMV.json";
import EmployeesPArtnersMV from "../../jsons/EmployeesPArtnersMV.json";
import treedata4 from "../../jsons/ServiceProvidersmobileview.json";
import treedata5 from "../../jsons/ContactUssmobileview.json";
import PdAccordionTree from "components/PdAccordion/PdAccordionTree";
import HeaderAccordian from "components/PdAccordion/HeaderAccordian";
import treedata1 from "../../jsons/partnersmobileview.json"
import treedata2 from "../../jsons/investors.json"
import Close from '@material-ui/icons/Close';
import MVAccordion from "components/Accordion/MVAccordion";
import Hidden from '@material-ui/core/Hidden';
import PolicyMobileview from "components/PdAccordion/PolicyMobileview";
import treedata7 from "../../jsons/customerlogin.json"

const AGENT_REDIRECTION_URL = process.env.AGENT_REDIRECTION_URL;
const OLD_URL = process.env.BASE_URL_OLD;
const BASE_URL = process.env.BASE_URL;
class CustomHeaderLinks extends React.Component {
  constructor(props) {
    super(props);
  }
  state = {
    openSelfService: false,
    openICMRProtocol: false,
    callbackpopup: false,
    mobileview: false,
    changeStyle: false,
    expandedAccordions: [false,false,false]
  };

  handleCallback = () => {
    browserDocument
      .getElementsByclassName("wbf-screen")[0]
      .classNameList.add("active");
    browserDocument
      .getElementsByclassName("wbf-container")[0]
      .classNameList.add("active");
  };
  handleChat = () => {
    let twinkleEvent1 = browserDocument.getElementById("twinkle-thumbnail");
    twinkleEvent1 && twinkleEvent1.click();
  };

  getDropDownMenu = (list, newElement, loggedIn) => {
    if (loggedIn) {
      list.push(newElement);
    }
    return list;
  };
  toggleICMRProtocol = () => {
    if (this.state.openICMRProtocol === false) {
      if (browserDocument.getElementById("mobileDrawer"))
        browserDocument.getElementById("mobileDrawer").style.zIndex = `1299`;
      if (browserDocument.getElementById("remy-thumbnail"))
        browserDocument.getElementById("remy-thumbnail").style.zIndex = `10`;
    } else {
      if (browserDocument.getElementById("mobileDrawer"))
        browserDocument.getElementById(
          "mobileDrawer"
        ).style.zIndex = `9999999999`;
      if (browserDocument.getElementById("remy-thumbnail"))
        browserDocument.getElementById("remy-thumbnail").style.zIndex = `99999`;
    }
    this.setState({
      openICMRProtocol: !this.state.openICMRProtocol,
    });
  };

  toggleSelfService = () => {
    if (this.state.openSelfService === false) {
      if (browserDocument.getElementById("mobileDrawer"))
        browserDocument.getElementById("mobileDrawer").style.zIndex = `1299`;
      if (browserDocument.getElementById("remy-thumbnail"))
        browserDocument.getElementById("remy-thumbnail").style.zIndex = `10`;
    } else {
      if (browserDocument.getElementById("mobileDrawer"))
        browserDocument.getElementById(
          "mobileDrawer"
        ).style.zIndex = `9999999999`;
      if (browserDocument.getElementById("remy-thumbnail"))
        browserDocument.getElementById("remy-thumbnail").style.zIndex = `99999`;
    }
    this.setState({
      openSelfService: !this.state.openSelfService,
    });
    // browserWindow.location.href = "/under-maintenance"
  };


  handleMobileview = () => {
    console.log("inside func")
    this.setState({
      mobileview: !this.state.mobileview
    })
  }

  setActiveAccordion = (activeArray,activeAccordion) =>{
    const unsetAccordion = activeArray && activeArray.length === 0 && this.state.activeAccordion === activeAccordion;
    this.setState({activeAccordion : unsetAccordion ? '' : activeAccordion})}

  setActiveSubdropdown = (activeDropdownArray,activeDropdown) =>{
    const unsetAccordion = activeDropdownArray && activeDropdownArray.length === 0 && this.state.activeDropdown === activeDropdown;
    this.setState({activeDropdown : unsetAccordion ? '' : activeDropdown})}
  changeStyle = (isPanelExpanded, index) => {
    //update the isPanelExpanded for the index in changeStyle stat
    const newExpandedAccordions = this.state.expandedAccordions;
    newExpandedAccordions[index] = isPanelExpanded;


    //check if any of the accordions are expanded
    const isAnyPanelExpanded = newExpandedAccordions.length > 0 && newExpandedAccordions.find((item)=>item === true);
    this.setState({
      expandedAccordions: newExpandedAccordions,
      changeStyle : isAnyPanelExpanded ? true : false
    })
  }

  render() {
    const {
      classNames,
      loggedIn,
      customerPortalBaseUrl,
      navItems,
      dropdownHoverColor,
      tokenSearchString,
      userId,
      wellnessUrl,
    } = this.props;

    const blogRedirectionUrl =
      process.env.BASE_URL == `https://www.starhealth.in/`
        ? `https://blog.starhealth.in`
        : `https://uatblogs.starhealth.in/blogs`;

    return (
      <div className="Header_header__1mdCC flex justify-between bg-white">
      <div className="flex align-center justify-between-md w-full">
        <span className="Header_logo-xs__yzxxa Header_logo-sm__M3ZfU mr-24">
          <img alt="Star Health Logo" src="./img/star_health_logo_big.svg" className="cursor-pointer" />
        </span>
        <div className="">
          <div className="flex align-center">
            <div className="Header_top-header-xs__FRedm flex align-center">
              <a className="flex align-center text-no-wrap text-16" href="tel:04469006900"><span
                  className="only-mobile show-tab">Buy</span>
                <div className="Header_mobile-buy-xs__gY5et ml-6 mr-12 relative only-mobile show-tab">
                  <span><img alt="" src="./img/mobile-icon.svg" /></span>
                </div>
              </a><a target="_blank" className="flex align-center text-no-wrap text-16 ml-8"
                href="https://apps.apple.com/us/app/star-power/id1477621177?ls=1">
                <div className="flex align-center">
                  <span className="only-mobile text-16 show-tab">App</span>
                  <div className="Header_mobile-app-xs__cspiU ml-6 mr-12 relative only-mobile show-tab">
                    <span><img alt="Star health iOS app" src="./img/iOS-xs.svg" /></span>
                  </div>
                </div>
              </a><a target="_blank" className="Header_mobile-app-xs__cspiU mr-12 relative only-mobile show-tab"
                href="https://play.google.com/store/apps/details?id=com.star.customer_app"><span><img
                    alt="Star health andriod app" src="./img/playstore-xs.svg" /></span></a>
            </div>
          </div>
        </div>
        <div className="Header_nav-link-container__nlUUp dropdown-active flex only-desktop">
          <div>
            <div>
              <div className="flex cursor-pointer pr-18 cursor-pointer nav-link flex relative">
                <p className="font-500 text-14 mb-0 pt-10 pb-10">Plans</p>
                <span className="ml-8 flex align-center">
                  <img src="./img/arrow-down.svg" alt="" />
                </span>
                <div className="ant-popover ant-popover-placement-bottomLeft ant-popover-hidden">
                  <div className="ant-popover-content">
                    <div className="ant-popover-arrow">
                      <span className="ant-popover-arrow-content"></span>
                    </div>
                    <div className="ant-popover-inner" role="tooltip">
                      <div className="ant-popover-inner-content">
                        <div className="Header_dropdown-container__w_bms">
                          <div className="dropdown-top flex justify-between pt-20 pb-32 pl-32 pr-32">
                            <div className="menu-links-container mr-30">
                              <div className="text-uppercase font-700 mb-24">
                                HEALTH
                              </div>
                              <div className="mb-20 flex align-center cursor-pointer menu-links">
                                <span className="mr-8 flex align-center">
                                  <img src="./img/star_health_insurance_icons_contact_us_agent_care.svg" alt="" />
                                  <a className="font-500 text-capitalize text-14"
                                    href="https://www.starhealth.in/health-insurance/comprehensive/">For Me</a>
                                </span>
                              </div>
                              <div className="mb-20 flex align-center cursor-pointer menu-links">
                                <span className="mr-8 flex align-center">
                                  <img src="./img/star_health_insurance_icons_accident_saral_suraksha.svg" alt="" />
                                  <a className="font-500 text-capitalize text-14"
                                    href="https://www.starhealth.in/health-insurance/comprehensive/">For My Family</a>
                                </span>
                              </div>
                              <div className="mb-20 flex align-center cursor-pointer menu-links">
                                <span className="mr-8 flex align-center">
                                  <img src="./img/for_parents.svg" alt="" />
                                  <a className="font-500 text-capitalize text-14"
                                    href="https://www.starhealth.in/health-insurance/red-carpet/">For Senior
                                    Citizens</a>
                                </span>
                              </div>
                              <div className="mb-20 flex align-center cursor-pointer menu-links">
                                <span className="mr-8 flex align-center">
                                  <img src="./img/star_health_insurance_icons_Plans_view_all.svg" alt="" />
                                  <a className="font-500 text-capitalize text-14"
                                    href="https://www.starhealth.in/health-insurance/">View All</a>
                                </span>
                              </div>
                            </div>
                            <div className="menu-links-container mr-30">
                              <div className="text-uppercase font-700 mb-24">
                                ACCIDENT
                              </div>
                              <div className="mb-20 flex align-center cursor-pointer menu-links">
                                <span className="mr-8 flex align-center">
                                  <img src="./img/star_health_insurance_icons_contact_us_agent_care.svg" alt="" />
                                  <a className="font-500 text-capitalize text-14"
                                    href="https://www.starhealth.in/accident-insurance/individual/">For Me</a>
                                </span>
                              </div>
                              <div className="mb-20 flex align-center cursor-pointer menu-links">
                                <span className="mr-8 flex align-center">
                                  <img src="./img/star_health_insurance_icons_accident_saral_suraksha.svg" alt="" />
                                  <a className="font-500 text-capitalize text-14"
                                    href="https://www.starhealth.in/accident-insurance/family/">For My Family</a>
                                </span>
                              </div>
                              <div className="mb-20 flex align-center cursor-pointer menu-links">
                                <span className="mr-8 flex align-center">
                                  <img src="./img/star_health_insurance_icons_accident_saral_suraksha-icon.svg"
                                    alt="" />
                                  <a className="font-500 text-capitalize text-14"
                                    href="https://www.starhealth.in/accident-insurance/saral-suraksha-bima/">Saral
                                    Suraksha Bima</a>
                                </span>
                              </div>
                              <div className="mb-20 flex align-center cursor-pointer menu-links">
                                <span className="mr-8 flex align-center">
                                  <img src="./img/star_health_insurance_icons_Plans_view_all.svg" alt="" />
                                  <a className="font-500 text-capitalize text-14"
                                    href="https://www.starhealth.in/accident-plans/">View All</a>
                                </span>
                              </div>
                            </div>
                            <div className="menu-links-container mr-30">
                              <div className="text-uppercase font-700 mb-24">
                                TRAVEL
                              </div>
                              <div className="mb-20 flex align-center cursor-pointer menu-links">
                                <span className="mr-8 flex align-center">
                                  <img src="./img/star_health_insurance_icons_travel_overseas_travel.svg" alt="" />
                                  <a className="font-500 text-capitalize text-14"
                                    href="https://uat.starhealth.in/travel-insurance/international-individual/">Overseas
                                    Travel</a>
                                </span>
                              </div>
                              <div className="mb-20 flex align-center cursor-pointer menu-links">
                                <span className="mr-8 flex align-center">
                                  <img src="./img/for_family.svg" alt="" />
                                  <a className="font-500 text-capitalize text-14"
                                    href="https://www.starhealth.in/travel-insurance/international-student/">For
                                    Students</a>
                                </span>
                              </div>
                              <div className="mb-20 flex align-center cursor-pointer menu-links">
                                <span className="mr-8 flex align-center">
                                  <img src="./img/star_health_insurance_icons_Plans_view_all.svg" alt="" />
                                  <a className="font-500 text-capitalize text-14"
                                    href="https://www.starhealth.in/travel-insurance-plans">View All</a>
                                </span>
                              </div>
                            </div>
                            <div className="menu-links-container mr-30">
                              <div className="text-uppercase font-700 mb-24">
                                CORPORATE
                              </div>
                              <div className="mb-20 flex align-center cursor-pointer menu-links">
                                <span className="mr-8 flex align-center">
                                  <img src="./img/star_health_insurance_icons_Corporate_group_mediclaim.svg" alt="" />
                                  <a className="font-500 text-capitalize text-14"
                                    href="https://www.starhealth.in/group-health-insurance/">Group Mediclaim</a>
                                </span>
                              </div>
                              <div className="mb-20 flex align-center cursor-pointer menu-links">
                                <span className="mr-8 flex align-center">
                                  <img src="./img/star_health_insurance_icons_Corporate_group_travel_insurance.svg"
                                    alt="" />
                                  <a className="font-500 text-capitalize text-14"
                                    href="https://www.starhealth.in/travel-insurance/international-corporate/">Group
                                    Travel Insurance</a>
                                </span>
                              </div>
                              <div className="mb-20 flex align-center cursor-pointer menu-links">
                                <span className="mr-8 flex align-center">
                                  <img src="./img/star_health_insurance_icons_Corporate_group_accident_insurance.svg"
                                    alt="" />
                                  <a className="font-500 text-capitalize text-14"
                                    href="https://www.starhealth.in/accident-insurance/group/">Group Accident
                                    Insurance</a>
                                </span>
                              </div>
                            </div>
                          </div>
                          {loggedIn && (<h5 style={{marginBottom: "2%", marginLeft: "3%", marginTop: "-3%" }} >
                                  <Link
                                    className={"dropdownLink customLink"}
                                    style={{
                                      paddingLeft: "15px !important",
                                      padding: "7px 15px",
                                      backgroundColor: "#434556",
                                      borderRadius: "15px",
                                      color: "white"
                                    }}
                                    onClick={() => {
                                      browserLocalStorage.setItem(`sessionalive`, true);
                                    }}
                                    to={
                                      loggedIn
                                      ? `${customerPortalBaseUrl}/${
                                          loggedIn ? `mypolicies${tokenSearchString}` : `mypolicies`
                                        }/`
                                      : ``
                                    }
                                  >
                                    My Policies
                                  </Link>
                                </h5>)}
                          <div className="Header_dropdown-bottom__1vOJk">
                            <div className="text-uppercase font-700 mb-12">
                              TOP HEALTH PLANS
                            </div>
                            <div className="flex">
                              <div>
                                <div className="flex">
                                  <div className="flex align-center cursor-pointer">
                                    <a className="font-500 text-capitalize text-14"
                                      href="https://www.starhealth.in/health-insurance/comprehensive/">
                                      <div className="text-14 text-capitalize">
                                        Star Comprehensive Insurance Policy
                                      </div>
                                    </a><span className="mr-20 ml-8">
                                      <img src="./img/right_arrow_orange.svg" alt="" />
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div>
                                <div className="flex">
                                  <div className="flex align-center cursor-pointer">
                                    <a className="font-500 text-capitalize text-14"
                                      href="https://www.starhealth.in/health-insurance/family-health-optima/">
                                      <div className="text-14 text-capitalize">
                                        Family Health Optima Insurance Plan
                                      </div>
                                    </a>
                                    <span className="mr-20 ml-8">
                                      <img src="./img/right_arrow_orange.svg" alt="" />
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div>
                                <div className="flex">
                                  <div className="flex align-center cursor-pointer">
                                    <a className="font-500 text-capitalize text-14"
                                      href="https://www.starhealth.in/health-insurance/youngstar/">
                                      <div className="text-14 text-capitalize">
                                        Young Star Insurance Policy
                                      </div>
                                    </a><span className="mr-20 ml-8">
                                      <img src="./img/right_arrow_orange.svg" alt="" />
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="undefined popover-bg-dim" style={{zIndex: 1}}></div>
          </div>
          <div>
            <div>
              <div className="flex cursor-pointer pr-18 cursor-pointer nav-link flex relative">
                <p className="font-500 text-14 mb-0 pt-10 pb-10">Customer</p>
                <span className="ml-8 flex align-center"><img src="./img/arrow-down.svg" alt="" />
                </span>
                <div className="ant-popover ant-popover-placement-bottomLeft ant-popover-hidden">
                  <div className="ant-popover-content">
                    <div className="ant-popover-arrow">
                      <span className="ant-popover-arrow-content"></span>
                    </div>
                    <div className="ant-popover-inner" role="tooltip">
                      <div className="ant-popover-inner-content">
                        <div className="Header_dropdown-container__w_bms">
                          <div className="dropdown-top flex justify-between pt-20 pb-32 pl-32 pr-32">
                          <div className="menu-links-container mr-30">
                            <div className="text-uppercase font-700 mb-24">
                               LOGIN
                              </div>
                              <div className="mb-20 flex align-center cursor-pointer menu-links">
                                <span className="mr-8 flex align-center">
                                  <img src="./img/retail_customer.svg" alt="" />
                                  <a className="font-500 text-capitalize text-14"
                                    href="https://customer.starhealth.in/customerportal/login">Retail Customer</a>
                                </span>
                              </div>
                              <div className="mb-20 flex align-center cursor-pointer menu-links">
                                <span className="mr-8 flex align-center">
                                  <img src="./img/employee.svg" alt="" />
                                  <a className="font-500 text-capitalize text-14"
                                    href="https://portal.starhealth.in/CorpClient/wfrm_Login.aspx">Employee</a>
                                </span>
                              </div>
                              <div className="mb-20 flex align-center cursor-pointer menu-links">
                                <span className="mr-8 flex align-center">
                                  <img src="./img/hr.svg" alt="" />
                                  <a className="font-500 text-capitalize text-14"
                                    href="https://portal.starhealth.in/CorpClient/wfrm_Login.aspx">HR</a>
                                </span>
                              </div>
                            </div>
                            <div className="menu-links-container mr-30">
                            <div className="text-uppercase font-700 mb-24">
                               SERVICES
                              </div>
                              <div className="mb-20 flex align-center cursor-pointer menu-links">
                                <span className="mr-8 flex align-center">
                                  <img src="./img/star_health_insurance_icons_customer_downloads.svg" alt="" />
                                  <a className="font-500 text-capitalize text-14"
                                    href="https://www.starhealth.in/downloads">Downloads</a>
                                </span>
                              </div>
                              <div className="mb-20 flex align-center cursor-pointer menu-links">
                                <span className="mr-8 flex align-center">
                                  <img src="./img/star_health_insurance_icons_customer_downloads.svg" alt="" />
                                  <a className="font-500 text-capitalize text-14"
                                    href="https://kychub.starhealth.in/kychub/ui/customer-login">Submit CKYC</a>
                                </span>
                              </div>
                              <div className="mb-20 flex align-center cursor-pointer menu-links">
                                <span className="mr-8 flex align-center">
                                  <img src="./img/star_health_insurance_icons_customer_wellness.svg" alt="" />
                                  <a className="font-500 text-capitalize text-14"
                                    href="https://www.starhealth.in/wellness/">Wellness</a>
                                </span>
                              </div>
                              <div className="mb-20 flex align-center cursor-pointer menu-links">
                                <span className="mr-8 flex align-center">
                                  <img src="./img/rupee.svg" alt="" />

                                  <a className="font-500 text-capitalize text-14"
                                    href="https://customer.starhealth.in/customerportal/auto-debit">e-NACH</a>
                                </span>
                              </div>
                              <div className="mb-20 flex align-center cursor-pointer menu-links">
                                <span className="mr-8 flex align-center">
                                  <img src="./img/EMI.svg" alt="" />
                                  <a className="font-500 text-capitalize text-14"
                                    href="https://customer.starhealth.in/customerportal/emi-online-registration">Pay EMI</a>
                                </span>
                              </div>
                              <div className="mb-20 flex align-center cursor-pointer menu-links">
                                <span className="mr-8 flex align-center">
                                  <img src="./img/star_health_insurance_icons_contact_us_callback.svg" alt="" />
                                  <a className="font-500 text-capitalize text-14"
                                    href="https://www.starhealth.in/talk-to-doctor/">Free Telemedicine</a>
                                </span>
                              </div>
                              <div className="mb-20 flex align-center cursor-pointer menu-links">
                                <span className="mr-8 flex align-center">
                                  <img src="./img/star_health_insurance_icons_customer_leave_a_testimonial.svg"
                                    alt="" />
                                  <a className="font-500 text-capitalize text-14"
                                    href="https://www.starhealth.in/contact/">Leave a Testimonial</a>
                                </span>
                              </div>
                              <div className="mb-20 flex align-center cursor-pointer menu-links">
                                <span className="mr-8 flex align-center">
                                  <img src="./img/star_health_insurance_icons_customer_file_a_grievance.svg" alt="" />
                                  <a className="font-500 text-capitalize text-14"
                                    href="https://www.starhealth.in/grievance-redressal/">File a Grievance</a>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="undefined popover-bg-dim" style={{zIndex: 1}}></div>
          </div>
          <div className="cursor-pointer nav-link flex pr-18 undefined">
            <a className="font-500 text-14 pt-10 pb-10"
              href="https://customer.starhealth.in/customerportal/instant-renewal/">Renew</a>
          </div>
          <div>
            <div>
              <div className="flex cursor-pointer pr-18 cursor-pointer nav-link flex relative">
                <p className="font-500 text-14 mb-0 pt-10 pb-10">Claims</p>
                <span className="ml-8 flex align-center">
                  <img src="./img/arrow-down.svg" alt="" />
                </span>
                <div className="ant-popover ant-popover-placement-bottomLeft ant-popover-hidden">
                  <div className="ant-popover-content">
                    <div className="ant-popover-arrow">
                      <span className="ant-popover-arrow-content"></span>
                    </div>
                    <div className="ant-popover-inner" role="tooltip">
                      <div className="ant-popover-inner-content">
                        <div className="Header_dropdown-container__w_bms">
                          <div className="claim-dropdown-top flex justify-between pt-20 pb-32 pl-32 pr-32">
                            <div className="menu-links-container mr-30">
                            <div className="text-uppercase font-700 mb-24">
                              CLAIMS
                              </div>
                              <div className="mb-20 flex align-center cursor-pointer menu-links">
                                <span className="mr-8 flex align-center">
                                  <img src="./img/star_health_insurance_icons_claims_claim_intimation.svg" alt="" />
                                  <a className="font-500 text-capitalize text-14" href="https://web.starhealth.in/claims#1">
                                    Claim Intimation</a>
                                </span>
                              </div>
                              <div className="mb-20 flex align-center cursor-pointer menu-links">
                                <span className="mr-8 flex align-center">
                                  <img src="./img/star_health_insurance_icons_claims_claims_process.svg" alt="" />
                                  <a className="font-500 text-capitalize text-14"
                                    href="https://www.starhealth.in/claims/#claim-process">Claim Process</a>
                                </span>
                              </div>
                              <div className="mb-20 flex align-center cursor-pointer menu-links">
                                <span className="mr-8 flex align-center">
                                  <img src="./img/star_health_insurance_icons_claim_claim_helpdesk.svg" alt="" />
                                  <a className="font-500 text-capitalize text-14" href="https://www.starhealth.in/claims/#claim-helpdesk">
                                    Claim Helpdesk</a>
                                </span>
                              </div>
                              <div className="mb-20 flex align-center cursor-pointer menu-links">
                                <span className="mr-8 flex align-center">
                                  <img src="./img/star_health_insurance_icons_claims_claims_status.svg" alt="" />
                                  <a className="font-500 text-capitalize text-14"
                                    href="https://web.starhealth.in/claims#3">Claim Status</a>
                                </span>
                              </div>
                              <div className="mb-20 flex align-center cursor-pointer menu-links">
                                <span className="mr-8 flex align-center">
                                  <img src="./img/star_health_insurance_icons_claims_claims_status.svg" alt="" />
                                  <a className="font-500 text-capitalize text-14"
                                    href="https://web.starhealth.in/claims#4">Upload Claim Documents</a>
                                </span>
                              </div>
                              {loggedIn && (<h5>
                                  <Link
                                    className={"dropdownLink customLink"}
                                    style={{
                                      paddingLeft: "15px !important",
                                      padding: "7px 15px",
                                      backgroundColor: "#434556",
                                      borderRadius: "15px",
                                      color: "white"
                                    }}
                                    onClick={() => {
                                      browserLocalStorage.setItem(`sessionalive`, true);
                                    }}
                                    to={
                                      loggedIn
                                        ? `${customerPortalBaseUrl}/${
                                            loggedIn ? `claims${tokenSearchString}` : `claims`
                                          }/`
                                        : ``
                                    }
                                  >
                                    My Claims
                                  </Link>
                                </h5>)}
                            </div>
                            <div className="menu-links-container mr-30">
                            <div className="text-uppercase font-700 mb-24">
                              COVID 19
                              </div>
                              <div className="mb-20 flex align-center cursor-pointer menu-links">
                                <span className="mr-8 flex align-center">
                                  <img src="./img/star_health_insurance_icons_covid_important_info.svg" alt="" />
                                  <a className="font-500 text-capitalize text-14"
                                    href="https://www.starhealth.in/covid-press-release/">Important Info</a>
                                </span>
                              </div>
                              <div className="mb-20 flex align-center cursor-pointer menu-links">
                                <span className="mr-8 flex align-center">
                                  <img src="./img/star_health_insurance_icons_covid_faqs.svg" alt="" />
                                  <a className="font-500 text-capitalize text-14"
                                    href="https://www.starhealth.in/covid-19-faq/">FAQs</a>
                                </span>
                              </div>
                              <div className="mb-20 flex align-center cursor-pointer menu-links">
                                <span className="mr-8 flex align-center">
                                  <img src="./img/star_health_insurance_icons_covid_about_covid.svg" alt="" />
                                  <a className="font-500 text-capitalize text-14"
                                    href="https://web.starhealth.in/sites/default/files/COVID-19-faq-1.pdf">About
                                    Covid</a>
                                </span>
                              </div>
                              <div className="mb-20 flex align-center cursor-pointer menu-links">
                                <span className="mr-8 flex align-center">
                                  <img src="./img/star_health_insurance_icons_covid_covid_charges.svg" alt="" />
                                  <a className="font-500 text-capitalize text-14"
                                    href="https://web.starhealth.in/sites/default/files/GI-Council-COVID-19.pdf">Covid
                                    Charges</a>
                                </span>
                              </div>
                              <div className="mb-20 flex align-center cursor-pointer menu-links">
                                <span className="mr-8 flex align-center" onClick={this.toggleICMRProtocol}>
                                  <img src="./img/star_health_insurance_icons_covid_aiims_icmr_protocols.svg" alt="" />
                                  <a className="font-500 text-capitalize text-14">AIIMS/ICMR Protocal</a>
                                </span>
                              </div>
                              <div className="mb-20 flex align-center cursor-pointer menu-links">
                                <span className="mr-8 flex align-center">
                                  <img src="./img/star_health_insurance_icons_covid_covid_calculator.svg" alt="" />
                                  <a className="font-500 text-capitalize text-14"
                                    href="https://www.starhealth.in/covid-19-calculator/">Covid Calculator</a>
                                </span>
                              </div>
                              <div className="mb-20 flex align-center cursor-pointer menu-links">
                                <span className="mr-8 flex align-center">
                                  <img src="./img/star_health_insurance_icons_covid_cashless_claims.svg" alt="" />
                                  <a className="font-500 text-capitalize text-14"
                                    href="https://web.starhealth.in/sites/default/files/Letter-to-Network-Hospitals.pdf">Cashless
                                    Claims of COVID-19 Patients</a>
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="Header_dropdown-bottom__1vOJk">
                            <div className="text-uppercase font-700 mb-12">
                              SENIOR CITIZENS HELPLINE NUMBERS
                            </div>
                            <div className="flex">
                              <div>
                                <div className="flex">
                                  <div className="flex align-center cursor-pointer">
                                    <a className="font-500 text-capitalize text-14" href="tel:04440020888 ">
                                      <div className="text-14 text-capitalize">
                                        Claim - 044 4002 0888
                                      </div>
                                    </a><span className="mr-20 ml-8">
                                      <img src="./img/right_arrow_orange.svg" alt="" />
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div>
                                <div className="flex">
                                  <div className="flex align-center cursor-pointer">
                                    <a className="font-500 text-capitalize text-14" href="tel:04469007500">
                                      <div className="text-14 text-capitalize">
                                        Grievance - 044 6900 7500
                                      </div>
                                    </a><span className="mr-20 ml-8">
                                      <img src="./img/right_arrow_orange.svg" alt="" />
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="undefined popover-bg-dim" style={{zIndex: 1}}></div>
          </div>
          <div>
            <div>
              <div className="flex cursor-pointer pr-18 cursor-pointer nav-link flex relative">
                <p className="font-500 text-14 mb-0 pt-10 pb-10">
                  Service Providers
                </p>
                <span className="ml-8 flex align-center"><img src="./img/arrow-down.svg" alt="" />
                </span>
                <div>
                  <div>
                    <div className="ant-popover ant-popover-placement-bottomLeft ant-popover-hidden">
                      <div className="ant-popover-content">
                        <div className="ant-popover-arrow">
                          <span className="ant-popover-arrow-content"></span>
                        </div>
                        <div className="ant-popover-inner" role="tooltip">
                          <div className="ant-popover-inner-content">
                            <div className="Header_dropdown-container__w_bms">
                              <div className="dropdown-top flex justify-between pt-20 pb-32 pl-32 pr-32">
                                <div className="menu-links-container mr-30">
                                  <div className="text-uppercase font-700 mb-24">
                                    FIND HOSPITALS
                                  </div>
                                  <div className="mb-20 flex align-center cursor-pointer menu-links">
                                    <span className="mr-8 flex align-center">
                                      <img src="./img/star_health_insurance_icons_claim_s_network_hospitals.svg"
                                        alt="" />

                                      <a className="font-500 text-capitalize text-14"
                                        href="https://www.starhealth.in/lookup/hospital/">Network Hospitals</a>
                                    </span>
                                  </div>
                                  <div className="mb-20 flex align-center cursor-pointer menu-links">
                                    <span className="mr-8 flex align-center">
                                      <img src="./img/star_health_insurance_icons_claims_excluded_hospitals.svg"
                                        alt="" />

                                      <a className="font-500 text-capitalize text-14"
                                        href="https://www.starhealth.in/lookup/hospital/#excluded-hospital">Excluded
                                        Hospitals</a>
                                    </span>
                                  </div>
                                  <div className="mb-20 flex align-center cursor-pointer menu-links">
                                    <span className="mr-8 flex align-center">
                                      <img src="./img/star_health_insurance_icons_claims_network_labs.svg" alt="" />
                                      <a className="font-500 text-capitalize text-14"
                                        href="https://www.starhealth.in/lookup/hospital/#network-labs-for-pre-medical-tests">Network
                                        Labs</a>
                                    </span>
                                  </div>
                                  <div className="mb-20 flex align-center cursor-pointer menu-links">
                                    <span className="mr-8 flex align-center">
                                      <img src="./img/Rehabilitation_Hospice_Care.svg" alt="" />
                                      <a className="font-500 text-capitalize text-14"
                                        href="https://www.starhealth.in/lookup/hospital/#network-providers-for-rehabilitation-and-hospice-care">Rehabilitation
                                        &amp; Hospice Care</a>
                                    </span>
                                  </div>
                                  <div className="mb-20 flex align-center cursor-pointer menu-links">
                                    <span className="mr-8 flex align-center">
                                      <img src="./img/Pain_Management_Treatment.svg" alt="" />
                                      <a className="font-500 text-capitalize text-14"
                                        href="https://www.starhealth.in/lookup/hospital/#network-providers-for-pain-management-treatment">Pain
                                        Management Treatment</a>
                                    </span>
                                  </div>
                                  <div className="mb-20 flex align-center cursor-pointer menu-links">
                                    <span className="mr-8 flex align-center">
                                      <img src="./img/Scan_Centres_for_Women_care.svg" alt="" />
                                      <a className="font-500 text-capitalize text-14"
                                        href="https://www.starhealth.in/lookup/hospital/#specified-scan-centres-for-women-care">Scan
                                        Centres for Women Care</a>
                                    </span>
                                  </div>
                                  <div className="mb-20 flex align-center cursor-pointer menu-links">
                                    <span className="mr-8 flex align-center">
                                      <img src="./img/star_health_insurance_icons_claims_home_pickup.svg" alt="" />
                                      <a className="font-500 text-capitalize text-14"
                                        href="https://www.starhealth.in/lookup/hospital/#preventive-health-check-services">Home
                                        Collection - Sample</a>
                                    </span>
                                  </div>
                                </div>
                                <div className="menu-links-container mr-30">
                                  <div className="text-uppercase font-700 mb-24">
                                    HEALTH CHECKUPS &amp; OPD
                                  </div>
                                  <div className="mb-20 flex align-center cursor-pointer menu-links">
                                    <span className="mr-8 flex align-center">
                                      <img src="./img/star_health_insurance_icons_claims_health_checkup_at_lab.svg"
                                        alt="" />
                                      <a className="font-500 text-capitalize text-14"
                                        href="https://www.starhealth.in/Preventive-Health-Check-walkin/">Health Checkup
                                        at Lab</a>
                                    </span>
                                  </div>
                                  <div className="mb-20 flex align-center cursor-pointer menu-links">
                                    <span className="mr-8 flex align-center">
                                      <img src="./img/star_health_insurance_icons_claims_health_check_at_home.svg"
                                        alt="" />
                                      <a className="font-500 text-capitalize text-14"
                                        href="https://www.starhealth.in/health-checkup-packages/">Health
                                        Checkup at Home</a>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="undefined popover-bg-dim" style={{zIndex: 1}}></div>
          </div>
          <div>
            <div>
              <div className="flex cursor-pointer pr-18 cursor-pointer nav-link flex relative">
                <p className="font-500 text-14 mb-0 pt-10 pb-10">Contact Us</p>
                <span className="ml-8 flex align-center">
                  <img src="./img/arrow-down.svg" alt="" />
                </span>
                <div className="ant-popover ant-popover-placement-bottomRight ant-popover-right">
                  <div className="ant-popover-content">
                    <div className="ant-popover-arrow">
                      <span className="ant-popover-arrow-content"></span>
                    </div>
                    <div className="ant-popover-inner" role="tooltip">
                      <div className="ant-popover-inner-content">
                        <div className="Header_dropdown-container__w_bms">
                          <div className="dropdown-top flex justify-between pt-20 pb-32 pl-32 pr-32">
                            <div className="menu-links-container mr-30">
                              <div className="text-uppercase font-700 mb-24">
                                POLICY
                              </div>
                              <div className="mb-20 flex align-center cursor-pointer menu-links">
                                <span className="mr-8 flex align-center" 
                                onClick={() => {
                                  this.handleChat();
                                }}>
                                  <img src="./img/star_health_insurance_icons_contact_chat_with_us.svg" alt="" />
                                  <a className="font-500 text-capitalize text-14 ChatWithUs">Chat With Us</a>
                                </span>
                              </div>
                              <div className="mb-20 flex align-center cursor-pointer menu-links">
                                <span className="mr-8 flex align-center">
                                  <img src="./img/star_health_insurance_icons_cotact_us_buy_policy.svg" alt="" />
                                  <a className="font-500 text-capitalize text-14" href="tel:18004252255">Buy - 1800 425
                                    2255</a>
                                </span>
                              </div>
                              <div className="mb-20 flex align-center cursor-pointer menu-links">
                                <span className="mr-8 flex align-center">
                                  <img src="./img/star_health_insurance_icons_contact_us_renew.svg" alt="" />
                                  <a className="font-500 text-capitalize text-14" href="tel:18001024477">Renew - 1800 102
                                    4477</a>
                                </span>
                              </div>
                              <div className="mb-20 flex align-center cursor-pointer menu-links">
                                <span className="mr-8 flex align-center"  onClick={this.handleCallback}>
                                  <img src="./img/star_health_insurance_icons_contact_us_callback.svg" alt="" />
                                  <a className="font-500 text-capitalize text-14 RequestaCallback">Request a Callback</a>
                                </span>
                              </div>
                              <div className="mb-20 flex align-center cursor-pointer menu-links">
                                <span className="mr-8 flex align-center">
                                  <img src="./img/star_health_insurance_icons_contact_us_locate_our_branch.svg"
                                    alt="" />
                                  <a className="font-500 text-capitalize text-14"
                                    href="https://www.starhealth.in/lookup/branch/">Locate Our Branch</a>
                                </span>
                              </div>
                              <div className="mb-20 flex align-center cursor-pointer menu-links">
                                <span className="mr-8 flex align-center">
                                  <img src="./img/star_health_insurance_icons_claims_claim_intimation.svg" alt="" />
                                  <a className="font-500 text-capitalize text-14"
                                    href="https://www.starhealth.in/contact">Write to Us</a>
                                </span>
                              </div>
                            </div>
                            <div className="menu-links-container mr-30">
                              <div className="text-uppercase font-700 mb-24">
                                CLAIMS
                              </div>
                              <div className="mb-20 flex align-center cursor-pointer menu-links">
                                <span className="mr-8 flex align-center">
                                  <img src="./img/star_health_insurance_icons_contact_us_tollfree_1.svg" alt="" />
                                  <a className="font-500 text-capitalize text-14 ChatWithUs" href="tel:18004252255">Helpline - 044 6900 6900</a>
                                </span>
                              </div>
                              <div className="mb-20 flex align-center cursor-pointer menu-links">
                                <span className="mr-8 flex align-center">
                                  <img src="./img/star_health_insurance_icons_contact_us_tollfree2.svg" alt="" />
                                  <a className="font-500 text-capitalize text-14" href="tel:18004252255">Corporate - 044 4366 4666</a>
                                </span>
                              </div>
                              <div className="mb-20 flex align-center cursor-pointer menu-links">
                                <span className="mr-8 flex align-center">
                                  <img src="./img/star_health_insurance_icons_contact_us_tollfree_1.svg" alt="" />
                                  <a className="font-500 text-capitalize text-14" href="tel:18001024477">Banca Channel - 044 6666 5050</a>
                                </span>
                              </div>
                              <div className="mb-20 flex align-center cursor-pointer menu-links">
                                <span className="mr-8 flex align-center">
                                  <img src="./img/star_health_insurance_icons_contact_us_grievance_care.svg" alt="" />
                                  <a className="font-500 text-capitalize text-14" href="tel:04443664600">Grievance Care -
                                    044 4366 4600</a>
                                </span>
                              </div>
                              <div className="mb-20 flex align-center cursor-pointer menu-links">
                                <span className="mr-8 flex align-center">
                                  <img src="./img/star_health_insurance_icons_contact_us_senior_citizen_claim.svg"
                                    alt="" />
                                  <a className="font-500 text-capitalize text-14" href="tel:04440020888">
                                    Sr Citizen Claim - 044 4002 0888</a>
                                </span>
                              </div>
                              <div className="mb-20 flex align-center cursor-pointer menu-links">
                                <span className="mr-8 flex align-center">
                                  <img src="./img/star_health_insurance_icons_contact_us_senior_citizen_grivience.svg"
                                    alt="" />
                                  <a className="font-500 text-capitalize text-14 mr-8" href="tel:04469007500">Sr
                                    Citizen
                                    Grievance - 044 6900 7500</a>
                                </span>
                              </div>
                            </div>
                            <div className="menu-links-container mr-30">
                              <div className="text-uppercase font-700 mb-24">
                                PARTNERS
                              </div>
                              <div className="mb-20 flex align-center cursor-pointer menu-links">
                                <span className="mr-8 flex align-center">
                                  <img src="./img/star_health_insurance_icons_contact_us_agent_care.svg" alt="" />
                                  <a className="font-500 text-capitalize text-14" href="tel:04469042500">Agents Care 1 - 044
                                    6904 2500</a>
                                </span>
                              </div>
                              <div className="mb-20 flex align-center cursor-pointer menu-links">
                                <span className="mr-8 flex align-center">
                                  <img src="./img/star_health_insurance_icons_contact_us_agent_care.svg" alt="" />
                                  <a className="font-500 text-capitalize text-14" href="tel:04440742600 ">Agents Care 2 -
                                    044 4074 2600</a>
                                </span>
                              </div>
                              <div className="mb-20 flex align-center cursor-pointer menu-links">
                                <span className="mr-8 flex align-center">
                                  <img src="./img/star_health_insurance_icons_contact_us_other_partners.svg" alt="" />
                                  <a className="font-500 text-capitalize text-14" href="tel:18004252255">Other Partners -
                                  044 6900 6900</a>
                                </span>
                              </div>
                              <div className="mb-20 flex align-center cursor-pointer menu-links">
                                <span className="mr-8 flex align-center">
                                  <img src="./img/star_health_insurance_icons_contact_us_feedback.svg" alt="" />
                                  <a className="font-500 text-capitalize text-14"
                                    href="https://desk.zoho.in/portal/shaizohoin/en/newticket?departmentId=12655000000058909&amp;layoutId=12655000000059746">Portals
                                    Feedback</a>
                                </span>
                              </div>
                              <div className="mb-20 flex align-center cursor-pointer menu-links">
                                <span className="mr-8 flex align-center">
                                  <img src="./img/star_health_insurance_icons_customer_file_a_grievance.svg" alt="" />
                                  <a className="font-500 text-capitalize text-14"
                                    href="https://www.starhealth.in/grievance-redressal/">Partners Grievance</a>
                                </span>
                              </div>
                              <div className="mb-20 flex align-center cursor-pointer menu-links">
                                <span className="mr-8 flex align-center">
                                  <img src="./img/star_health_insurance_icons_contact_us_agent_care.svg" alt="" />
                                  <a className="font-500 text-capitalize text-14"
                                    href="https://www.starhealth.in/partners/">Partners Corner</a>
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="Header_dropdown-bottom__1vOJk">
                            <div className="text-uppercase font-700 mb-12">
                              DOWNLOAD OUR APP
                            </div>
                            <div className="flex">
                              <div>
                                <div className="flex">
                                  <div className="flex align-center cursor-pointer">
                                    <a className="font-500 text-capitalize text-14"
                                      href="https://play.google.com/store/apps/details?id=com.star.customer_app">
                                      <div className="text-14 text-capitalize">
                                        Android
                                      </div>
                                    </a><span className="mr-20 ml-8">
                                      <img src="./img/right_arrow_orange.svg" alt="" />
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div>
                                <div className="flex">
                                  <div className="flex align-center cursor-pointer">
                                    <a className="font-500 text-capitalize text-14"
                                      href="https://apps.apple.com/us/app/star-power/id1477621177?ls=1">
                                      <div className="text-14 text-capitalize">
                                        IOS
                                      </div>
                                    </a><span className="mr-20 ml-8">
                                      <img src="./img/right_arrow_orange.svg" alt="" />
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="undefined popover-bg-dim" style={{zIndex: 1}}></div>
          </div>
          <div className="cursor-pointer nav-link flex pr-18 undefined">
            <a className="font-500 text-14 pt-10 pb-10"
              href="https://help.starhealth.in/">Help</a>
          </div>
          <div className="cursor-pointer nav-link flex pr-18 undefined">
            <a className="font-500 text-14 pt-10 pb-10"
              href="https://kychub.starhealth.in/kychub/ui/customer-login">Update CKYC</a>
          </div>
        </div>
      </div>
      <div className="Header_nav-link-container__nlUUp flex align-center only-desktop">
        <div className="primaryButton_primary__lQkYH">
          <button
            className="primaryButton_primary-button__ZYTTy flex flex-row justify-center align-center text-16 font-extra-bold primaryButton_btn-blue___2H9y">
            <a style={{color: "white"}} href="https://www.starhealth.in/buy-now/health/instantbuy/userDetails/?leadSource=instantbuy">
              <span className="btn-text">Buy Now</span></a>
          </button>
        </div>
      </div>
      <Hidden lgUp>
        
      <IconButton
              color='inherit'
              aria-label='open drawer'
              onClick={this.handleMobileview}>
              <Menu />
            </IconButton>
              <Drawer 
              id='mobileDrawer'
              open = {this.state.mobileview}
              anchor
              ={'right'}
              style={{overflowX: 'hidden'}}
              >
    <div className="flex align-center justify-between-md w-full">
        <span className="Header_logo-xs__yzxxa Header_logo-sm__M3ZfU mr-24">
          <img alt="Star Health Logo" src="./img/star_health_logo_big.svg" className="cursor-pointer" />
        </span>
          <div className="flex align-center">
            <div className="Header_top-header-xs__FRedm flex align-center">
              <a className="flex align-center text-no-wrap text-16" href="tel:04469006900"><span
                  className="only-mobile show-tab">Buy</span>
                <div className="Header_mobile-buy-xs__gY5et ml-6 mr-12 relative only-mobile show-tab">
                  <span><img alt="" src="./img/mobile-icon.svg" /></span>
                </div>
              </a><a target="_blank" className="flex align-center text-no-wrap text-16 ml-8"
                href="https://apps.apple.com/us/app/star-power/id1477621177?ls=1" >
                <div className="flex align-center">
                  <span className="only-mobile text-16 show-tab">App</span>
                  <div className="Header_mobile-app-xs__cspiU ml-6 mr-12 relative only-mobile show-tab">
                    <span><img alt="Star health iOS app" src="./img/iOS-xs.svg" /></span>
                  </div>
                </div>
              </a><a target="_blank" className="Header_mobile-app-xs__cspiU mr-12 relative only-mobile show-tab"
                href="https://play.google.com/store/apps/details?id=com.star.customer_app" ><span><img
                    alt="Star health andriod app" src="./img/playstore-xs.svg" /></span></a>

                    <IconButton
              aria-label='open drawer'
              onClick={this.handleMobileview}
              className="closeButtonDrawer"
              style={{top: '3px'}}>
              <Menu />
            </IconButton>
                    
            </div>
		   </div>
          </div>

                <div className="Header_kyc-info__FrpZo text-14" style={{backgroundColor: '#f2f4f7',padding: '8px',display: "flex",
                  justifyContent: "center",
                  alignItems: "center"}}>
                    <span className="Header_new-tag__d3zVz mr-8 text-white">New</span>
                    <span
                    className="text-ternary kyc-detail" > <a href='https://bimabharosa.irdai.gov.in/Home/Home'>"IGMS is now BIMA BHAROSA "- An Integrated Grievance Management System to facilitate the policyholders and complainants to file their grievances with IRDAI - Click here </a><b className="bull text-20 mr-4 ml-4">| </b>
                      <a href='https://kychub.starhealth.in/kychub/ui/customer-login'>Click here to link your KYC </a><b className="bull text-20 mr-4 ml-4">| </b>
                      Download our App -<a href='https://play.google.com/store/apps/details?id=com.star.customer_app&hl=en&gl=US'>Andriod</a><a href='https://apps.apple.com/us/app/star-health/id1477621177'>IOS</a>to do the ABHA Registration</span>
                  </div>
            <div style={{padding: '10px 10px 0px 20px'}}>
              <Accordion
              collapses={[
                {
                  title: `Plans`,
                  content: (<>{
                    <MVAccordion  
                      collapses={[
                        {
                          title: `HEALTH`,
                          content: (<>{
                            <PdAccordionTree treedata={treedata} mobileView={true} />
                          }</>)
                        }
                      ]}
                      onChangeHandler={(activeArray)=>this.setActiveSubdropdown(activeArray,'HEALTH')}
                      forceInActive={!this.checkActiveDropdown('HEALTH')}
                      mobileView={true}>
                      </MVAccordion>
                  }
                  {<MVAccordion
                      collapses={[
                        {
                          title: `ACCIDENT`,
                          content: (<>{
                            <PdAccordionTree treedata={accidentMV} mobileView={true} />
                          }</>)
                        }
                      ]}
                      onChangeHandler={(activeArray)=>this.setActiveSubdropdown(activeArray,'ACCIDENT')}
                      forceInActive={!this.checkActiveDropdown('ACCIDENT')}
                      mobileView={true}>
                      </MVAccordion>}
                      {<MVAccordion
                      collapses={[
                        {
                          title: `TRAVEL`,
                          content: (<>{
                            <PdAccordionTree treedata={travelMV} mobileView={true} />
                          }</>)
                        }
                      ]}
                      onChangeHandler={(activeArray)=>this.setActiveSubdropdown(activeArray,'TRAVEL')}
                      forceInActive={!this.checkActiveDropdown('TRAVEL')}
                      mobileView={true}>
                      </MVAccordion>}
                      {<MVAccordion
                      collapses={[
                        {
                          title: `CORPORATE`,
                          content: (<>{
                            <PdAccordionTree treedata={corporateMV} mobileView={true} />
                          }</>)
                        }
                      ]}
                      onChangeHandler={(activeArray)=>this.setActiveSubdropdown(activeArray,'CORPORATE')}
                      forceInActive={!this.checkActiveDropdown('CORPORATE')}
                      mobileView={true}>
                      </MVAccordion>}
                  </>)
                }
              ]}
              onChangeHandler={(activeArray)=>this.setActiveAccordion(activeArray,'Plans')}
              forceInActive={!this.checkActive('Plans')}
              mobileView={true}>
              </Accordion>
              <Accordion
              collapses={[
                {
                  title: `Customer`,
                  content: (<>{
                    <MVAccordion
                    collapses={[
                      {
                        title: `LOGIN`,
                        content: (<>{
                          <PdAccordionTree treedata={treedata7} mobileView={true} />
                        }</>)
                      }
                    ]}
                    onChangeHandler={(activeArray)=>this.setActiveSubdropdown(activeArray,'LOGIN')}
                      forceInActive={!this.checkActiveDropdown('LOGIN')}
                    mobileView={true}> 
                    </MVAccordion>
                  }
                  {
                    <MVAccordion
                    collapses={[
                      {
                        title: `SERVICES`,
                        content: (<>{
                          <PdAccordionTree treedata={treedata6} mobileView={true}/>
                        }</>)
                      }
                    ]}
                    onChangeHandler={(activeArray)=>this.setActiveSubdropdown(activeArray,'SERVICES')}
                      forceInActive={!this.checkActiveDropdown('SERVICES')}
                    mobileView={true}> 
                    </MVAccordion>
                  }</>)
                }
              ]}
              onChangeHandler={(activeArray)=>this.setActiveAccordion(activeArray,'Customer')}
              forceInActive={!this.checkActive('Customer')}
              mobileView={true}>
              </Accordion>
              <div class="cursor-pointer nav-link flex pr-36 " style={{borderBottom: '1px solid rgb(221, 221, 221)',marginBottom: '3%'}} >
                <a class="mobile-view"
                  href="https://customer.starhealth.in/customerportal/instant-renewal/">Renew</a>
              </div>
              <Accordion
              collapses={[
                {
                  title: `Claims`,
                  content: (<>{
                    <MVAccordion
                    collapses={[
                      {
                        title: `CLAIMS`,
                        content: (<>{
                          <PdAccordionTree treedata={ClaimsMV} mobileView={true} />
                        }</>)
                      }
                    ]}
                    onChangeHandler={(activeArray)=>this.setActiveSubdropdown(activeArray,'CLAIMS')}
                      forceInActive={!this.checkActiveDropdown('CLAIMS')}
                    mobileView={true}> 
                    </MVAccordion>
              }
                  {
                    <MVAccordion
                    collapses={[
                      {
                        title: `COVID 19`,
                        content: (<>{
                          <PdAccordionTree treedata={COVID19MV} mobileView={true} />
                        }</>)
                      }
                    ]}
                    onChangeHandler={(activeArray)=>this.setActiveSubdropdown(activeArray,'COVID 19')}
                      forceInActive={!this.checkActiveDropdown('COVID 19')}
                    mobileView={true}> 
                    </MVAccordion>
                   }</>)
                }
              ]}
              onChangeHandler={(activeArray)=>this.setActiveAccordion(activeArray,'Claims')}
              forceInActive={!this.checkActive('Claims')}
              mobileView={true}> 
              </Accordion>
              <Accordion
              collapses={[
                {
                  title: `Service Providers`,
                  content: (<>{
                    <MVAccordion
                    collapses={[
                      {
                        title: `FIND HOSPITALS`,
                        content: (<>{
                          <PdAccordionTree treedata={FineHospitalsMV} mobileView={true}/>
                        }</>)
                      }
                      
                    ]}
                    onChangeHandler={(activeArray)=>this.setActiveSubdropdown(activeArray,'FIND HOSPITALS')}
                    forceInActive={!this.checkActiveDropdown('FIND HOSPITALS')}
                    mobileView={true}>
                    </MVAccordion>
                   }
                  {
                    <MVAccordion
                    collapses={[
                      {
                        title: `HEALTH CHECKUPS & OPD`,
                        content: (<>{
                          <PdAccordionTree treedata={HealthcheckupMV} mobileView={true} />
                        }</>)
                      }
                    ]}
                    onChangeHandler={(activeArray)=>this.setActiveSubdropdown(activeArray,'HEALTH CHECKUPS & OPD')}
                    forceInActive={!this.checkActiveDropdown('HEALTH CHECKUPS & OPD')}
                    mobileView={true}> 
                    </MVAccordion>
                   }
                  </>)
                }
              ]}
              onChangeHandler={(activeArray)=>this.setActiveAccordion(activeArray,`Service Providers`)}
              forceInActive={!this.checkActive(`Service Providers`)}
              mobileView={true}>
              </Accordion>
              <Accordion
              collapses={[
                {
                  title: `Contact Us`,
                  content: (<>{
                    <MVAccordion
              collapses={[
                {
                  title: `POLICY`,
                  content: (<>{
                    <PolicyMobileview treedata={PolicyContactMV} mobileView={true}/>
                  }</>)
                }
              ]}
              onChangeHandler={(activeArray)=>this.setActiveSubdropdown(activeArray,'HEALTH CHECKUPS & OPD')}
              forceInActive={!this.checkActiveDropdown('HEALTH CHECKUPS & OPD')}
              mobileView={true}>
              </MVAccordion>
                  }
                  {
                    <MVAccordion
              collapses={[
                {
                  title: `CLAIMS`,
                  content: (<>{
                    <PdAccordionTree treedata={ClaimsContactMV} mobileView={true}/>
                  }</>)
                }
              ]}
              onChangeHandler={(activeArray)=>this.setActiveSubdropdown(activeArray,'CLAIMS')}
              forceInActive={!this.checkActiveDropdown('CLAIMS')}
              mobileView={true}>
              </MVAccordion>
                 }
                  {
                    <MVAccordion
              collapses={[
                {
                  title: `PARTNERS`,
                  content: (<>{
                    <PdAccordionTree treedata={PartnersContactMV} mobileView={true}/>
                  }</>)
                }
              ]}
              onChangeHandler={(activeArray)=>this.setActiveSubdropdown(activeArray,'PARTNERS')}
              forceInActive={!this.checkActiveDropdown('PARTNERS')}
              mobileView={true}>
              </MVAccordion>
                   }</>)
                }
              ]}
              onChangeHandler={(activeArray)=>this.setActiveAccordion(activeArray,`Contact Us`)}
              forceInActive={!this.checkActive(`Contact Us`)}
              mobileView={true}>
              </Accordion>
              <div class="cursor-pointer nav-link flex pr-36 " style={{borderBottom: '1px solid rgb(221, 221, 221)',marginBottom: '3%'}} >
                <a class="mobile-view"
                  href="https://help.starhealth.in/">Help</a>
              </div>
              <div class="cursor-pointer nav-link flex pr-36 " style={{borderBottom: '1px solid rgb(221, 221, 221)',marginBottom: '3%'}} >
                <a class="mobile-view"
                  href="https://kychub.starhealth.in/kychub/ui/customer-login">Update CKYC</a>
              </div>
              </div>
              <div className="btns-buy-now pt-12 pl-24 pr-24 mb-24">
              <button className="Header_buy-now-btn__dV1N1">
              <a style={{color: "white"}} href="https://www.starhealth.in/buy-now/health/instantbuy/userDetails/?leadSource=instantbuy">
              <span className="text-white text-16 font-800">Buy Now</span>
              </a>
              </button>
                    </div>
                    <div className="undefined Header_header-links-secondary__irtlp" >
                      <div id='partners' style={this.state.changeStyle ? {backgroundColor: '#f2f4f7', color: 'rgba(0,0,0,.85)'} : {}}>
                      <div className="undefined Header_login-accordion__OlO0O undefined">
                        <div className="ant-collapse ant-collapse-icon-position-end ant-collapse-ghost">
                          <div className="ant-collapse-item text-white text-16 pt-16 pb-16 pl-24 pr-24 collapse-text-white">
                            <div className="ant-collapse-header" aria-expanded="false" aria-disabled="false" role="button"
                              style={{marginBottom:'-5%'}}
                              tabindex="0">
                              <div className="ant-collapse-expand-icon" >
                              </div>
                              <Accordion
                              whiteText={!this.state.changeStyle}
                              collapses={[
                                {
                                  title: `Partners`,
                                  onChangeHandler: (isPanelExpanded)=>{this.changeStyle(isPanelExpanded,'0')},
                                  content: (<>{

                                    <MVAccordion
                                    
                                    collapses={[
                                      {
                                        title: `Agents`,
                                        forceTitleColor: this.state.changeStyle ? 'rgba(0,0,0,.85)' : '',
                                        content: (<>{
      
                                          <PdAccordionTree treedata={AgentsPArtnersMV} mobileView={true}/>
                                        }</>)
                                      }
                                      
                                    ]}
                                    onChangeHandler={(activeArray)=>this.setActiveSubdropdown(activeArray,'Agents')}
                                    forceInActive={!this.checkActiveDropdown('Agents')}
                                    HeaderAccordian={true}
                                    style={{color: 'white'}}
                                    >
                                    </MVAccordion>
                              }
                                  
                                  {
                                      <MVAccordion
                                      collapses={[
                                        {
                                          title: `Intermediaries`,
                                          forceTitleColor: this.state.changeStyle ? 'rgba(0,0,0,.85)' : '',
                                          content: (<>{

                                            <PdAccordionTree treedata={IntermediariesPartnersMV} mobileView={true}/>
                                          }</>)
                                        }
                                        
                                      ]}
                                      onChangeHandler={(activeArray)=>this.setActiveSubdropdown(activeArray,'Intermediaries')}
                                    forceInActive={!this.checkActiveDropdown('Intermediaries')}
                                HeaderAccordian={true}
                                whiteText={this.state.changeStyle !== true}
                                style={this.state.changeStyle ? {} : {color: 'white'}}
                                >
                                </MVAccordion>
                                }
                                {

                                <MVAccordion
                                collapses={[
                                  {
                                    title: `Service Providers`,
                                    forceTitleColor: this.state.changeStyle ? 'rgba(0,0,0,.85)' : '',
                                    content: (<>{

                                      <PdAccordionTree treedata={ServiceProvidersPartnersMV} mobileView={true}/>
                                    }</>)
                                  }
                                  
                                ]}
                                onChangeHandler={(activeArray)=>this.setActiveSubdropdown(activeArray,'Service Providers')}
                                forceInActive={!this.checkActiveDropdown('Service Providers')}
                                HeaderAccordian={true}
                                whiteText={this.state.changeStyle !== true}
                                style={{color: 'white'}}
                                >
                                </MVAccordion>
                                }
                                {

                                <MVAccordion
                                collapses={[
                                  {
                                    title: `Employees`,
                                    forceTitleColor: this.state.changeStyle ? 'rgba(0,0,0,.85)' : '',
                                    content: (<>{

                                      <PdAccordionTree treedata={EmployeesPArtnersMV} mobileView={true}/>
                                    }</>)
                                  }
                                  
                                ]}
                                onChangeHandler={(activeArray)=>this.setActiveSubdropdown(activeArray,'Employees')}
                                forceInActive={!this.checkActiveDropdown('Employees')}
                                HeaderAccordian={true}
                                whiteText={this.state.changeStyle !== true}
                                style={{color: 'white'}}
                                >
                                </MVAccordion>
                                }
                                  </>)
                                }
                              ]}
                              HeaderAccordian={true}
                              
                              >
                              </Accordion>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="undefined Header_login-accordion__OlO0O undefined" style={{marginTop: '-7%'}}>
                        <div className="ant-collapse ant-collapse-icon-position-end ant-collapse-ghost">
                          <div className="ant-collapse-item text-white text-16 pb-16 pl-24 pr-24 collapse-text-white">
                            <div className="ant-collapse-header" aria-expanded="false" aria-disabled="false" role="button"
                            style={{margintop: '-10px',marginBottom:'-5%'}}
                              tabindex="0">
                              <Accordion
                              whiteText={!this.state.changeStyle}
                              collapses={[
                                {
                                  title: `Investors`,
                                  onChangeHandler: (isPanelExpanded)=>{this.changeStyle(isPanelExpanded,'1')},
                                  content: (<>{
                                    <PdAccordionTree treedata={treedata2} whiteText={false}/>
                                  }</>)
                                }
                              ]}
                             forceTitleColor={this.state.changeStyle['1'] ? "rgba(0,0,0,.85)" : ""}
                              style={{color: 'white'}}
                              >
                              </Accordion>
                            </div>
                          </div>
                        </div>
                      </div>
                      </div>
                      {/* <div
                        className="Header_hamburger-items__xQKfO hamburger-items link-items outline-none linking pt-16 pb-16 pl-24 pr-24">
                        <a href="https://www.starhealth.in/portability/"><span
                            className="text-16 font-500 text-white">Port-In</span></a>
                      </div> */}
                      <div
                        className="Header_hamburger-items__xQKfO hamburger-items link-items outline-none linking pt-16 pb-16 pl-24 pr-24" style={{marginTop: '-5px'}}>
                        <a href="https://www.starhealth.in/blog/"><span
                            className="text-16 font-500 text-white">Blog</span></a>
                      </div>
                    </div>
              </Drawer>
        </Hidden>
    </div>
    );
  }

  checkActive(accordion) {
    return this.state.activeAccordion === accordion
  }

  checkActiveDropdown(Dropdown) {
    return this.state.activeDropdown === Dropdown
  }
}

CustomHeaderLinks.defaultProps = {
  hoverColor: "primary",
};

CustomHeaderLinks.propTypes = {
  dropdownHoverColor: PropTypes.oneOf([
    "dark",
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "rose",
  ]),
};

export default withStyles(headerLinksStyle)(CustomHeaderLinks);
