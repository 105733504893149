export default function() {
    return {
     accordion: {
        "&:hover": {
            cursor: `pointer`
        },
     },
     pdLink: {
         color: `blue`,
        "&:hover": {
            cursor: `pointer`
        },
     },
    }
}