import React from "react";
import claimListStyle from "ps-assets/jss/claimListStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import CashlessContent from "./CashlessContent.jsx";
import ClaimIntimationList from './claimIntimationList.jsx'
import ReimbursementContent from "./ReimbursementContent.jsx";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Accordion from "components/Accordion/Accordion";
import GridContainer from "components/Grid/GridContainer";
import {withPrefix } from "gatsby"
import Datetime from "react-datetime";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import ClaimStatusContent from "components/ClaimStatusContent/ClaimStatusContent";
import CardHeader from "components/Card/CardHeader.jsx";
import PropTypes from "prop-types";
import Button from "components/CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import Loading from "components/Loading/Loading";
import classNames from 'classnames';
const CUSTOMER_PORTAL_API_URL = process.env.CUSTOMER_PORTAL_API_URL;

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

class ClaimStatus extends React.Component {
  state = {
    response: ``,
    intimationNumber: ``,
    idCardNumber: ``,
    largeModal: false,
    searchByPolicyNumber:false,
    intimationNo: ``,
    resJsonStatus: false,
    resJsonStatusAfter: false,
    resJson: ``,
    errorMsgforPolicy:``,
    resJsonStatusError: false,
    errorMessage: ``,
    policyNumber:``,
    dob:``,
    Loading: false,
  };

  handleClose(modal) {
    var x = [];
    x[modal] = false;
    this.setState({largeModal:false,response:``});
  }

  handleClickOpen = async (modal,intimationnumber,idcardNumber) => {
    this.setState({
      largeModal: true,
      Loading:true
    })
    const { apiBaseUrl ,currentClaims} = this.props;
    let resValue;
    let url = apiBaseUrl;
    if(intimationnumber.length>0)
    {
    this.setState({
      fromIntimation:true,
      intimationNumber:intimationnumber
    })
  }
   let  searchByPolicyNumber=this.state.searchByPolicyNumber?true:false
   if(searchByPolicyNumber){
      this.setState({
        submitButton: true,
        response: ``,
        resJsonStatusAfter: false,
        resJsonStatusError: false,
        resJsonStatusGalaxy: false,
        resJsonStatus:currentClaims!='group' && (this.state.policyNumber.length == 0 || this.state.dob.length==0)? true:currentClaims=='group' && (this.state.policyNumber.length == 0 || this.state.idCardNumber.length==0)?true: false,
        errorMsgforPolicy:currentClaims=='group'?'Please enter both policy number and Intimation Number':'Please enter both policy number and Date of Birth'
      });
      let url1=`${CUSTOMER_PORTAL_API_URL}/cp/claim/list/policy`
      let RequestBody
      if(currentClaims === `op`)
      RequestBody= JSON.stringify([{
        policyNumber:this.state.policyNumber,
        healthId:this.state.idCardNumber,
        category:'OP'
        }])
      else
      RequestBody=JSON.stringify([{
        policyNumber:this.state.policyNumber,
        healthId:this.state.idCardNumber,
        }])
      await fetch(url1, {
        method: `POST`,
        headers: {
          "Content-Type": `application/json`
        },
        body: RequestBody
      })
        .then(res => res.json())
        .then(res => {
          resValue=res &&res.data &&  res.data[0] && res.data[0].claimsList,
          this.setState({
            // location: location,
            response: res &&res.data &&  res.data[0] && res.data[0].claimsList,
            initiateCreateMarkers: true,
            initiateCreateList: true,
          });
        })
        .catch(err => {
          console.log(err);
        });
        sessionStorage.setItem('claimList',JSON.stringify(resValue))
    }
    else if (modal == `largeModal` && !searchByPolicyNumber ) {
      this.setState({
        submitButton: true,
        response: ``,
        resJsonStatusAfter: false,
        resJsonStatusError: false,
        resJsonStatusGalaxy: false,
        resJsonStatus:
          this.state.intimationNumber.length == 0 ||
          this.state.idCardNumber.length == 0
            ? true
            : false
      });
    }
    let params = {
      method:`GET`,
      headers:{clientId: `7X2hEMm7YlhRXb7S3fjYs4GHXTeroWUr`},
    };
    let data = {
      intimationNumber:
        this.state.intimationNumber != `` ? this.state.intimationNumber : intimationnumber,
      idCardNumber: this.state.idCardNumber != `` ? this.state.idCardNumber : idcardNumber
    };
    url = `${url}claimNo=${data.intimationNumber}&healthId=${data.idCardNumber}&prodCode=`;
    url += currentClaims === `op`
      ? `MED-PRD-077`
      : ``;
    if (data.intimationNumber.length != 0 && data.idCardNumber.length != 0) {
      let res, resJson;
      await fetch(url, params)
        .then(async (resp) => {
          res = resp;
          resJson = await resp.json();
        })
        .catch(() => {
          res = { status: 500 };
          resJson = { message: `System unavailable, please try again later.` };
        });
      this.setState({
        resJson: resJson
      });
      if (res.status !== 200) {
        this.setState({
          submitStatus: `errored`,
          resJsonStatusError: true,
          errorMessage: resJson.message
        });
      } else {
        if (resJson && resJson.error && resJson.error.length != 0) {
          this.setState({
            resJsonStatusError: true
          });
        }
        if (resJson && resJson.data && resJson.data.length != 0) {
          this.setState({
            resJsonStatus: false,
            response: resJson.data,
            submitStatus: `success`,
            searchByPolicyNumber:false,
            intimationNo: this.state.intimationNumber,
            intimationNumber: ``,
            idCardNumber: ``,
            submitButton: false
          });
        }
        if (resJson && resJson.data && resJson.data.length === 0) {
          this.setState({
            resJsonStatusAfter: true,
            response: resJson.data,
            submitStatus: `success`,
            submitButton: false
          });
        }
      }
    }
    var x = [];
    x[modal] = true;
    this.setState(x);
    {this.state.response && this.state.response.length != 0 &&(
      this.setState({
        Loading:false
      })
    )}
  };

  handleChange = e => {
    if(e.target.value[e.target.value.length-1] != " ")
      this.setState({ [e.target.id]: e.target.value });
  };
  onClickPolicyNumber=()=>{
    this.setState({
      searchByPolicyNumber:true
    })
  }
  onClickIntimationNumber=()=>{
    this.setState({
      searchByPolicyNumber:false
    })
  }
  handleDate = value => {
    let dateSeperator='-'
    // let dob=moment().format('DD/MM/YYY')
    // console.log('123',dob)
    switch (typeof value) {
      case `string`:
        if(value.search(`^[0-9-]*$`) === 0){
        if(this.state.dob !== value+dateSeperator){
        if(!value.endsWith(dateSeperator)){
        switch (value.length) {
          case 2:
          case 5:
            value = value + dateSeperator;
            break;
        }
        }else{
          value = value.substr(0,value.length-1);
        }
        }
        if (value.length <= 10) {
          this.setState({ dob: value });
        }
        }
        break;
      case `object`:
        this.setState({ dob: value.format(`DD-MM-YYYY`) });
        break;
    }
    // this.setState({ dob: dob });
  };
  onClickback=()=>{
    let claimList= sessionStorage.getItem('claimList')
if(claimList)
{
  claimList=JSON.parse(claimList)
  this.setState({
    searchByPolicyNumber:true,
    response:claimList
  })
}
  }
  render() {
    const { classes } = this.props;
    var dateValidtion = Datetime.moment().add(0, `day`);
    var valid_default = function(current) {
      return current.isBefore(dateValidtion);
    };
    return (
      <>
      <GridItem xs={12} md={12}>
        <Card className={classes.card}>
          <CardBody
            formHorizontal
            style={{ paddingTop: `0px`, paddingBottom: `0px` }}
          >
            <form>
              <GridContainer>
              {this.state.searchByPolicyNumber == false ?
                (
                  <>
                  <GridItem xs={12} sm={4} md={4}>
                    <CustomInput
                      id="intimationNumber"
                      inputProps={{
                        placeholder: `Intimation Number`,
                      }}
                      formControlProps={{
                        fullWidth: true,
                        className: classes.formControl,
                      }}
                      value={this.state.intimationNumber}
                      onChange={this.handleChange}
                    />
                  </GridItem>
             
                  <GridItem xs={12} sm={4} md={4}>
                    <CustomInput
                      id="idCardNumber"
                      inputProps={{
                        placeholder: `ID Card Number`,
                      }}
                      formControlProps={{
                        fullWidth: true,
                        className: classes.formControl,
                      }}
                      value={this.state.idCardNumber}
                      onChange={this.handleChange}
                    />
                   
                  </GridItem>
                  </>
                     ):(
                      <>
                      <GridItem xs={12} sm={4} md={4}>
                        <CustomInput
                          id="policyNumber"
                          inputProps={{
                            placeholder: `Policy Number`,
                          }}
                          formControlProps={{
                            fullWidth: true,
                            className: classes.formControl,
                          }}
                          value={this.state.policyNumber}
                          onChange={this.handleChange}
                        />
                      </GridItem>
                 {this.props.isGroupProduct== false &&(
                      <GridItem xs={12} sm={4} md={4} style={{marginTop:'11px'}}>
                      <Datetime
                              style={{ marginTop: `10px`, color: `#999` }}
                              timeFormat={false}
                              id='dob'
                              inputProps={{
                                placeholder: `Date of birth of the policy holder (DD-MM-YYYY)`
                              }}
                              className={`${classes.description} `}
                              value={this.state.dob}
                              onChange={this.handleDate}
                              onClick={this.handleDate
                              }
                              isValidDate={valid_default}
                              closeOnSelect={true}
                            />
                       
                      </GridItem>)}
                      {this.props.isGroupProduct==true && (
                          <GridItem xs={12} sm={4} md={4}>
                          <CustomInput
                            id="idCardNumber"
                            inputProps={{
                              placeholder: `ID Card Number`,
                            }}
                            formControlProps={{
                              fullWidth: true,
                              className: classes.formControl,
                            }}
                            value={this.state.idCardNumber}
                            onChange={this.handleChange}
                          />
                          </GridItem>
                      )}
                      </>
                      )}


                <GridItem
                  xs={12}
                  sm={4}
                  md={4}
                  style={{
                    alignSelf: `center`
                  }}
                >
                      <Button
                        block
                        color="primary"
                        className={classes.button}
                        onClick={() => this.handleClickOpen(`largeModal`,'','')}
                      >
                        GET CLAIM STATUS
                      </Button>
                          <>
                        <Dialog
                          classes={
                            {root: classes.modalRoot,
                            paper: (this.state.Loading ? classes.modal1 : this.state.searchByPolicyNumber ? classes.modal1 : classes.modal2) + ` ` + classes.modalSignup}}
                          open={this.state.largeModal && this.state.resJsonStatus==false}
                          TransitionComponent={Transition}
                          keepMounted
                          onClose={() => this.handleClose(`largeModal`)}
                          aria-labelledby="large-modal-slide-title"
                          aria-describedby="large-modal-slide-description"
                        >
                           <DialogTitle
                            id="large-modal-slide-title"
                            disableTypography
                            className={classes.modalHeader}
                          >
                            <CardHeader
                              color="primary"
                              signup
                              className={classes.cardHeader}
                              style={{
                                marginLeft: 0,
                                marginRight: 10,
                                borderRadius: `10px`
                              }}
                            >
                            
                              <GridContainer>
                                <GridItem
                                  xs={2}
                                  md={2}
                                  lg={2}
                                  sm={2}
                                  style={{marginTop:'17px'}}
                                >
{this.state.searchByPolicyNumber==false && this.state.fromIntimation==true  &&(
                               <p onClick={()=>{this.onClickback()}}style={{float:'left',color:'white',paddingLeft:'28px',cursor:'pointer'}} >BACK</p>)}

                                </GridItem>
                                <GridItem xs={8} md={8} lg={8} sm={8}>
                                  <h2
                                    className={classes.cardTitle}
                                    style={{
                                      color: `white`,
                                      textAlign: `center`,
                                      fontSize: 30
                                    }}
                                  >
                                {this.state.searchByPolicyNumber?"Check status for one of these claims":'Status of Intimation Number' }
                                  </h2>
                                  <h5
                                    style={{
                                      color: `white`,
                                      textAlign: `center`
                                    }}
                                  >
                                    {!this.state.searchByPolicyNumber?this.state.intimationNo:''}
                                  </h5>
                                  {` `}
                                </GridItem>
                                <GridItem xs={2} md={2} lg={2} sm={2}>
                                  <Button
                                    simple
                                    key="close"
                                    aria-label="Close"
                                    color="primary"
                                    onClick={() =>
                                      this.handleClose(`largeModal`)
                                    }
                                    style={{
                                      float: `right`,
                                      color: `white`
                                    }}
                                  >
                                    close
                                  </Button>
                                </GridItem>
                              </GridContainer>
                            </CardHeader>
                          </DialogTitle>
                          
                          {this.state.Loading && this.state.Loading==true?
                        (
                          <Loading
                            classes={classNames(classes.mlAuto, classes.mrAuto, classes.loading)}
                          />)
                        :(
                          <>
                          {this.state.response && this.state.response.length != 0 && ( 
                            <>
                         <DialogContent
                            id="large-modal-slide-description"
                            className={classes.modalBody}
                          >   
                            {this.state.searchByPolicyNumber==false?(
                            <Accordion
                              active={0}
                              collapses={[
                                {
                                  title: `Intimation`,
                                  content: (
                                    <ClaimStatusContent
                                      data={this.state.response[0]}
                                    />
                                  )
                                },
                                {
                                  title: `Cashless Details`,
                                  content: (
                                    <CashlessContent
                                      data={
                                        this.state.response[0].cashlessDetails
                                      }
                                    />
                                  )
                                },
                                {
                                  title: `Reimbursement Details`,
                                  content: (
                                    <ReimbursementContent
                                      data={
                                        this.state.response[0]
                                          .reimbursementDetails
                                      }
                                    />
                                  )
                                }
                              ]}
                            />
                            ):(
                              this.state.response && this.state.response.map((intimation)=>{
                                  return(
                                  <>
                                  <ClaimIntimationList 
                                  intiamtionDetails={intimation}
                                  onSelectClaim={this.handleClickOpen}
                                  />   
                                  </>
                                  )
                              })
                            
                            )}
                            <div>
                              <Button
                                simple
                                key="close"
                                aria-label="Close"
                                color="primary"
                                onClick={() => this.handleClose(`largeModal`)}
                                style={{ float: `right` }}
                              >
                                close
                              </Button>
                            </div>
                          </DialogContent>
                          </>
                          )}
                          </>)}
                        </Dialog>
                    </>
                </GridItem>
                <GridItem style={{marginLeft: '15%',marginBottom: '8px'}} >
                <img src={withPrefix("/Rectangle_961.png")} style={{marginRight: '10px'}} >
                </img>
                  OR
                <img src={withPrefix("/Rectangle_961.png")} style={{marginLeft: '10px' }} >
                </img>
                </GridItem>
                {this.state.searchByPolicyNumber==false?
                (<GridItem
                className={classes.textposition}
                    xs={12}
                    sm={12}
                    md={12}
                    style={{marginLeft: '15.5%'}}
                  >
                    For using Policy Number and 
                    {this.props.isGroupProduct==true ? ' Id card Number' : ' Date of Birth'}
                    {" "}
                    <span onClick={this.onClickPolicyNumber} style={{cursor: 'pointer',color: '#35488a'}} >click here</span>
                    </GridItem>)
                    :
                    (<GridItem
                    className={classes.textposition}
                    xs={12}
                    sm={12}
                    md={12}
                    style={{marginLeft: '13.5%'}}
                  >
                    For using Intimation Number and Id card Number{" "}
                    <span onClick={this.onClickIntimationNumber} style={{cursor: 'pointer', color: '#35488a'}} >click here</span>
                  </GridItem>)
                  }
              </GridContainer>
            </form>
          </CardBody>
        </Card>
      </GridItem>
        {this.state.resJsonStatus && (  
          <div id="unMatchedRecords">
            <span
              style={{
                fontSize: `13px`,
                color: `red`,
                textAligin: `center`,
                paddingLeft: `20px`
              }}
            >
              {this.state.errorMsgforPolicy && this.state.errorMsgforPolicy.length>0?this.state.errorMsgforPolicy:`Please enter both intimation number and Id card Number`}
            </span>
          </div>
        )}
        {this.state.resJsonStatusAfter && (
          <div id="unMatchedRecords">
            <span
              style={{
                fontSize: `13px`,
                color: `red`,
                textAligin: `center`,
                paddingLeft: `20px`
              }}
            >
              {this.state.resJson.message}
            </span>
          </div>
        )}
        {this.state.resJsonStatusError && (
          <div id="unMatchedRecords">
            <span
              style={{
                fontSize: `13px`,
                color: `red`,
                textAligin: `center`,
                paddingLeft: `20px`
              }}
            >
              {
                this.state.errorMessage === `` ?  
                  `System unavailable, please try again later.` :
                  this.state.errorMessage
              }
            </span>
          </div>
        )}
      </>
    );
  }
}

ClaimStatus.propTypes = {
  classes: PropTypes.object,
  apiBaseUrl: PropTypes.string,
  currentClaims:PropTypes.string,
  isGroupProduct:PropTypes.bool,
};

export default withStyles(claimListStyle)(ClaimStatus);
