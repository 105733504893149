import {
    ApolloClient,
    InMemoryCache,
    createHttpLink,
    ApolloLink,
  } from '@apollo/client';
import Cookies from "js-cookie";
import fetch from 'isomorphic-fetch'
  
  const httpLink = createHttpLink({
    fetch,
    uri: process.env.REACT_APP_GRAPHQL_API_URL, // Your GraphQL API endpoint
  });
  
  const authLink = new ApolloLink((operation, forward) => {
    // Retrieve the authentication token from wherever you have it stored
    const authToken = Cookies.get('customer_accessToken')
  
    // Set the authentication token in the headers
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        Authorization: authToken ? `${authToken}` : '',
      },
    }));
  
    return forward(operation);
  });
  
  const client = new ApolloClient({
    link: authLink.concat(httpLink), // Use the authLink before the httpLink
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {},
        },
      },
    }),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'no-cache',
      },
      query: {
        fetchPolicy: 'no-cache',
      },
    },
  });
  
  export default client;
  