import React,{useState, useEffect}  from 'react'
import withStyles from "@material-ui/core/styles/withStyles";
import claimListStyle from "ps-assets/jss/claimListStyle.jsx";
import GridContainer from "components/Grid/GridContainer";
import classNames from "classnames";
import Button from "components/CustomButtons/Button.jsx";
import PropTypes from 'prop-types';
import GridItem from "components/Grid/GridItem";
import ClaimUploadDocuments from './ClaimUploadDocumnets'
import { browserWindow } from 'util/localStorageHelper';

const CUSTOMER_PORTAL_API_URL = process.env.CUSTOMER_PORTAL_API_URL;
const ClaimStatusContainer = ({classes, data}) =>{
    const [currentClaims,changeClaims] = useState(`other`);
   
    useEffect(() => {
      if(data != undefined && data != null){
        changeClaims(data)
      }      
    }, [data]);
   
    return(
        <>        
          <GridContainer style={{marginLeft:'22px'}} md={12}>
            <GridItem className={classNames(classes.mTAuto,classes.mBAuto,classes.statusTitle)} md={4}>
              <h3
                className={classes.cardTitle}
                style={{ margin: `30px 0px` }}
              >
              Upload your documents
              </h3>
            </GridItem>
            <GridItem className={classNames(classes.mTAuto,classes.mBAuto,classes.toggleButtons)} md={8}>
              <Button
                className={currentClaims === `other` ? classes.primaryBorder : classes.noRightBorder}
                color={currentClaims === `other` ? `primary`:`transparent`} 
                onClick={()=>changeClaims(`other`)}
              >
              Health Policies
              </Button>
              <Button
                className={classes.primaryBorder}
                color={currentClaims === `op` ? `primary`:`transparent`} 
                onClick={()=>changeClaims(`op`)}
              >
              Outpatient Care Policy
              </Button>
              <Button
                className={currentClaims === `group` ? classes.primaryBorder : classes.noLeftBorder}
                color={currentClaims === `group` ? `primary`:`transparent`} 
                onClick={()=>changeClaims(`group`)}
              >
              Group product
              </Button>
            </GridItem>
          </GridContainer>
            <ClaimUploadDocuments
            isGroupProduct={currentClaims=='op'?true:false}
              currentClaims={currentClaims}
              document={document}
              classes={classes}
              apiBaseUrl={`${CUSTOMER_PORTAL_API_URL}/cp/claim/check/status?`}
            />
          </>
    );
  };
  
  ClaimStatusContainer.propTypes = {
    classes:PropTypes.object,
    data: PropTypes.string
  };
  class ClaimUploadBase extends React.Component{
    claims;
    componentDidMount(){
      const searchString = browserWindow.location.href;
      if(browserWindow.location.hash != '#1?Type=withoutLogin'){
        const req = searchString.split('?')[1];
          if(req != undefined && req != null){
            const paramJson = {};
            req.split('&').map((e) => {
              const p = e.split('=');
              paramJson[p[0]] = p[1];
            });
            let type = paramJson && paramJson.category && paramJson.category.toLowerCase();
            this.claims = type == 'health'? 'other': type;          
          }
        }
        else{
          this.claims = null;
        }
      }

      render(){
        const { classes } = this.props;
return(
    <GridContainer
    item
    md={10}
    className={classNames(classes.mrAuto, classes.mlAuto)}
    style={{ paddingTop: `70px` }}
  >
    <ClaimStatusContainer classes={classes} data={this.claims}/>
    </GridContainer>
)
      }
  }
  ClaimUploadBase.propTypes = {
    classes: PropTypes.object.isRequired,
    data: PropTypes.string
  };
export default withStyles(claimListStyle)(ClaimUploadBase);