import React,{useState} from "react";
import classNames from "classnames";
import { graphql } from "gatsby";
// @material-ui/core components
import Hidden from "@material-ui/core/Hidden";
import withStyles from "@material-ui/core/styles/withStyles";
import claimListStyle from "ps-assets/jss/claimListStyle.jsx";
import NavPills from "components/NavPills/NavPills.jsx";
import GridContainer from "components/Grid/GridContainer";
//import CustomMap from "ps-components/Maps/CustomMap";
import GridItem from "components/Grid/GridItem";
import Layout from "ps-components/Layout/Layout.jsx";
import Accordion from "components/Accordion/Accordion";
import Table from "components/Table/Table";
import Button from "components/CustomButtons/Button.jsx";
import ClaimsIntimation from "./claimsIntimation.jsx";
import Markdown from "react-markdown";
import { Helmet } from "react-helmet";
import ClaimStatus from "./claimStatus.jsx";
import ClaimUploadBase from './claimUploadBase'
import ClaimStatusBase from "./claimStatusBase";

// @material-ui/icons

import PropTypes from "prop-types";
//var apiBaseUrl = process.env.API_URL;
const CUSTOMER_PORTAL_API_URL = process.env.CUSTOMER_PORTAL_API_URL;
const CUSTOMERPORTAL_URL = process.env.CUSTOMERPORTAL_URL

const formDownloadData = {
  health: {
    url: `https://web.starhealth.in/sites/default/files/CLAIMFORM.pdf`,
    buttonText: `Download Health Claims Form`
  },
  travel: {
    url: `https://web.starhealth.in/sites/default/files/Star_Claim_Form.pdf`,
    buttonText: `Download Travel Claims Form`
  },
  accident: {
    url: `https://web.starhealth.in/sites/default/files/accident-claim-form.pdf`,
    buttonText: `Download Accident Claims Form`
  }
};

const ClaimStatusContainer = ({classes}) =>{
  const [currentClaims,changeClaims] = useState(`other`); 
  return(
      <>
        <GridContainer style={{marginLeft:'22px'}} md={12}>
          <GridItem className={classNames(classes.mTAuto,classes.mBAuto,classes.statusTitle)} md={4}>
            <h3
              className={classes.cardTitle}
              style={{ margin: `30px 0px` }}
            >
            Check your claim status for
            </h3>
          </GridItem>
          <GridItem className={classNames(classes.mTAuto,classes.mBAuto,classes.toggleButtons)} md={8}>
            <Button
              className={currentClaims === `other` ? classes.primaryBorder : classes.noRightBorder}
              color={currentClaims === `other` ? `primary`:`transparent`} 
              onClick={()=>changeClaims(`other`)}
            >
            Other Policies
            </Button>
            <Button
              className={classes.primaryBorder}
              color={currentClaims === `op` ? `primary`:`transparent`} 
              onClick={()=>changeClaims(`op`)}
            >
            Outpatient Care Policy
            </Button>
            <Button
              className={currentClaims === `group` ? classes.primaryBorder : classes.noLeftBorder}
              color={currentClaims === `group` ? `primary`:`transparent`} 
              onClick={()=>changeClaims(`group`)}
            >
            Group product
            </Button>
          </GridItem>
        </GridContainer>
          <ClaimStatus
          isGroupProduct={currentClaims=='group'?true:false}
            currentClaims={currentClaims}
            document={document}
            classes={classes}
            apiBaseUrl={`${CUSTOMER_PORTAL_API_URL}/cp/claim/check/status?`}
          />
        </>
  );
};

ClaimStatusContainer.propTypes = {
  classes:PropTypes.object
};

class ClaimsList extends React.Component {
  state = {
    idCard: ``,
    navPillArr: null,
    largeModal: false,
    loginModal: false,
    submitButton: false,
    response: ``,
    accordionActive: -1,
    intimationNumber: ``,
    intimationNo: ``,
    idCardNumber: ``,
    resJson: ``,
    resJsonStatus: false,
    resJsonStatusAfter: false,
    resJsonStatusError: false,
    resJsonStatusGalaxy: false
  };

  handleChange = e => {
    this.setState({ [e.target.id]: e.target.value });
  };

  componentDidMount() {
    const { location } = this.props;
    if (location.hash) {
      var hasharr = location.hash.split(`#`);
      if (hasharr && hasharr.length === 3) {
        this.setState({ accordionActive: hasharr[2] });
      }
    }
  }

  getNav = () => {
    const { classes, data } = this.props;
    var navPillArr = data.allStrapiFaqs.edges
      .map(node => {
        var document = node.node;
        if (document.category === `Claim`) {
          var obj = {};
          var arr = data.allStrapiImages.edges.filter(item => {
            if (
              item.node.category ===
              document.title.replace(` `, `_`).toLowerCase()
            )
              return item;
          });
          var imageUrl =
            arr.length > 0 &&
            arr[0].node.content &&
            arr[0].node.content.publicURL;
          if (imageUrl) obj.tabImage = imageUrl;
          switch (document.title) {
            
            case `Claims Process`: {
              var subNavPillObj = {},
                subNavPillArr = [];
              var tabledata = [];
              document.questions.forEach(ele => {
                //  ele.answers[0].tables[0].tablerows &&
                //  ele.answers[0].tables[0].tablerows.tablecolumns &&
                var summary =
                  ele.answers[0].summary && ele.answers[0].summary.split(`\n`);
                var summary1 = ele.answers[1] && ele.answers[1].summary;
                var detail =
                  ele.answers[0].detail && ele.answers[0].detail.split(`\n`);
                var temp = {};
                temp.title = ele.title;
                ele.answers[0].tables.length != 0 &&
                  ele.answers[0].tables[0].tablerows.map(item => {
                    var dataArr = item.tablecolumns.map(data =>
                      data.details
                        ? data.details.split(`\n`).map((item, key) => (
                          <span key={key}>
                            {item} <br />
                          </span>
                        ))
                        : ``
                    );
                    tabledata.push(dataArr);
                  });
                // ele.answers[0].tables.length != 0 &&
                temp.content = (
                  <div>
                    {summary &&
                      summary.map((item, idx) => {
                        return ele.answers[0].tables.length != 0 ? (
                          <>
                            <p key={idx}>
                              <Markdown source={item} />
                            </p>
                            <Table
                              striped
                              tableHead={null}
                              tableData={tabledata}
                              customCellClasses={[classes.twoColumnSpacing]}
                              customClassesForCells={[1]}
                            />
                            {summary1 && (
                              <p key={idx}>
                                <Markdown source={summary1} />
                              </p>
                            )}
                          </>
                        ) : (
                          <p key={idx}>
                            <Markdown source={item} />
                          </p>
                        );
                      })}
                    {detail &&
                      detail.map((item, idx) => {
                        return <p key={idx}>{item}</p>;
                      })}
                  </div>
                );
                if (ele.category && ele.category in subNavPillObj) {
                  subNavPillObj[ele.category].push(temp);
                } else if (ele.category) {
                  var tempArr = [];
                  tempArr.push(temp);
                  subNavPillObj[ele.category] = tempArr;
                }
              });

              for (var key in subNavPillObj) {
                let temp = {};
                temp.tabButton = key;
                temp.tabContent = (
                  <GridItem xs={12} md={12}>
                    <div>
                      <h3 className={classes.textCenter}>
                        <strong>
                          {
                            data.allStrapiSections.edges.filter(
                              edge => edge.node.category == key
                            )[0].node.title
                          }
                        </strong>
                      </h3>
                      <h5 className={classes.textLeft}>
                        {
                          data.allStrapiSections.edges.filter(
                            edge => edge.node.category == key
                          )[0].node.summary
                        }
                      </h5>
                    </div>
                    <Accordion
                      activeColor="primary"
                      active={this.state.accordionActive}
                      collapses={subNavPillObj[key]}
                    />
                    <br />
                    {formDownloadData[key] && (
                      <Button
                        href={formDownloadData[key].url}
                        round
                        color="primary"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {formDownloadData[key].buttonText}
                      </Button>
                    )}
                  </GridItem>
                );
                subNavPillArr.push(temp);
              }

              obj.tabButton = document.title;
              obj.tabContent = (
                <GridContainer
                  key={document.title}
                  item
                  md={10}
                  className={classNames(classes.mrAuto, classes.mlAuto)}
                  style={{ paddingTop: `50px` }}
                >
                  <GridItem xs={12} md={12}>
                    <NavPills color="primary" tabs={subNavPillArr} />
                  </GridItem>
                </GridContainer>
              );
              obj.navigateURL= 'https://www.starhealth.in/claims/#claim-process'
              break;
            }
            case `Claims Intimation`: {
              obj.tabButton = document.title;
              obj.tabContent = <ClaimsIntimation />;
              obj.navigateURL= `${CUSTOMERPORTAL_URL}/customerportal/claimIntimationWithoutLogin`
              break;
            }
            case `Claims Helpdesk`: {
              obj.tabButton = document.title;
              obj.tabContent = data.allStrapiContacts.group.map(group => {
                var tableHead = [
                  // group.edges[0].node.name,
                  group.edges[0].node.department,
                  group.edges[0].node.workingHours,
                  group.edges[0].node.primaryPhoneNumber
                ];
                var calltableHead = [
                  group.edges[0].node.department,
                  group.edges[0].node.workingHours,
                  group.edges[0].node.designation,
                  group.edges[0].node.primaryPhoneNumber
                ];
                var tableRows = group.edges.slice(1).map(ele => {
                  var temp = [];
                  if (ele.node.name) 
                    temp.push(`${ele.node.name} - ${ele.node.office}`);
                  else temp.push(ele.node.office);
                  if (ele.node.workingHours) temp.push(ele.node.workingHours);
                  else temp.push(group.edges[1].node.workingHours);
                  if (ele.node.primaryPhoneNumber) temp.push(ele.node.primaryPhoneNumber);
                  else temp.push(group.edges[1].node.primaryPhoneNumber);     
                  return temp;
                });
                var callTableRows = group.edges.slice(1).map(ele => {
                  var temp = [];
                  if (ele.node.name) 
                    temp.push(`${ele.node.name} - ${ele.node.office}`);
                  else temp.push(ele.node.office);
                  if (ele.node.workingHours) temp.push(ele.node.workingHours);
                  else temp.push(group.edges[1].node.workingHours);
                  if (ele.node.designation) temp.push(ele.node.designation);
                  else temp.push(group.edges[1].node.designation);         
                  if (ele.node.primaryPhoneNumber) temp.push(ele.node.primaryPhoneNumber);
                  else temp.push(group.edges[1].node.primaryPhoneNumber); 
                  return temp;
                });
                return (
                  <GridContainer
                    key={document.title}
                    item
                    md={10}
                    className={classNames(classes.mrAuto, classes.mlAuto)}
                    style={{ paddingTop: `70px` }}
                  >
                    <h3>
                      <strong>{group.edges[0].node.name}</strong>
                    </h3>
                    <br />
                    <br />
                    <br />
                    <GridItem lg={12} xs={12}>
                      <Table
                        striped
                        tableHead={group.edges[0].node.department == 'Region' ? calltableHead : tableHead}
                        tableData={ group.edges[0].node.department == 'Region' ? callTableRows : tableRows}
                      />
                    </GridItem>
                  </GridContainer>
                );
              });
              obj.navigateURL= 'https://www.starhealth.in/claims/#claim-helpdesk'

              break;
            }
            case `Claims Status`:
              // var claimStatus = document.questions.map(ele => {
              //   var detail =
              //     ele.answers.length > 0 &&
              //     ele.answers[0].detail &&
              //     ele.answers[0].detail.split(`\n`);
              //   var temp = {};
              //   temp.title = ele.title;
              //   temp.content = (
              //     <div>
              //       {detail &&
              //         detail.map((item, index) => {
              //           return <p key={`statusPara-` + index}>{item}</p>;
              //         })}
              //     </div>
              //   );
              //   return temp;
              // });
              obj.tabButton = document.title;
              obj.tabContent =<ClaimStatusBase />
              // obj.tabContent = (
              //   <GridContainer
              //     item
              //     md={10}
              //     className={classNames(classes.mrAuto, classes.mlAuto)}
              //     style={{ paddingTop: `70px` }}
              //   >
              //     <ClaimStatusContainer classes={classes} />
              //     <GridItem xs={12} md={12} style={{ paddingTop: `70px` }}>
              //       <h3
              //         className={classes.cardTitle}
              //         style={{ textAlign: `center`, marginBottom: `30px` }}
              //       >
              //         Search Partner Network
              //       </h3>
              //       <NavPills
              //         color="primary"
              //         tabs={[
              //           {
              //             tabButton: `Agreed Network Hospitals`,
              //             tabContent: (
              //               <div>
              //                 <CustomMap
              //                   id="anhMap"
              //                   searchMessage="Search Agreed Network Hospitals"
              //                   category="agreed"
              //                   icon_url={`https://maps.gstatic.com/mapfiles/place_api/icons/doctor-71.png`}
              //                   icon_size={25}
              //                   cityUrl={`${apiBaseUrl}hospital/city`}
              //                   url={`${apiBaseUrl}hospital/search`}
              //                   defaultView={`list`}
              //                   helperText={`agreed network hospitals`}
              //                   disableListView={
              //                     process.env.DISABLE_LISTVIEW === `true`
              //                       ? true
              //                       : false
              //                   }
              //                 />
              //               </div>
              //             )
              //           },
              //           {
              //             tabButton: `Network Hospitals`,
              //             tabContent: (
              //               <div>
              //                 <CustomMap
              //                   id="networkMap"
              //                   searchMessage="Search Network Hospitals"
              //                   category="network"
              //                   icon_url={`https://maps.gstatic.com/mapfiles/place_api/icons/doctor-71.png`}
              //                   icon_size={25}
              //                   cityUrl={`${apiBaseUrl}hospital/city`}
              //                   url={`${apiBaseUrl}hospital/search`}
              //                   helperText={`network hospitals`}
              //                   defaultView={`list`}
              //                   disableListView={
              //                     process.env.DISABLE_LISTVIEW === `true`
              //                       ? true
              //                       : false
              //                   }
              //                   showTypeInListView
              //                 />
              //               </div>
              //             )
              //           },
              //           {
              //             tabButton: `FAQs`,
              //             tabContent: <Accordion collapses={claimStatus} />
              //           }
              //         ]}
              //         overflowY={true}
              //       />
              //     </GridItem>
              //   </GridContainer>
              // );
              break;
            case `Claim Upload`:{
                obj.tabButton = document.title;
                if(document.title!= `Claims Intimation`){
                  obj.tabContent =<ClaimUploadBase />
                }               
                break;
              }
              case `Hospital Portal`: {
              obj.tabButton = document.title;
              obj.tabContent = (
                <GridContainer
                  item
                  md={10}
                  className={classNames(classes.mrAuto, classes.mlAuto)}
                  style={{
                    paddingTop: `70px`,
                    textAlign: `center`,
                    justifyContent: `center`
                  }}
                >
                  <Button
                    color="primary"
                    round
                    href="https://spp.starhealth.in"
                    target="_blank"
                  >
                    Take me to Hospital Portal
                  </Button>
                </GridContainer>
              );
              break;
            }
            default:
              break;
          }
        }

        if (obj !== undefined) return obj;
      })
      .filter(node => {
        if (node) return true;
        return false;
      });

    return navPillArr;
  };
  setSubmitButton = () => {
    this.setState({
      submitButton: false
    });
  };
  render() {
    const { classes, location, data } = this.props;
    var arr = data.allStrapiImages.edges.filter(item => {
      if (item.node.category === `claim`) return item;
    });
    let navPillTitle=(<>
     <GridContainer className={classes.containerDisplay}>
          
    <GridItem
        md={11}
        sm={11}
        xs={11}
        className={classNames(classes.mrAuto, classes.mlAuto, classes.infoArea,classes.handleMarginRight)}
    >
        <GridItem  style={{ paddingLeft:'0px'}}>
    <h1 className={classes.claimTitle}>Experience worry-free healthcare with our hassle-free claims services</h1>
    </GridItem>
        <h5>
        At Star Health and Allied Insurance, we understand that health emergencies can be a stressful time for you and your loved ones. That’s why we are dedicated to making the insurance claim process worry-free for you. Our in-house claim settlement team works efficiently to process and settle claims promptly, with a track record of settling over Rs. Four crores every hour. We also offer cashless treatment facilities across our extensive network of over 14,000 hospitals, so you can focus on your health without worrying about financial hardships. 
        </h5>
        <br/>
        <h5>
        To ensure a cashless experience, our cashless facility is available at all Network Hospitals nationwide and to access the list of our Network Hospitals in your area, just <u><b><a href="https://www.starhealth.in/lookup/hospital/" >click here</a></b></u> and search using either the city name or Pin Code.
        </h5>
        <br/>
        <h5>
        We do not cover the expenses for treatment in hospitals or by medical practitioners or providers that are excluded and listed on the company website or notified to the policyholders. It is important to note that only expenses up to the stabilization stage are payable in case of life-threatening situations or after an accident, but the complete claim will not be covered. <u><b><a href="https://www.starhealth.in/lookup/hospital/#excluded-hospital" >Click here</a></b></u> to check the list of excluded service provider. 
        </h5>
        <br/>
        <h5>
        In the event that you are admitted to a Non-network Hospital (which are not listed here), kindly settle the charges directly at the hospital. Afterwards, please submit the complete set of original documents to us. We offer you several options for submitting the claim documents: you can hand them over to our nearest office or advisor, utilize our Customer Portal or Customer App to upload the documents, or alternatively, send the full set via postal mail to our address.        
        </h5>
        <br />
        <h5>
        We would also like to remind you that as per the new mandate, please ensure to share your CKYC number or KYC documents along with your claims documents.
      </h5>
      <br />
      <h5>
      We are here to support you during these challenging times, and thank you for choosing Star Health and Allied Insurance.
      </h5>
      </GridItem>
      </GridContainer>
    </>)
    var imageUrl1 =
      arr.length > 0 &&
      arr[0].node.content &&
      arr[0].node.content.childImageSharp.fluid;
    var imageUrl2 =
      arr.length > 0 &&
      arr[1].node.content &&
      arr[1].node.content.childImageSharp.fluid;
    var navPillArr = this.getNav();
  return (
      <Layout
        image2={imageUrl2}
        image1={imageUrl1}
        title={`We are there when you need us the most.`}
        summary={`Hassle free claims process with 93% of cashless claims.`}
        small={true}
      >
        <Helmet key="helmetTags">
          <title> Star Health Claims Services, Cashless Medical Policy</title>
        </Helmet>
        {process.env.ENABLE_HELMET == `true` && (
          <Helmet key="helmetTags">
            <link rel="canonical" href="https://www.starhealth.in/claims" />
            <meta property="og:title" content="Star Health Claims Services, Cashless Medical Policy" />
            <meta property="og:description" content="Claims Form Download, Claims FAQS" />
            <meta property="og:url" content="https://www.starhealth.in/claims"/>
            <meta name="Star Health Claims Services, Cashless Medical Policy" />
            <meta name="twitter:description" content="Claims Form Download, Claims FAQS" />
            <meta property="twitter:url" content="https://www.starhealth.in/claims" />
            <meta
              name="title"
              content="Star Health Claims Services, Cashless Medical Policy"
            />
            <meta
              name="twitter:title"
              content="Star Health Claims Services, Cashless Medical Policy"
            />
            <meta
              name="description"
              content="Claims Form Download, Claims FAQS"
            />
            <meta
              name="keywords"
              content="Buy Online Health Insurance Plans, Health Insurance, Accident Care, Overseas Travel, Insurance Policy, Insurance Agent Corner, Star Health and Allied Insurance Company Limited, Chennai, India, Portability, Star Health Products, Renew Policy Online, Health Insurance Specialist In India"
            />
          </Helmet>
        )}
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.section} style={{ marginBottom: `100px` }}>
            <div className={`${classes.container} ${classes.paddingfixclaim}`}>
              <Hidden mdUp>
                <h2
                  className={classes.pageTitle}
                  style={{
                    marginTop: `10px`,
                    textAlign: `center`,
                    fontFamily: `Roboto Slab`,
                    padding: `10px`
                  }}
                >{`We are there when you need us the most.`}</h2>
                <h4
                  className={classes.summary}
                  style={{
                    padding: `10px`,
                    textAlign: `center`,
                    marginBottom: `50px`
                  }}
                >{`Hassle free claims process with 93% of cashless claims.`}</h4>
              </Hidden>
              {
                <NavPills
                  color="primary"
                  tabs={navPillArr}
                  alignCenter
                  navPillTitle={navPillTitle}
                  location={location}
                  variant={true}
                />
              }
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}
ClaimsList.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object,
  location: PropTypes.object
};
export default withStyles(claimListStyle)(ClaimsList);

export const claimListQuery = graphql`
  query ClaimsList {
    allStrapiFaqs {
      edges {
        node {
          id
          title
          category
          questions {
            title
            category
            answers {
              detail
              summary
              tables {
                title
                tablerows {
                  tablecolumns {
                    details
                  }
                }
              }
            }
          }
          contacts {
            sequence
            name
            department
            office
            primaryPhoneNumber
          }
        }
      }
    }
    allStrapiContacts(
      filter: { department: { in: ["UNIT1", "UNIT2", "Zonal Name", "Region"] } }
      sort: { fields: sequence, order: ASC }
    ) {
      group(field: department) {
        edges {
          node {
            department
            sequence
            primaryPhoneNumber
            secondaryPhoneNumber
            office
            workingHours
            name
            designation
          }
        }
      }
    }
    allStrapiImages {
      edges {
        node {
          category
          content {
            publicURL
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
    allStrapiSections(filter: { page: { eq: "claims_process" } }) {
      edges {
        node {
          title
          category
          summary
        }
      }
    }
  }
`;
