import carouselStyle from 'assets/jss/material-kit-pro-react/views/componentsSections/carouselStyle';
// import modalStyle from '../../assets/jss/material-kit-pro-react/modalStyle';
import modalStyle from 'assets/jss/material-kit-pro-react/modalStyle';

import {
  primaryColor,
  hexToRgb,
  whiteColor,
} from 'assets/jss/material-kit-pro-react.jsx';
const ProductLandingPage = (theme) => ({
  ...carouselStyle,
  ...modalStyle,

  // ProductHeaderCard.js css start //
  label: {
    color: '#999 !important',
    margin: '0px 10px !important',
    fontSize: '15px !important',
  },
  checkRoot: {
    borderRadius: '3px',
    marginLeft: '40px',
    color: '#fff',
    padding: '0px',
    border: 'solid thin #ccc',
  },
  primaryFillCheckedIcon: {
    border: `1px solid rgba(${hexToRgb(primaryColor[0])}, 0.84)`,
    borderRadius: '3px',
    backgroundColor: primaryColor[0],
    color: 'white',
  },
  whiteChecked: {
    color: whiteColor + ' !important',
  },
  overFlowXHidden: {
    overflowX: `hidden !important`,
  },
  modalArrowBack: {
    color: '#000 !important',
    width: '25px !important',
    height: '25px !important',
    marginTop: '10px',
  },
  modelZindex:{
    "@media (max-width: 576px)": {
      zIndex:'10000'
    },
  },
  modalClose: {
    color: '#000 !important',
    width: '25px !important',
    height: '25px !important',
    marginTop: '10px',
  },
  modalArrowBackButton: {
    padding: '0 !important',
    float: 'left !important',
  },
  modalCloseButton: {
    padding: '0 !important',
    float: 'right !important',
  },
  modal: {
    maxWidth: '1000px',
    margin: '0px !important',
    width: '100% !important',
    borderRadius: '10px',
    overflow: 'hidden',
  },
  modalwidth:{
    maxWidth: '1000px',
    margin: '0px !important',
    width: '100% !important',
    borderRadius: '10px',
    overflow: 'hidden',
    "@media (max-width: 576px)": {
      width: '95% !important',
    },
  },
  modalTitle: {
    textAlign: 'center',
    fontWeight: '500',
    margin: '0px !important',
  },

  modalHeader: {
    padding: '0px  !important',
    textAlign: 'center !important',
    color: '#000 !important',
    marginTop: '50px !important',
    marginBottom: '20px !important',
    fontWeight: '500',
    fontSize: '22px',
    margin: '0px 20px !important',
  },
  modalBody: {
    textAlign: 'left',
    margin: '30px 20px !important',
    paddingBottom: '20px !important',
    // marginBottom: `40px !important`,

    '& p': {
      // margin: '0px !important',
      // fontSize: '15px',
      // marginBottom: '30px !important',
    },
    '&::-webkit-scrollbar': {
      width: 7,
    },

    /* Track */
    '&::-webkit-scrollbar-track': {
      boxShadow: `inset 0 0 2px ${primaryColor[0]}`,
      borderRadius: 7,
    },

    /* Handle */
    '&::-webkit-scrollbar-thumb': {
      background: primaryColor[0],
      borderRadius: 7,
    },

    /* Handle on hover */
    //   '&::-webkit-scrollbar-thumb:hover': {
    //     opacity : 0.5
    //   }
  },
  modalFooter: {
    justifyContent: 'center !important',
    padding: '0px ',
    '& button': {
      padding: '15px 40px',
      width: '150px',
    },
  },
  modalFooterBtm: {
    justifyContent: 'center !important',

    padding: '0px ',
    '& button': {
      padding: '15px 40px',
      width: '150px',
      position: 'fixed !important',
    },
  },
  modalHeaderCard: {
    justifyContent: 'center !important',

    padding: '6px 6px',  //'15px' '40px'
    position: 'fixed !important',
    [theme.breakpoints.down('sm')]: {
      width: `266px !important `,
      fontSize: `20px !important`,
    },
    [theme.breakpoints.up('md')]: {
      width: `500px !important `,
      fontSize: `25px !important`,
    },
    textTransform: `uppercase !important`,
    left: `50% !important`,
    transform: `translateX(-50%) !important`,
    marginTop: `-40px !important`,
    background: `${primaryColor[0]} !important`,
    color: `${whiteColor} !important`,
    fontWeight: 500,
    textAlign: `center !important`,
  },
  smallHeaderCard: {
    [theme.breakpoints.down('sm')]: {
      fontSize: `15px !important`,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: `22px !important`,
    },
  },
  selfServPolicies:{
    [theme.breakpoints.down(`sm`)]:{
      marginTop:`7% !important`
    },
    [theme.breakpoints.down(`xs`)]:{
      marginTop:`22% !important`
    }
  },
  selfServBasic:{
    overflowY:`hidden`
  },
  freeQuote:{
    margin:`0px !important`
  }
  // ProductListCard.js css end //
});

export default ProductLandingPage;
