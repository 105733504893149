import { hotjar } from "react-hotjar";
import { browserDocument, browserWindow,
  //  browserSessionStorage 
  } from "./localStorageHelper";

export const initiateHotjar = function() {
  if (!browserWindow.hjSiteSettings) {
    hotjar.initialize(1824516, 6);
  }
};

export function gTagAdder(id) {
  let gTagScriptElement = browserDocument.createElement(`script`);
  gTagScriptElement.src = `https://www.googletagmanager.com/gtag/js?id=${id}`;
  gTagScriptElement.async = true;
  gTagScriptElement.innerHTML = `window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());
  gtag('config', ${id});`;
  browserDocument.head.appendChild(gTagScriptElement);
}

export const addAnalyticsScript = function() {
  gTagAdder(`AW-987779232`);
};

export const addRemarkettingScript = function() {
  gTagAdder(`UA-18443892-1`);
};

export const script3 = function() {
  let scriptEle = browserDocument.createElement("script");
  scriptEle.innerHTML = `(function(w,d,s,l,i) {w[l]=w[l]||[];w[l].push( {'gtm.start': new Date().getTime(),event:'gtm.js'}
  );var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','GTM-PNCV6QJ');`;
  browserDocument.head.appendChild(scriptEle);
};

export const script4 = function() {
  let scriptElem = browserDocument.createElement("script");
  scriptElem.src = `https://www.googleoptimize.com/optimize.js?id=OPT-WG3RH5D`;
  browserDocument.head.appendChild(scriptElem);
};

export const addNoScript = function() {
  let noScriptEle = browserDocument.createElement("noscript");
  noScriptEle.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-PNCV6QJ"
      height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>`;
  browserDocument.body.prepend(noScriptEle);
};

export const addCorporateContacts = function() {
  let corporateContactScript = browserDocument.createElement("script");
  corporateContactScript.type = "application/ld+json";
  corporateContactScript.innerHTML = `{ "@context" : "http://schema.org",
  "@type" : "Organization",
  "name": "Star Health and Allied Insurance",
  "url" : "https://www.starhealth.in",
  "sameAs" : [ "https://www.facebook.com/StarHealth",
  "https://twitter.com/starhealthins",
  "https://www.instagram.com/starhealth.insurance/",
  "https://www.linkedin.com/company/star-health-and-allied-insurance-co--ltd",
  "https://www.youtube.com/user/StarhealthInsurance"],
  "contactPoint" : [ { "@type" : "ContactPoint","telephone" : "+1800-425-2255","contactType" : "Customer Service - 24x7 Toll Free"}] }`;
  browserDocument.head.appendChild(corporateContactScript);
};

export const sevaApp = function sevaApp() {
  const queryParams = new URLSearchParams(browserWindow.location.search)
  var seva = browserWindow ? browserWindow.sessionStorage && browserWindow.sessionStorage.getItem("seva") : false
  if(queryParams.has('sevaapp') && queryParams.get('sevaapp') == 'true' || (seva)){
  return true
  } else {
  return false
  }
  };