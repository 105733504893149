import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import PdAccordion from "./PdAccordion";
import * as treeclasses from "./PdAccordionTreeStyles.module.css";
import {
    browserDocument,
  } from "util/localStorageHelper";

const handleChat = () => {
    let twinkleEvent1 = browserDocument.getElementById("twinkle-thumbnail");
    twinkleEvent1 && twinkleEvent1.click();
  };

const PdAccordionTree = ({ treedata, styletype, whiteText, mobileView}) => {
    return (
      <div className={treeclasses[styletype]} style={{ marginLeft: `22px` }}>
        {treedata &&
          treedata.map((item) => {
            return item["title"] == 'Chat With Us' ? (
                <a
                className={treeclasses.pdLink}
                href={item.link ? item.link : `javascript:void(0);`}
                alt="public disclosure link"
                onClick={()=>{handleChat()}}
                style={whiteText== false ? {color: 'rgba(0,0,0,.85)',
                padding: '5px 0px 20px 0px',
                borderBottom: '1px solid rgb(221, 221, 221)'} 
                : item["img"] && 
                {color: '#3C4858',
                padding: '10px 0px 10px 0px',
                fontFamily: 'Manrope',
                fontSize: '14px',
                fontWeight: 500,
                textDecoration: 'none'}}
              >
                {item["img"] ? <img src={item["img"]} style={{ width: "32px",marginRight: '10px'}} ></img> : ''}{item["title"]}
            </a>
            ) : (
              <div style={{padding: '0', margin: '0'}} onclick={()=>{console.log("on click ....")}}>
                <a
                  className={treeclasses.pdLink}
                  href={item.link ? item.link : `javascript:void(0);`}
                  target="_blank"
                  alt="public disclosure link"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  style={whiteText== false ? {color: 'rgba(0,0,0,.85)',
                  padding: '5px 0px 20px 0px',
                  borderBottom: '1px solid rgb(221, 221, 221)'} 
                  : item["img"] && 
                  {color: '#3C4858',
                  padding: '10px 0px 10px 0px',
                  fontFamily: 'Manrope',
                  fontSize: '14px',
                  fontWeight: 500,
                  textDecoration: 'none'}}
                >
                  {item["img"] ? <img src={item["img"]} style={{ width: "32px",marginRight: '10px'}} ></img> : ''}{item["title"]}
              </a>
              </div>
              
            );
          })}
      </div>
    );
}
PdAccordionTree.propTypes = {
    classes: PropTypes.object,
  };
export default withStyles()(PdAccordionTree);