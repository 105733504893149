import {
  container,
  mlAuto,
  mrAuto,
  title,
  description,
  main,
  mainRaised,
} from "assets/jss/material-kit-pro-react.jsx";
import sectionCards from "assets/jss/material-kit-pro-react/views/componentsSections/sectionCards.jsx";
const features = {
    container,
    mlAuto,
    mrAuto,
    title,
    main,
    mainRaised,
    ...sectionCards,
    description,
    listposition:{
        textAlign:`center`,
        marginTop:`20px`,
        border: `1px solid #D7DEE8`,
        borderRadius: `12px`,
        paddingBottom: `20px`,
    },
    imageposition: {
        marginRight: `50% !important`,
        marginBottom: `-16% !important`,
    },
    intimationnumber:{
        marginLeft: `19.5% !important`,
        marginTop: `-6% !important`,
    },
    imageposition2:{
        marginRight: `50% !important`,
        marginBottom: `-5% !important`,
    },
    dateposition:{
        marginLeft: `17% !important`,
    }
};

export default features;
