import { gql } from '@apollo/client';
import commonAPiGraphql, {
  handlerGraphQlResponse,
} from './commonApiGraphql';

const queryString = gql`
  query Logout {
    logout {
      responseCode
      responseMessage
      response {
        status
      }
    }
  }
`;

export async function logoutAPI() {
  const query = queryString;
  const response = await commonAPiGraphql.query(query, {});
  return handlerGraphQlResponse(response && response.data && response.data.logout);
}
