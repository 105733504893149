import {
  container,
  main,
  mainRaised,
  title,
  whiteColor,
  primaryColor,
  grayColor,
  section,
  mrAuto,
  mlAuto,
  cardTitle,
  mTAuto,
  mBAuto
} from "assets/jss/material-kit-pro-react.jsx";

import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.jsx";
import customCheckboxRadioSwitch from "assets/jss/material-kit-pro-react/customCheckboxRadioSwitchStyle.jsx";

import loginPageStyle from "assets/jss/material-kit-pro-react/views/loginPageStyle.jsx";
const genericCardStyle = theme => ({
  main,
  mainRaised,
  mrAuto,
  mlAuto,
  title,
  cardTitle,
  mTAuto,
  mBAuto,
  ...customCheckboxRadioSwitch,
  ...loginPageStyle,
  ...modalStyle(theme),
  textCenter: {
    textAlign: `center !important`
  },
  typeIcon: { display: `flex`, justifyContent: `center` },
  container: {
    ...container,
    zIndex: `2`
  },
  paddingfixclaim: {
    [theme.breakpoints.down(`sm`)]: {
      padding: `0px !important`
    }
  },
  buttonclaimfix: {
    [theme.breakpoints.down(`sm`)]: {
      width: `100% !important`
    }
  },
  dateTimeError: {
    color: `red`,
    "&::placeholder": {
      color: `red`
    }
  },
  radiobuttonclaimfix: {
    [theme.breakpoints.down(`xs`)]: {
      fontSize: `12px`
    }
  },
  containerDisplay:{
    "@media (max-width:550px)": {
      display:'flow-root'
    }
  },
  infoArea: {
    marginBottom: `-5%`,
    maxWidth: '76%',
    flexBasis: '76%',
    marginTop:'1%'
  },
  handleMarginRight:{
    "@media (max-width:750px)": {
        paddingRight: '0px !important',
      }
  },
  claimTitle:{
    color: `#3C4858`,
    fontSize:'32px',
textDecoration: `none`,
fontWeight: `700`,
marginTop: `30px`,
marginBottom: `25px`,
minHeight: `32px`,
textAlign:'center',
fontFamily: `"Roboto Slab", "Times New Roman", serif`,
[theme.breakpoints.down('md')]: {
color: `#3C4858 !important`,
textDecoration: `none !important`,
fontWeight: `700 !important`,
marginTop: `30px !important`,
marginBottom: `25px !important`,
minHeight: `32px !important`,
fontFamily: `"Roboto Slab", "Times New Roman", serif !important`,
fontSize:'28px !important'
}
  },

  buttonfixintimation: {
    float: `right`,
    [theme.breakpoints.down(`sm`)]: {
      width: `100% !important`
    }
  },

  customCarousel: {
    height: `100%`
  },
  brand: {
    "& h2, & h4": {
      color: whiteColor
    }
  },
  link: {
    textDecoration: `none`
  },
  description: {
    color: grayColor[0],
    fontWeight: `300`,
    "@media (max-width:1030px)": {
      marginTop: '15px !important'
    },
    "@media (max-width:770px)": {
      marginTop: '15px !important'
    },
    "@media (max-width:550px)": {
      marginBottom: '10px !important'
    },
  },
  section: {
    ...section,
    padding: `70px 0px`,
    "& h4$description": {
      fontSize: `1.5em`
    }
  },
  list: {
    marginBottom: `0`,
    padding: `0`,
    marginTop: `0`
  },
  inlineBlock: {
    display: `inline-block`,
    padding: `0px`,
    width: `auto`
  },
  left: {
    float: `left!important`,
    display: `block`
  },
  right: {
    padding: `15px 0`,
    margin: `0`,
    float: `right`
  },
  aClass: {
    textDecoration: `none`,
    backgroundColor: `transparent`
  },
  block: {
    color: `inherit`,
    padding: `0.9375rem`,
    fontWeight: `500`,
    fontSize: `12px`,
    textTransform: `uppercase`,
    borderRadius: `3px`,
    textDecoration: `none`,
    position: `relative`,
    display: `block`
  },
  mapCard: {
    top: `120px`,
    width: `40%`,
    paddingLeft: `15px`,
    position: `absolute`,
    height: `500px`,
    overflowY: `auto`,
    overflowX: `hidden`
  },
  responsiveMapCard: {
    top: `150px`,
    width: `85%`,
    left: `0`,
    right: `0`,
    margin: `0 auto`,
    position: `absolute`,
    height: `500px`,
    overflowX: `hidden`
  },
  mapForm: {
    position: `absolute`,
    top: `-3%`,
    width: `90%`,
    margin: `0 auto`,
    left: `0`,
    right: `0`
  },
  listForm: {
    position: `absolute`,
    top: `-10%`,
    width: `90%`,
    margin: `0 auto`,
    left: `0`,
    right: `0`
  },
  customFormControl: {
    paddingTop: `15px !important`
  },
  noLeftBorder: {
    "&, &:hover": {
      borderWidth: `1px 1px 1px 0`,
      borderStyle: `solid`,
      borderColor: primaryColor[0],
      color: primaryColor[0],
      borderRadius: `0px 5px 5px 0px`
    }
  },
  noRightBorder: {
    "&, &:hover": {
      borderWidth: `1px 0 1px 1px`,
      borderStyle: `solid`,
      borderColor: primaryColor[0],
      color: primaryColor[0],
      borderRadius: `5px 0px 0px 5px`
    }
  },
  primaryBorder: {
    borderWidth: `1px`,
    borderStyle: `solid`,
    borderColor: primaryColor[0],
  },
  statusTitle:{
    marginRight:`0px`,
    paddingRight:`0px`,
    "& h3":{
      textAlign:`right`
    }
  },
  toggleButtons:{
    textAlign:`left`
  },
  textposition:{
    textAlign: 'left !important',
    marginBottom: '16px !important'
  },
  dateTimePosition: {
    '& .rdt.rdtOpen .rdtPicker ': {
      position: 'relative !important',
      top: '0 !important',
    },
  },

  mapHeight:{
    '@media (max-width: 600px)':{
         height:'380px',
    }
  }
});

export default genericCardStyle;
