import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import layoutStyle from "ps-assets/jss/layoutStyle.jsx";
import { StaticQuery, graphql } from "gatsby";
import PSFooterSection from "./PSFooterSection";
import PropTypes from "prop-types";
// import "../Footer/Footerstyle.css"
import 'ps-assets/css/Footerstyle.css'

// const sectionTitle = {
//   paddingLeft: `15px`,
//   marginBottom: `0px`,
//   fontFamily: `Roboto`,
//   fontSize: `15px !important `,
//   fontWeight: `500 `,
//   fontStyle: `normal`,
//   fontStretch: `normal`,
//   lineHeight: `normal`,
//   letterSpacing: `normal`,
//   textAlign: `left`,
//   color: `rgba(255, 255, 255, 0.8)`
// };


const PSFooter = ({ data }) => {
//   let allImages = data.allStrapiImages.edges;

  const sections = data.allStrapiSections.edges.sort((a, b) => {
    return a.node.sequence - b.node.sequence;
  });

//   const footerSections = sections
//     .map(section => {
//       if (section.node.ui_alignment === `vertical`) {
//         if (section && section.node && section.node.title)
//           return {
//             title: (
//               <div
//                 style={{
//                   fontFamily: `Roboto`,
//                   fontWeight: 500, fontSize: 15, minHeight: `17px`, color: `rgba(255, 255, 255, 0.8)`,
//                   paddingBottom: `10px`
//                 }}
//               >
//                 {section.node.title === `Service` ? <br /> : section.node.title}
//               </div>
//             ),
//             content: <PSFooterSection key={section.node.id} section={section} />
//           };
//       }
//     })
//     .filter(item => item);
  let footerAccordian = [];
  let sectionClone = JSON.parse(JSON.stringify(sections));
  for (let index = 0; index < sectionClone.length; index++) {
    const section = sectionClone[index];
    if (section.node.ui_alignment === `vertical`) {
      if (section && section.node && section.node.title === `Services` && section.node.title !== `Service`) {

        // section.node.links = section.node.links.concat(sectionClone[index + 1].node.links);
        footerAccordian.push({
          title: (
            <span
              style={{
                fontFamily: `Roboto`,
                fontWeight: 500, fontSize: 15, minHeight: `17px`, color: `rgba(255, 255, 255, 0.8)`
              }}
            >
              {section.node.title}
            </span>
          ),
          content: <PSFooterSection key={section.node.id} section={section} />
        });
      }
      else if (section && section.node && section.node.title !== `Service`) {
        footerAccordian.push({
          title: (
            <span
              style={{
                fontFamily: `Roboto`,
                fontWeight: 500, fontSize: 15, minHeight: `17px`, color: `rgba(255, 255, 255, 0.8)`
              }}
            >
              {section.node.title}
            </span>
          ),
          content: <PSFooterSection key={section.node.id} section={section} />
        });
      }

    }
  }

//   var sectionArray = sections
//     .map(section => {
//       if (
//         section.node.ui_alignment === `horizontal` &&
//         section.node &&
//         section.node.title
//       )
//         return {
//           title: (
//             <span
//               style={{
//                 display: `block`,
//                 minHeight: `17px`,
//                 color: `rgba(255, 255, 255, 0.8)`
//               }}
//             >
//               {section.node.title}
//             </span>
//           ),

//           content: <PSFooterSection section={section} />
//         };
//     })
//     .filter(item => item);

//   const accordianArray = footerAccordian.concat(sectionArray);

  return (
    <div className="footer_footer-component__4xPe6 relative common-section">
      <div className="footerSew-fullctions_footer-sections-root__IP_Bh w-full relative">
        <div className="footerSections_desc__I4Qfy mb-24">
          <div className="footerSections_listing__jgmBS mb-30 mb-10-sm">
            <h7
              className="footerSections_heading__6mIWJ text-14 font-bold text-uppercase text-ternary mb-0 flex justify-between align-left pb-10">
              <b>HEALTH INSURANCE</b><span className="arrow only-mobile"></span>
            </h7>
            <div className="footerSections_info__dzPOP text-14 font-500 text-capitalize text-secondary mb-0">
              <p>
                <a href="https://www.starhealth.in/health-insurance/individuals/">Individual Health Insurance</a><span
                  style={{backgroundColor: "rgb(242, 244, 247)",
                  color: "rgb(67, 69, 86)"}}>
                  • </span><a href="https://www.starhealth.in/health-insurance/family/">Health Insurance
                  For Family</a><span style={{backgroundColor: "rgb(242, 244, 247)",
                  color: "rgb(67, 69, 86)"}}>
                  • </span><a href="https://www.starhealth.in/health-insurance/red-carpet/">Medical Insurance For
                  Parents</a><span style={{backgroundColor: "rgb(242, 244, 247)",
                  color: "rgb(67, 69, 86)"}}>
                  • </span><a href="https://www.starhealth.in/health-insurance/diabetes-safe/" target="_blank"
                  rel="noopener noreferrer">Diabetes Safe Insurance Policy</a><span style={{backgroundColor: "rgb(242, 244, 247)",
                  color: "hsl(0, 0%, 0%)"}}>
                </span><span style={{backgroundColor: "rgb(242, 244, 247)",
                  color: "rgb(67, 69, 86)"}}>• </span><a href="https://www.starhealth.in/health-insurance/super-surplus/">Top Up Health
                  Insurance</a><span style={{backgroundColor: "rgb(242, 244, 247)",
                  color: "rgb(67, 69, 86)"}}>
                  • </span><a href="https://www.starhealth.in/health-insurance/outpatient-care/" target="_blank"
                  rel="noopener noreferrer">Star Out Patient Care Insurance Policy</a><span style={{backgroundColor: "rgb(242, 244, 247)",
                  color: "rgb(67, 69, 86)"}}>
                  • </span><a href="https://www.starhealth.in/health-insurance/hospital-cash/" target="_blank"
                  rel="noopener noreferrer">Star Hospital Cash Insurance Policy</a>
                <a href="https://www.starhealth.in/group-health-insurance/">
                  • Group Health Insurance</a><span style={{backgroundColor: "rgb(242, 244, 247)",
                  color: "rgb(67, 69, 86)"}}>
                </span>
              </p>
            </div>
          </div>
          <div className="footerSections_listing__jgmBS mb-30 mb-10-sm">
            <h7
              className="footerSections_heading__6mIWJ text-14 font-bold text-uppercase text-ternary mb-0 flex justify-between align-left pb-10">
              <b>ACCIDENT INSURANCE</b><span className="arrow only-mobile"></span>
            </h7>
            <div className="footerSections_info__dzPOP text-14 font-500 text-capitalize text-secondary mb-0">
              <p>
                <a href="https://www.starhealth.in/accident-insurance/individual" target="_blank"
                  rel="noopener noreferrer">Personal Accident Cover Policy</a>
                •
                <a href="https://www.starhealth.in/accident-insurance/family" target="_blank"
                  rel="noopener noreferrer">Family Accident Cover Plan</a>
              </p>
            </div>
          </div>
          <div className="footerSections_listing__jgmBS mb-30 mb-10-sm">
            <h7
              className="footerSections_heading__6mIWJ text-14 font-bold text-uppercase text-ternary mb-0 flex justify-between align-left pb-10">
              <b>TRAVEL INSURANCE</b><span className="arrow only-mobile"></span>
            </h7>
            <div className="footerSections_info__dzPOP text-14 font-500 text-capitalize text-secondary mb-0">
              <p>
                <a href="https://www.starhealth.in/travel-insurance/international-individual" target="_blank"
                  rel="noopener noreferrer">Overseas Individual Travel Insurance</a>
                •
                <a href="https://www.starhealth.in/travel-insurance/international-student" target="_blank"
                  rel="noopener noreferrer">Student Travel Insurance</a>
                •
                <a href="https://www.starhealth.in/travel-insurance/international-corporate" target="_blank"
                  rel="noopener noreferrer">Corporate Travel Insurance</a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="footer_footer-container__QTue_ flex">
        <div
          className="mr-30 mr-0-xs mr-0-sm text-center-xs flex-sm flex-wrap-xs justify-between-sm align-center-sm justify-center-xs">
          <span className="footer_star-logo__3eVlr mt-10 mb-10">
            <img src="./img/logo-footer.svg" alt="" />
          </span>
          <div className="flex flex-wrap mt-30 justify-around-xs">
            <a className="mr-4 mr-10-xs" href="https://www.facebook.com/StarHealth/">
              <img src="./img/footer_facebook.svg" alt="" />
            </a>
            <a className="mr-4 mr-10-xs" href="https://www.youtube.com/user/StarhealthInsurance">
              <img src="./img/footer_youtube.svg" alt="" /> </a><a className="mr-4 mr-10-xs"
              href="https://www.linkedin.com/company/star-health-and-allied-insurance-co--ltd/?originalSubdomain=in">
              <img src="./img/footer_linkedin.svg" alt="" /></a>

            <a className="mr-4 mr-10-xs" href="https://twitter.com/starhealthins?lang=en">
              <img src="./img/footer_twitter.svg" alt="" />
            </a>
            <a className="mr-4 mr-10-xs" href="https://www.instagram.com/starhealth.insurance/?hl=en">
              <img src="./img/footer_instagram.svg" alt="" />
            </a>
          </div>
        </div>
        <div className="footer_footer-links__UxAK9 flex justify-between flex-col">
          <div className="footer_footer-column__Yw6ZB flex justify-between flex-col-xs">
            <div className="mb-24-xs pb-20-xs border-bottom-gray-xs">
              <div className="text-14 flex flex-col">
                <div className="flex justify-between">
                  <strong className="mb-20 mb-10-xs text-uppercase font-700">About Us</strong>
                </div>
                <div className="flex flex-col">
                  <a className="text-14 mb-8 text-capitalize"
                    href="https://www.starhealth.in/investors/about-us/#board-of-directors">Board Of Directors</a><a
                    className="text-14 mb-8 text-capitalize"
                    href="https://www.starhealth.in/investors/about-us/#key-management-personnel">Key Management Persons</a><a
                    className="text-14 mb-8 text-capitalize" href="https://www.starhealth.in/careers/">Careers</a><a
                    className="text-14 mb-8 text-capitalize" href="https://www.starhealth.in/contact/">Contact Us</a><a
                    className="text-14 mb-8 text-capitalize" href="https://www.starhealth.in/media-center/">Media
                    Center</a><a className="text-14 mb-8 text-capitalize"
                    href="https://www.starhealth.in/about-us/#citizen-charter">Citizens Charter</a>
                </div>
              </div>
            </div>
            <div className="mb-24-xs pb-20-xs border-bottom-gray-xs">
              <div className="text-14 flex flex-col">
                <div className="flex justify-between">
                  <strong className="mb-20 mb-10-xs text-uppercase font-700">Resources</strong>
                </div>
                <div className="flex flex-col">
                  <a className="text-14 mb-8 text-capitalize" href="https://www.starhealth.in/blog">Blog</a><a
                    className="text-14 mb-8 text-capitalize" href="https://ems.starhealth.in/AEMS/#b">Agents</a><a
                    className="text-14 mb-8 text-capitalize" href="https://spp.starhealth.in/#/">Hospital Portal</a><a
                    className="text-14 mb-8 text-capitalize"
                    href="https://mail.starhealth.in/owa/auth/logon.aspx?replaceCurrent=1&amp;url=https%3a%2f%2fmail.starhealth.in%2fowa%2f">Employee
                    Login</a><a className="text-14 mb-8 text-capitalize"
                    href="https://www.starhealth.in/star-glossary/">Glossary</a><a className="text-14 mb-8 text-capitalize"
                    href="https://www.starhealth.in/downloads">Downloads</a>
                </div>
              </div>
            </div>
            <div className="mb-24-xs pb-20-xs border-bottom-gray-xs">
              <div className="text-14 flex flex-col">
                <div className="flex justify-between">
                  <strong className="mb-20 mb-10-xs text-uppercase font-700">Insurance</strong>
                </div>
                <div className="flex flex-col">
                  <a className="text-14 mb-8 text-capitalize"
                    href="https://www.starhealth.in/group-health-insurance/">Health Insurance For Corporate</a><a
                    className="text-14 mb-8 text-capitalize" href="https://www.starhealth.in/health-insurance/">Health
                    Insurance</a><a className="text-14 mb-8 text-capitalize"
                    href="https://www.starhealth.in/travel-insurance-plans/">Travel Insurance</a><a
                    className="text-14 mb-8 text-capitalize" href="https://www.starhealth.in/accident-plans/">Accident
                    Insurance</a><a className="text-14 mb-8 text-capitalize" href="https://www.starhealth.in/faqs/">FAQ</a>
                </div>
              </div>
            </div>
            <div className="mb-24-xs pb-20-xs border-bottom-gray-xs">
              <div className="text-14 flex flex-col">
                <div className="flex justify-between">
                  <strong className="mb-20 mb-10-xs text-uppercase font-700">Services</strong>
                </div>
                <div className="flex flex-col">
                  <a className="text-14 mb-8 text-capitalize"
                    href="https://portal.starhealth.in/CorpClient/wfrm_Login.aspx">Corporate Health Claim</a><a
                    className="text-14 mb-8 text-capitalize" href="https://www.starhealth.in/dnd-registration/">DND
                    Registration</a><a className="text-14 mb-8 text-capitalize"
                    href="https://www.starhealth.in/portability/">Portability</a><a className="text-14 mb-8 text-capitalize"
                    href="https://www.starhealth.in/service-parameters/">Service Parameter</a><a
                    className="text-14 mb-8 text-capitalize" href="https://www.starhealth.in/80d-tax-benefits/">80D Tax
                    Benefits</a><a className="text-14 mb-8 text-capitalize"
                    href="https://web.starhealth.in/claims#0">Claims</a><a className="text-14 mb-8 text-capitalize"
                    href="https://customer.starhealth.in/customerportal/instant-renewal">Renewals</a><a
                    className="text-14 mb-8 text-capitalize"
                    href="https://www.starhealth.in/grievance-redressal/">Grievance</a><a
                    className="text-14 mb-8 text-capitalize"
                    href="https://www.starhealth.in/sites/default/files/CSR.pdf">CSR</a>
                </div>
              </div>
            </div>
            <div className="mb-24-xs pb-20-xs border-bottom-gray-xs">
              <div className="text-14 flex flex-col">
                <div className="flex justify-between">
                  <strong className="mb-20 mb-10-xs text-uppercase font-700">Regulatory</strong>
                </div>
                <div className="flex flex-col">
                  <a className="text-14 mb-8 text-capitalize" href="https://www.starhealth.in/list-products/">List Of
                    Products</a><a className="text-14 mb-8 text-capitalize"
                    href="https://www.starhealth.in/installment-products/">Products With Instalment Facility</a><a
                    className="text-14 mb-8 text-capitalize" href="https://www.gicouncil.in/">GI Council</a><a
                    className="text-14 mb-8 text-capitalize"
                    href="https://nonlife.iib.gov.in/IIB/homePageAction.do?method=loadHomePage1">Insurance Information
                    Bureau</a><a className="text-14 mb-8 text-capitalize"
                    href="https://www.irdai.gov.in/">IRDAI</a><a
                    className="text-14 mb-8 text-capitalize"
                    href="https://www.starhealth.in/media-center/#public-disclosures">Public Disclosures</a><a
                    className="text-14 mb-8 text-capitalize" href="https://www.starhealth.in/claim-proposal/">Unclaimed
                    Amount</a><a className="text-14 mb-8 text-capitalize"
                    href="https://www.starhealth.in/irdai-regulations-appointment/">IRDAI Regulations Appointment</a><a
                    className="text-14 mb-8 text-capitalize" href="https://www.starhealth.in/withdrawn-products/">Withdrawn
                    Products</a><a className="text-14 mb-8 text-capitalize" href="https://www.policyholder.gov.in/">IRDAI
                    Consumer Education Website</a><a className="text-14 mb-8 text-capitalize"
                    href="https://www.starhealth.in/underwriting-philosophy/">Underwriting Philosophy</a>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-between mt-48 only-desktop">
            <div className="flex">
              <p className="text-12 mr-24 font-600">
                © Star Health Insurance. All rights reserved.
              </p>
            </div>
          </div>
          <div className="mt-20 only-desktop flex flex-wrap">
            <div className="mr-20">
              <img src="./img/visa.svg" alt="" />
            </div>
            <div className="mr-20">
              <img src="./img/mastercard.svg" alt="" />
            </div>
            <div className="mr-20">
              <img src="./img/maestro.svg" alt="" />
            </div>
            <div className="mr-20">
              <img src="./img/american_express.svg" alt="" />
            </div>
            <div className="mr-20">
              <img src="./img/paytm.svg" alt="" />
            </div>
            <div className="mr-20">
              <img src="./img/rupay.svg" alt="" />
            </div>
            <div className="mr-20">
              <img src="./img/upi.svg" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-between mt-48 only-mobile">
        <p className="text-12 mr-24">
          © Star Health Insurance. All rights reserved.
        </p>
        <div className="flex align-center justify-center"></div>
        <div className="mt-20 only-mobile">
          <div className="flex card-img-size">
            <div className="mr-10-xs">
              <img src="./img/visa.svg" alt="" />
            </div>
            <div className="mr-10-xs">
              <img src="./img/mastercard.svg" alt="" />
            </div>
            <div className="mr-10-xs">
              <img src="./img/maestro.svg" alt="" />
            </div>
            <div className="mr-10-xs">
              <img src="./img/american_express.svg" alt="" />
            </div>
            <div className="mr-10-xs">
              <img src="./img/paytm.svg" alt="" />
            </div>
            <div className="mr-10-xs">
              <img src="./img/rupay.svg" alt="" />
            </div>
            <div className="mr-10-xs">
              <img src="./img/upi.svg" alt="" />
            </div>
          </div>
        </div>
      </div>

      <div className="footerSections_footer-sections-root__IP_Bh w-full relative">
        <div className="footerSections_bottom-content-container__0zRge pt-24 mt-24 text-12">
          <div className="bottom-content-element mb-16">
            <div className="mb-2 font-700">Useful Links</div>
            <div className="links flex w-full mb-2">
              <div>
                <a href="https://www.starhealth.in/wellness" className="text-12 mr-8 text-black">Wellness</a>
              </div>
              <div>
                <a href="https://portal.starhealth.in/lab/" className="text-12 mr-8 text-black">Lab Login</a>
              </div>
              <div>
                <a href="https://www.starhealth.in/sitemap" className="text-12 mr-8 text-black">Sitemap</a>
              </div>
              <div>
                <a href="https://www.starhealth.in/free-look-period" className="text-12 mr-8 text-black">Free Look
                  Period</a>
              </div>
            </div>
          </div>
          <div className="bottom-content-element mb-16">
            <div className="mb-2 font-700">Legal</div>
            <div className="links flex w-full mb-2">
              <div>
                <a href="https://www.starhealth.in/disclaimer" className="text-12 mr-8 text-black">Disclaimer</a>
              </div>
              <div>
                <a href="https://www.starhealth.in/privacy-policy" className="text-12 mr-8 text-black">Privacy</a>
              </div>
              <div>
                <a href="https://www.starhealth.in/terms-of-usage" className="text-12 mr-8 text-black">Terms Of Usage</a>
              </div>
              <div>
                <a href="https://www.starhealth.in/disclaimer/safebuying" className="text-12 mr-8 text-black">Safe
                  Buying</a>
              </div>
            </div>
          </div>
          <div className="bottom-content-element mb-16">
            <div className="mb-2 font-700">
              Star Health and Allied Insurance Co Ltd
            </div>
            <div className="bottom-content-description">
              <p>&nbsp;</p>
              <p>
                Registered Office: No 1, New Tank Street, Valluvarkottam High
                Road, Nungambakkam, Chennai 600034<br />IRDAI Registration No:
                129 | CIN : L66010TN2005PLC056649 | Ph: 044-28288800 | Fax:
                044-28260062 | Email:
                <span style={{color: "hsl(240, 75%, 60%)"}}>info@starhealth.in</span>
                | Website:
                <a href="https://web.starhealth.in/" target="_blank" rel="noopener noreferrer"><span
                    style={{color: "hsl(240, 75%, 60%)"}}>www.starhealth.in</span></a><br />Toll Free Number -1800-425-2255
                / 1800-102-4477 |
                Corporate Customers - 044 43664666
              </p>
            </div>
          </div>
          <div className="bottom-content-element mb-16">
            <div className="mb-2 font-700">Address of IRDAI:</div>
            <div className="bottom-content-description">
              <p>&nbsp;</p>
              <p>
                Insurance Regulatory And Development Authority Of India<br />Sy
                No. 115/1, Financial District, Nanakramguda, Gachibowli,
                Hyderabad – 500032 Website:
                <a href="https://www.irdai.gov.in/" target="_blank" rel="noopener noreferrer"><span
                    style={{color: "hsl(240, 75%, 60%)"}}>https://www.irdai.gov.in</span></a>
              </p>
            </div>
          </div>
          <div className="bottom-content-element mb-16">
            <div className="mb-2 font-700">IRDAI clarifies to public that:</div>
            <div className="bottom-content-description">
              <p>&nbsp;</p>
              <p>
                IRDAI or its officials do not involve in activities like sale
                of any kind of insurance or financial products nor invest
                premiums.<br />Public receiving such phone calls are requested
                to lodge a police complaint along with details of phone call,
                number.<br />IRDAI does not announce any bonus.
              </p>
              <p>&nbsp;</p>
              <p>
                Star Health and Allied Insurance Co Ltd, IRDA licensed
                stand-alone health insurer, hereby makes it clear to customers
                and would be customers and those visiting this website, run
                officially by this company for its benefit and the interest of
                its stakeholders, that there could be websites or entities
                running duplicate sites and offering for sale products
                belonging to this company. The customers are warned to make
                enquiries and be satisfied before they close any transaction.
                This company does not hold itself responsible for any
                transactions the customers may enter into with entities
                misrepresenting themselves on behalf of Star Health and Allied
                Insurance Co Ltd. This insurer is ready and willing to clarify
                any doubts that may arise to customers to be satisfied with
                the authenticity of the website and entity engaged with by
                them so that they can enter into transactions only of the
                genuine kind to better protect their interests. This Important
                Disclaimer may kindly be read and noted for the benefit of all
                stakeholders, particularly, customers of the insurance
                company.
              </p>
              <p>&nbsp;</p>
              <p>
                Insurance is the subject matter of solicitation. For more
                details on risk factors, terms and conditions, please read the
                sales brochure carefully before concluding a sale.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    );
};
PSFooter.propTypes = {
  classNameNamees: PropTypes.object,
  data: PropTypes.object
};
export default withStyles(layoutStyle)(props => (
  <StaticQuery
    query={graphql`
      query {
        allStrapiLinks(filter: { category: { eq: "footers" } }) {
          edges {
            node {
              id
              title
              url
              type
              icon {
                publicURL
              }
            }
          }
        }
        allStrapiSections(filter: { category: { eq: "footer_section" } }) {
          edges {
            node {
              id
              title
              category
              sequence
              ui_alignment
              links {
                id
                url
                title
                sequence
                sub_sequence
                sub_category
              }
            }
          }
        }
        allStrapiImages(filter: { category: { eq: "footer" } }) {
          edges {
            node {
              id
              url
              sequence
              content {
                publicURL
              }
            }
          }
        }
      }
    `}
    render={data => <PSFooter data={data} {...props} />}
  />
));