import {
  main,
  mainRaised,
  container,
  mrAuto,
  mlAuto,
  section
} from "assets/jss/material-kit-pro-react.jsx";
const GracePeriodPolicyPageStyle = function() {
  return {
    main,
    mainRaised,
    mrAuto,
    mlAuto,
    container: {
      ...container,
      zIndex: 1
    },
    section: {
      ...section,
      padding: `70px 0px`
    },
    cursorHover:{
      cursor: 'pointer',
      top: '0px',
    left: '0px',
    zIndex: '100',
    position: 'sticky',
    backgroundColor: '#fff',
    color: 'black',
    fontSize: '17px',
    display: 'table-cell',
    padding: '12px 56px 12px 24px',
    textAlign: 'left',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    verticalAlign: 'inherit',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    borderSpacing: '0',
    borderCollapse: 'collapse',
    },
    contentStyle:{
      color: 'rgba(0, 0, 0, 0.87)',
      fontSize: '0.8125rem',
      fontWeight: '400',
      display: 'table-cell',
      padding: '12px 56px 12px 24px',
    textAlign: 'left',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    verticalAlign: 'inherit',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    borderSpacing: '0',
    borderCollapse: 'collapse',

    },
    textCenter: {
      textAlign: `center`
    },
  };
};

export default GracePeriodPolicyPageStyle;