import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { Link } from "gatsby";
import footerSectionStyle from "ps-assets/jss/footerSectionStyle.jsx";
import PropTypes from "prop-types";
function PSFooterSection({ classes, section }) {
  const links = section.node.links;
  if (!links || links.length === 0) {
    return null;
  }
  const orderedLinks = links.sort((a, b) => {
    return a.sub_sequence - b.sub_sequence;
  });
  return (
    <ul className={(classes.linksVertical, classes.sectionBody)}>
      {orderedLinks.map(link => {
        return (
          <li key={link.id}>
            {(link.url.includes(`http://`) ||
              link.url.includes(`https://`)) && (
              <a
                href={link.url}
                target="__blank"
                style={{ textTransform: `capitalize` }}
                rel="noopener"
              >
                {link.title}
              </a>
            )}
            {!(
              link.url.includes(`http://`) || link.url.includes(`https://`)
            ) && (
              <Link to={link.url} style={{ textTransform: `capitalize` }}>
                {link.title}
              </Link>
            )}
          </li>
        );
      })}
    </ul>
  );
}
PSFooterSection.propTypes = {
  classes: PropTypes.object,
  section: PropTypes.object
};
export default withStyles(footerSectionStyle)(PSFooterSection);
