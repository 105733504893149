import React from "react";

// nodejs library that concatenates classes
import classNames from "classnames";

// @material-ui/core components
import Accordion from "components/Accordion/Accordion";
import withStyles from "@material-ui/core/styles/withStyles";

import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
import GridContainer from "components/Grid/GridContainer";
import aboutUsStyle from "ps-assets/jss/aboutUsStyle.jsx";
import ClaimReimbursementStatusTrail from "./claimReimbursementStatusTrail";
class ClaimReimbursementContent extends React.Component {
  claimData;
  render() {
    let { classes, data } = this.props;
    
    this.claimData = data
    let info =[];
    this.claimData.reimbursementDetails.map((d)=>{
      if(d.classification != undefined && d.classification != null){
        info.push(d)
      }
    })

    return (
      <div style={{ margin: `0px 20px` }}>
        <Accordion
          active={0}
          collapses={info.map(data => {
            var date= new  Date(data.documentReceivedDate);
           
            return {
              title: data.classification != null ? data.classification+`-`+date.toLocaleDateString() : `` ,
              content: (                
                <>
                  <GridContainer
                    className={classNames(classes.mrAuto, classes.mlAuto)}
                    style={{ fontSize: `13px`, paddingLeft: `20px` }}
                  >
                    <GridItem xs={12} sm={4} md={6}>
                      <b>Claimed Amount</b>
                      <div style={{ minHeight: 20 }}>
                        {data ? data.claimedAmount : ``}
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={4} md={6}>
                      <b>Approved Amount</b>
                      <div style={{ minHeight: 20 }}>
                        {data ? data.approvedAmount : ``}
                      </div>
                    </GridItem>
                    {/* <GridItem xs={12} sm={4} md={4}>
                      <b>Status</b>
                      <div style={{ minHeight: 20 }}>
                        {data ? data.status : ``}
                      </div>
                    </GridItem> */}
                     {data.classification != null && data.statusTrail.length !== 0 && (<ClaimReimbursementStatusTrail data={data}
                     fulldata={this.props.data}
                      intimationNumber={this.props.intimationNumber} classification={data.classification} category={this.props.category}
                      rodNumber={data.rodNumber} policyNo={this.claimData.policyNo} claimNo={data.claimNo} date={data.admissionDate} healthCard={data.healthCardNo}/>)}
                  </GridContainer>
                </>
              )
            };
          })}
        />
      </div>
    );
  }
}

export default withStyles(aboutUsStyle)(ClaimReimbursementContent);

ClaimReimbursementContent.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object,
  intimationNumber: PropTypes.string,
  category:PropTypes.string,
};
