import React from "react";
import captchaImg from "ps-assets/img/captcha.png";
import PropTypes from "prop-types";
class CanvasComponent extends React.Component {
  canvas = null
  
  componentDidMount() {
    this.updateCanvas(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.updateCanvas(nextProps);
  }
  updateCanvas = props => {
    const ctx = this.canvas.getContext(`2d`);
    var img = new Image();
    img.onload = () => {
      ctx.clearRect(0, 0, 150, 45);
      ctx.drawImage(img, 0, 0);
      ctx.font = `bold 20px Roboto Slab`;
      ctx.fillText(props.text, 15, 27.5);
    };
    img.src = captchaImg;
  };
  render() {
    return (
      <div>
        <canvas ref={(canvas) => this.canvas = canvas} width={150} height={45} text={this.props.text} />
      </div>
    );
  }
}
CanvasComponent.propTypes = {
  text: PropTypes.string,
};
export default CanvasComponent;
