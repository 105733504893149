import buttonGroup from "assets/jss/material-kit-pro-react/buttonGroupStyle.jsx";
import customCheckboxRadioSwitch from "assets/jss/material-kit-pro-react/customCheckboxRadioSwitchStyle.jsx";
import customSelectStyle from "assets/jss/material-kit-pro-react/customSelectStyle.jsx";
import {
  cardTitle,
  primaryColor,
  hexToRgb,
  blackColor,
  mlAuto,
  mrAuto,
  mTAuto,
  mBAuto
} from "assets/jss/material-kit-pro-react.jsx";
const wizardStyle = {
  cardTitle,
  ...buttonGroup,
  ...customCheckboxRadioSwitch,
  ...customSelectStyle,
  amount: {
    ...cardTitle,
    color: primaryColor[0] + ` !important`
  },
  customRadioRoot: {
    display: `none`
  },
  mlAuto,
  mrAuto,
  mTAuto,
  mBAuto,
  customLabel: {
    padding: `10px 10px`,
    width: `100%`,
    justifyContent: `center`,
    boxShadow: `none`,
    border: `solid thin #ccc`,
    borderRadius: `3px`,
    "& span": {
      color: `#000`
    },
    "&:hover": {
      backgroundColor: primaryColor[0],
      border: `solid thin ` + primaryColor[0],
      boxShadow:
        `0 4px 4px -4px rgba(` +
        hexToRgb(blackColor) +
        `, 0.56), 0 4px 4px 0px rgba(` +
        hexToRgb(blackColor) +
        `, 0.12), 0 4px 4px -5px rgba(` +
        hexToRgb(blackColor) +
        `, 0.2)`
    },
    "&:hover span": {
      color: `#FFF !important`
    },
    margin: `10px`
  },
  activeLabel: {
    backgroundColor: primaryColor[0],
    border: `solid thin ` + primaryColor[0],
    boxShadow:
      `0 4px 4px -4px rgba(` +
      hexToRgb(blackColor) +
      `, 0.56), 0 4px 4px 0px rgba(` +
      hexToRgb(blackColor) +
      `, 0.12), 0 4px 4px -5px rgba(` +
      hexToRgb(blackColor) +
      `, 0.2)`,
    "& span": {
      color: `#FFF !important`
    }
  },
  count: {
    display: `inline-block`,
    padding: `5px`,
    paddingTop: `2px`,
    border: `2px solid #eee`,
    color: `black`,
    width: `35px`,
    height: `35px`,
    textAlign: `center`
  },
  error: {
    color: `red`
  },
  loadingGIF: {
    textAlign: `center`
  },
  emiContent:{
    width:`fit-content`,
    display:`flex`,
    marginRight:`auto`,
    marginLeft:`auto`
  },
  checkboxspan:{
  marginTop:'2%',
  paddingBottom:'1.5%'
  },
  authorize:{
    color:'black',
    display:'contents',
    fontSize:'16px'
  },
  emiText:{
    marginLeft:`5px`,
    cursor:`pointer`,
    color:`blue`,
    textDecoration:`underline`
  },
  GMCbutton:{
    float: 'center',
    marginLeft: `auto`,
    marginRight: `auto`,
    width: `fit-content`,
  },
  GMCform:{
  paddingLeft: '30px !important',
  paddingRight: '15px !important',
  },
  youngstar_label:{
    fontFamily: "'Inter'",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "17px",
    color: "#3c4858"
  }
};

export default wizardStyle;
