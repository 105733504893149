import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from 'prop-types';
import React from 'react'
import Button from "components/CustomButtons/Button.jsx";
// import { browserWindow } from "util/localStorageHelper";
import CustomInput from "components/CustomInput/CustomInput";
import FormHelperText from "@material-ui/core/FormHelperText";
import { navigate } from '@reach/router';
import {browserLocalStorage} from "util/localStorageHelper";

const CUSTOMERPORTAL_URL = process.env.CUSTOMERPORTAL_URL
const regex = /^[0-9]*$/
class FileUploadComponent extends React.Component{
  claimData;
  claimStatusResponse;
  claimClassification;
  value = true
  category;
    state={
        claimType:'',
        displayButton: true,
        validUpload:true,
        claimedAmount: '',
        error: false
    }
  comboDropdown = [
  { name: 'Hospitalization', value: 'HOSPITALISATION' },
  { name: 'PreHospitalization', value: 'PRE_HOSPITALISATION'},
  { name: 'PostHospitalization', value: 'POST_HOSPITALISATION' },
  ];
    
    componentDidMount(){
      // this.claimData = 'HOSPITALISATION';
      // this.getUploadFunction(this.claimData)
      
      
    }

    handleChange=(e)=> {
        this.setState({ claimType: e.target.value,validUpload:true });
        this.claimData = e.target.value 
        this.getUploadFunction()
        
    }

    handleAmountChange = (e) => {
      if(regex.test(e.target.value)){
        this.setState({
          claimedAmount: e.target.value
        })
      }
    }

    getUploadFunction(){
      if(this.claimStatusResponse.eligibleForRoD==true){
        this.value = false;
        
      }
      if(this.category == 'op'){
        this.value = false;
      }
      
      
      this.setState({displayButton: this.value})
    }

    handleSubmit = (data, intimationNumber, type, category)=>{
      var valid=true;
      if(this.state.claimedAmount.length == 0){
        valid = false
        this.setState({
          error: true
        })
      }
      if(data.reimbursementDetails){
        data.reimbursementDetails.map((item)=>{
          //item.classification = "HOSPITALISATION,,PRE_HOSPITALISATION,POST_HOSPITALISATION";
          if(item.classification && item.classification.includes(',') && item.classification.includes(',')){            
            // let x = item && item.classification.split(',');    
            // console.log(x)
            if(this.state.claimType==`HOSPITALISATION`){
              valid=false;
              this.setState({
                validUpload:false
              });
            }
            else if(this.state.claimType=='PRE_HOSPITALISATION'){
              valid=false;
              this.setState({displayButton: true})
            }
            else if(this.state.claimType==`POST_HOSPITALISATION`){
              type = `POST_HOSPITALISATION`;
            }
          }
          if(item.classification=='HOSPITALISATION' && item.rodNumber && this.state.claimType=='HOSPITALISATION'){
            valid=false;
            this.setState({
              validUpload:false
            });
          }
        })
      }
      if(valid){
        let claimToken = browserLocalStorage.getItem(`getClaimToken${intimationNumber}`)
        let policyToken = browserLocalStorage.getItem(`getPolicyToken${data.policyNo}`)
        let url=`${CUSTOMERPORTAL_URL}/customerportal/file-upload?policyNumber=${data.policyNo}&iintimationNo=${intimationNumber}&type=${type}&category=${category}&claimedAmount=${this.state.claimedAmount}&policyToken=${policyToken}&claimToken=${claimToken}`
        if(data.healthCardNo){
          url=url+`&healthCardNo=${data.healthCardNo}`;
        }
      navigate(url,`_self`)
      }
    }

    renderBasedOnCategory= () =>{
      if(this.category == 'op'){
        return(
          <Select
          value={this.state.claimType}
          displayEmpty
          onChange={this.handleChange}
          style={{ display: `block` }}
          inputProps={{
            name: `claimType`,
            id: `claimType`
          }}
        >
          {this.claimClassification.map((menu,index) => 
            <MenuItem
            key={index}
            value={menu}
            >
              {menu}
            </MenuItem>)}

          </Select>
        )
      }
      else{
        return(
          <Select
          value={this.state.claimType}
          displayEmpty
          onChange={this.handleChange}
          style={{ display: `block` }}
          inputProps={{
            name: `claimType`,
            id: `claimType`
          }}
        >
          {this.comboDropdown.map((menu,index) => 
            <MenuItem
            key={index}
            value={menu.value}
            >
              {menu.name}
            </MenuItem>)}
          </Select>
        );
      
      }
    }

    render(){
      let data=this.props.data
      let intimationNumber=this.props.intimationNumber
      this.category = this.props.category
      this.claimStatusResponse = data;
      this.claimClassification = this.props.classification;
      //const disabledNext = nextPost ? "" : "disabled-link"
      
        return(
            <GridContainer>
              <GridItem md={12} style={{display: 'flex'}}>
            <GridItem md={4}
            style={this.state.claimType == 'HOSPITALISATION' ? {marginLeft:'20%'} : {marginLeft:'37%'}}
            >
            <InputLabel htmlFor="city">Claim Type</InputLabel>
           
              {/* <MenuItem value="Hospitalization">Hospitalization</MenuItem>
              <MenuItem value="PreHospitalization">PreHospitalization</MenuItem>
              <MenuItem value="PostHospitalization">PostHospitalization</MenuItem> */}
              {this.renderBasedOnCategory()}
          </GridItem>
          {this.state.claimType == 'HOSPITALISATION' && (<GridItem md={6} style={{marginTop: '-3px',maxWidth: '30%'}}>
          <CustomInput
                id="Claim Amount"
                inputProps={{
                  placeholder: `Claim Amount*`,
                  required: true,
                }}
                formControlProps={{
                  fullWidth: true,
                  required: true,
                }}
                value={this.state.claimedAmount}
                onChange={this.handleAmountChange}
              />
              {this.state.error && (
          <FormHelperText id="my-helper-text" error>
            Please enter claim Amount{` `}
          </FormHelperText>
          )}
          </GridItem>)}
          </GridItem>
          <GridItem md={12} style={{textAlign:'center'}}>
            <div  style={{ pointerEvents: this.state.displayButton ? 'none' : 'auto' }}>
            <Button
              disabled={this.state.displayButton}
              color="primary"
              onClick={() => this.handleSubmit(data, intimationNumber, this.state.claimType, this.category)}
            >
              UPLOAD DOCUMENTS
            </Button>
            {
              !this.state.validUpload && (
                <GridItem md={12} style={{textAlign:'center',color:'red'}}>
                Hospitalisation claim is already submitted for this claim intimation.
                </GridItem>
              )
            }
            {/* <Link  to={`/customerportal/file-upload?policyNumber=${data.policyNo}&iintimationNo=${intimationNumber}&type=${this.state.claimType}`}>
        <span>
            <h4 style={{marginTop:'20px'}}>Upload Documents</h4>
            <img style={{marginTop: '-9%',marginLeft:'33%',height:'43px'}} src={withPrefix("/Vectornext.png")}/>
            </span>
            </Link> */}
            </div>
            </GridItem>
          
        </GridContainer>
        
        )
    }
}
FileUploadComponent.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object,
  intimationNumber:PropTypes.string,
  classification: PropTypes.arrayOf(PropTypes.string),
  category:PropTypes.string,
};
export default FileUploadComponent




