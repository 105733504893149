import { browserWindow } from "./localStorageHelper";

const CUSTOMER_PORTAL_API_URL = process.env.CUSTOMER_PORTAL_API_URL;
// const CUSTOMER_PORTAL_API_URL = `https://customer.starhealth.in`;
// const CUSTOMER_PORTAL_API_URL = `https://customer-uat.starhealth.in`;

// const cityAndOfficeCode = {
//   "chennai":"700001",
//   "bangalore":"700019",
//   "hyderabad":"700013",
//   "mumbai":"700017",
//   "noida":"700004",
//   "delhi":"700016",
//   "kolkata":"700018",
//   "lucknow":"700020"
// }

// const getChannelAndSource = (channel) => {
//   switch (channel) {
//     case `tele`:
//       return `TeleSales`;
//     case `direct`:
//       return `Direct`;
//     default:
//       return `Direct`;
//   }
// }

// function getOfficeCodeForCity(city){
//   switch (city.toLowerCase()) {
//     case "chennai":
//     case "bangalore":
//     case "hyderabad":
//     case "mumbai":
//     case "noida":
//     case "delhi":
//     case "kolkata":
//     case "lucknow":
//       return cityAndOfficeCode[city.toLowerCase()];
//     default:
//       return ``;
//   }
// }

export const lmsRequestCall = function(requestBody) {
  let url = `${CUSTOMER_PORTAL_API_URL}/api/portal/insurance/calculate/LMSSales/leadupload/new`;
  requestBody = {
    ...requestBody,
    leadType: `FRESH`,
    officeCode: `700002`,
  };
  let language=browserWindow.location.pathname.split('/')[1];
    if(language!='hindi' && language!='marathi' && language!='tamil' ){
      language='english';
    }
    requestBody["receivedSystem"]="Website";
    requestBody["preferedLanguage"]=language;
  if(requestBody.productName == "Star Group Health Insurance"){
    requestBody.officeCode= '',
    requestBody.source= "GMC"
  }
  return fetch(url, {
    method: `POST`,
    headers: {
      "Content-Type": `application/json`,
      clientId: `website`,
    },
    body: JSON.stringify(requestBody),
  });
};

export const dndCall = function(name,mobileNo){
  let url = `${CUSTOMER_PORTAL_API_URL}/api/portal/insurance/calculate/LMSSales/create/dnd`;
  fetch(url,{
    method:"POST",
    headers: {
      "Content-Type": `application/json`,
      clientId: `website`,
    },
    body: JSON.stringify({name,mobileNo})
  })
}

export const wizardLMSCall = function(name,mobile,email,productName,productId,urlInfo=browserWindow.location.href,city,source,others={}){
  let queryParams = decodeURIComponent(browserWindow.location.search);
  const leadsUTMKeyMapping = {
    "utm_source":`utmSource`,
    "utm_campaign":`utmCampain`,
    "utm_channel":`teleChannel`,
    "utm_medium":`utmMedium`,
    "utm_term": `utmTerm`,
    "utm_content":`utmContent`,
    "gclid": "gclid",
    "keyword":"keyword"
  };
  let lmsRequestBody = {
    firstName:name,
    mobileNumber:mobile,
    email:email,
    productName:null,
    source:`on quote`,
    urlInfo:urlInfo,
    ...others
  };
  if (city) {
    lmsRequestBody.city = city;
  }
  if(source){
    lmsRequestBody['source']=source
  }
  if(queryParams){
    queryParams = queryParams.split(`?`)[1];
    queryParams = queryParams.split(`&`);
    queryParams.map(param => {
      let key = param.split(`=`)[0];
      let value = param.split(`=`)[1];
      if(key && leadsUTMKeyMapping[key.toLowerCase()]){
        if(!lmsRequestBody[leadsUTMKeyMapping[key.toLowerCase()]]){
          lmsRequestBody[leadsUTMKeyMapping[key.toLowerCase()]] = value;
        }
      }
    });
  }
  else if(browserWindow.location.pathname == '/health-insurance-plans'){
    lmsRequestBody[leadsUTMKeyMapping['utm_source']] = 'Internal',
    lmsRequestBody[leadsUTMKeyMapping['utm_campaign']] = 'HI_plans'
  }
  // else if(browserWindow.location.href == 'https://web.starhealth.in/') {
  //   lmsRequestBody[leadsUTMKeyMapping['utm_source']] = 'Internal',
  //   lmsRequestBody[leadsUTMKeyMapping['utm_campaign']] = 'home_banner'
  // }
  else if(browserWindow.location.pathname == '/health-insurance-for-parents'){
    lmsRequestBody[leadsUTMKeyMapping['utm_source']] = 'Internal',
    lmsRequestBody[leadsUTMKeyMapping['utm_campaign']] = 'Parents_plans'
  }

  if(browserWindow.location.pathname == '/group-health-insurance' || browserWindow.location.pathname == '/international-corporate-travel-insurance' || browserWindow.location.pathname == '/group-accident-insurance-policy'){
    lmsRequestBody.source = 'GMC'
  }
  else if(browserWindow.location.pathname == '/star-outpatient-care-insurance-policy'){
    lmsRequestBody.source = 'OPD'
  }
  // console.log(JSON.stringify(lmsRequestBody));
  return lmsRequestCall(lmsRequestBody);
};

export function landingPageLMSCall(name,mobile,email,zipCode,city,otp=null,others={}){
  let url = `${CUSTOMER_PORTAL_API_URL}/api/portal/insurance/calculate/LMSSales/leadupload/new`;
  let queryParams = decodeURIComponent(browserWindow.location.search);
  const utmKeys = {
    UMTChannel : `teleChannel`,
    UMTCampaign:`utmCampain`,
    UMTSource:`utmSource`,
    otp:"otpverfie",
    city:"city",
  }
  let leadsUTMKeyMapping = {
    "utm_channel":utmKeys.UMTChannel,
    "utm_campaign":utmKeys.UMTCampaign,
    "utm_source":utmKeys.UMTSource,
    "utm_city":utmKeys.city,
    "utm_medium":`utmMedium`,
    "utm_term": `utmTerm`,
    "utm_content":`utmContent`,
    "gclid": "gclid",
    "keyword":"keyword"
  }
  let lmsRequestBody = {
    firstName:name,
    mobileNumber:mobile,
    email:email,
    source:`lp`,
    // leadType: `FRESH`,
    zipCode:zipCode
  };
  if(queryParams){
    queryParams = queryParams.split(`?`)[1];
    queryParams = queryParams.split(`&`);
    queryParams.map(param => {
      let key = param.split(`=`)[0];
      let value = param.split(`=`)[1];
      if(key && leadsUTMKeyMapping[key.toLowerCase()]){
        if(!lmsRequestBody[leadsUTMKeyMapping[key.toLowerCase()]]){
          lmsRequestBody[leadsUTMKeyMapping[key.toLowerCase()]] = value;
        }
      }
    });
  }
  // lmsRequestBody[utmKeys.UMTChannel] = lmsRequestBody[utmKeys.UMTChannel] ? lmsRequestBody[utmKeys.UMTChannel] : `Direct`;
  // lmsRequestBody[utmKeys.city] = lmsRequestBody[utmKeys.city] ? lmsRequestBody[utmKeys.city] : ``;
  // lmsRequestBody[`source`] = getChannelAndSource(lmsRequestBody[utmKeys.UMTChannel]);
  lmsRequestBody[utmKeys.otp] = otp;
  // switch(lmsRequestBody[utmKeys.UMTChannel].toLowerCase()){
  //   case `direct`:
  //     lmsRequestBody.officeCode = `700002`;
  //     break;
  //   case `tele`:
  //     lmsRequestBody.officeCode = getOfficeCodeForCity(lmsRequestBody[utmKeys.city]);
  //     break;
  // }
  lmsRequestBody.city = city;
  lmsRequestBody = {...lmsRequestBody,...others}
  lmsRequestBody.urlInfo = browserWindow.location.href;  
  let language=browserWindow.location.pathname.split('/')[1];
    if(language!='hindi' && language!='marathi' && language!='tamil' ){
      language='english';
    }
  lmsRequestBody["receivedSystem"]="Website";
  lmsRequestBody["preferedLanguage"]=language;
  return fetch(url, {
    method: `POST`,
    headers: {
      "Content-Type": `application/json`,
      clientId: `website`,
    },
    body: JSON.stringify(lmsRequestBody),
  });
}
