import React from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
import classNames from 'classnames';
import Radio from "@material-ui/core/Radio";
import Button from 'components/CustomButtons/Button.jsx';
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import claimListStyle from "ps-assets/jss/claimListStyle.jsx";
import loadingGIF from 'ps-assets/img/star2.gif';
// import { ArrowForwardIcon } from '@mui/icons-material';
    class SelectMobileNumber  extends React.Component{
        state={
            value:'',
            loading: false
        }
        handleChangeEnabled=(event)=>{
           // console.log('eve',event.target.value)
            this.setState({
                value:event.target.value
            })
        }
        sendOTP=()=>{
          this.setState({
            loading: true
           },
           ()=>{
            this.props.handleOTP(this.state.value)
           })
          
        }

    render(){
        //console.log('valrender',this.state.value)
        const classes=this.props.classes
        let val = true;
        if(this.state.value == ''){
          val = true
        }
        else{
          val = false
        }
        // let intimations=this.props.intiamtionDetails
        return(
            <GridItem
            md={9}
            sm={9}
            xs={12}
            className={classNames(
              classes.mrAuto,
              classes.mlAuto,
              classes.textCenter
            )}>
            {this.state.loading && (
              <GridItem>
                <div
                  style={{ margin: `0 auto` }}
                  className={classes.loadingGIF}>
                  <img src={loadingGIF} />
                </div>
              </GridItem>
            )}
            {!this.state.loading && (
            <>
            <h2
              style={{
                fontWeight: 200,
                color: 'black'
              }}>
              Verify yourself to proceed
            </h2>
            <h3
              style={{
                fontWeight: 200,
                color: 'black'
              }}>
              Select a number from below which you have access to
            </h3>
            {this.props.mobileNumbers && this.props.mobileNumbers.map((mobileNumber,index)=>{
 return(  <GridItem
 key={index}
   xs={12}
   md={12}
   sm={12}
   style={{ marginLeft: `3%`, marginTop: `2%`,textAlign:'left' }}
   className={classes.radiobuttonclaimfix}
 >
<Radio
checked={this.state.value === this.props.mobileNumbers[index]}
onChange={this.handleChangeEnabled}
value={mobileNumber}
name="one"
// aria-label="XXXXXXXX80"
icon={<FiberManualRecord className={classes.radioUnchecked} />}
checkedIcon={
  <FiberManualRecord className={classes.radioChecked} />
}
classes={{
  checked: classes.radio,
  root: classes.radioRoot
}}
/>
{mobileNumber.replace(/\d(?=\d{2})/g, "*")}
</GridItem>
 )
            })}
          <GridItem
            md={12}
            sm={12}
            xs={12}
            style={{ textAlign: `center` }}
            className={classNames(classes.mrAuto, classes.mlAuto)}>
            <Button
              disabled={val}
              onClick={this.sendOTP}
              color='primary'
              style={{ marginLeft: '-1.4%' }}>
              Send Otp
            </Button>
          </GridItem>
          </>
        )}
            </GridItem>
        )
    }
        
}
export default withStyles(claimListStyle)(SelectMobileNumber);

SelectMobileNumber.propTypes = {
  classes: PropTypes.object,
  mobileNumbers:PropTypes.array,
  handleOTP:PropTypes.func
};