import {
  container,
  mlAuto,
  mrAuto,
  title,
  description,
  main,
  mainRaised,
  section
} from "assets/jss/material-kit-pro-react.jsx";

import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.jsx";

import sectionCards from "assets/jss/material-kit-pro-react/views/componentsSections/sectionCards.jsx";
const features = theme => ({
  ...modalStyle,
  container,
  mlAuto,
  mrAuto,
  title,
  main,
  mainRaised,
  ...sectionCards,
  description,
  team: {
    padding: `80px 0px`
  },
  gridContainer: {},
  gridItem: {},
  textCenter: {
    textAlign: `center`
  },
  section: {
    ...section,
    padding: `70px 0px`,
    "& h4$description": {
      fontSize: `1.5em`
    }
  },
  contentStyles: {
    textAlign: `left`,
    fontSize: `15px`,
    marginBottom: `10px`,
    color: `rgb(0,0,0)`
  },
  phoneContainer: {
    "& img": {
      width: `100%`
    }
  },
  displayFlex:{
    "@media (max-width: 600px)": {
      display:'block'
    },
  },
  card: {
    boxShadow: `none`,
    border: `solid 1px #eee`,
    "&:hover": {
      boxShadow: `0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(255, 255, 255, 0.4)`
    },
    marginTop: `10px`
  },
  modal: {
    maxWidth: `700px`
  },
  modalHeader: {
    padding: `2px 24px 0px !important`
  },
  heightfix: {
    [theme.breakpoints.down(`md`)]: {
      height: `auto !important`
    }
  },
  infoArea: {
    maxWidth: `none`,
    margin: `0 auto`,
    padding: `10px 0 0px`
  },
  infoArea5: {},
  cardDescription: {
    color: `#000 !important`,
    "& p": {
      fontSize: `15px !important`
    }
  },
  cardTitle: {
    fontSize: `20px`
  },
  h1title:{
    ...title,
    fontSize:'38px'
  },
  claimimage:{
  width: '20px',
  marginTop: '-7px',
  marginRight: '7px',
  },
});

export default features;
