import React from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import claimIntimationListStyle from "ps-assets/jss/claimIntimationListStyle.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
import GridContainer from "components/Grid/GridContainer";
import {withPrefix } from "gatsby"
import moment from "moment";
// import { ArrowForwardIcon } from '@mui/icons-material';
    class ClaimIntimationListForUpload  extends React.Component{
        claimList=(intimations)=>{
            let classes=this.props.classes
            intimations && intimations.map((intimation)=>{
                return(
                <>
        <div className={classes.listposition}
        >
            <GridContainer>
                <GridItem md={6} xs={12} sm={6}>
                    <span>
                    <img src={withPrefix("/Rectangle.png")} className={classes.imageposition}>
                    {/* <img src={withPrefix("/Stroke.png")} /> */}
                        </img>
                        <img src={withPrefix("/Stroke.png")} className={classes.imageposition2}>
                        </img>
                        </span>
                        <div className={classes.intimationnumber}>
                        {intimation.claimNumber}
                        </div>
                        <div>
                        CLAIM NUMBER
                        </div>
                </GridItem>
                <GridItem md={4} xs={10} sm={4} style={{marginTop: '24px',marginLeft: '-7%',}} >
                   {moment(intimation.claimDate).format("MMM Do YY")  } 
                   <div className={classes.dateposition}>
                       DATE OF FILLING
                       </div>
                    
                </GridItem>
                <GridItem md={2} xs={2} sm={2}>
                    {/* <ArrowForwardIcon fontSize={large} fontColor={blackColor}
                    /> */}
                    <img style={{marginTop: '14px'}} src={withPrefix("/Vectornext.png")} onClick={()=>{
                        this.props.onSelectClaim('largeModal',intimation.claimNumber,intimation.healthCardNo)
                    }}/>
                </GridItem>
            </GridContainer>
        </div>
        </>
        )})
        
                }
    render(){
        const classes=this.props.classes
        let intimations=this.props.intiamtionDetails
        return(
           
            <div>
            {intimations && intimations.map((intimation,index)=>{
           return( 
        <div className={classes.listposition}
        key={index}
        >
            <GridContainer>
                <GridItem md={6} xs={12} sm={6}>
                    <span>
                    <img src={withPrefix("/Rectangle.png")} className={classes.imageposition}>
                    {/* <img src={withPrefix("/Stroke.png")} /> */}
                        </img>
                        <img src={withPrefix("/Stroke.png")} className={classes.imageposition2}>
                        </img>
                        </span>
                        <div className={classes.intimationnumber}>
                        {intimation.claimNumber}
                        </div>
                        <div style={{fontSize:'12px'}}>
                        CLAIM NUMBER
                        </div>
                </GridItem>
                <GridItem md={4} xs={10} sm={4} style={{marginTop: '24px',marginLeft: '-7%',}} >
                   {moment(intimation.claimDate).format("MMM Do YY")  } 
                   <div  style={{fontSize:'12px',marginLeft:'2%'}}>
                       DATE OF FILLING
                       </div>
                    
                </GridItem>
                <GridItem md={2} xs={2} sm={2}>
                    {/* <ArrowForwardIcon fontSize={large} fontColor={blackColor}
                    /> */}
                    <img style={{marginTop: '14px'}} src={withPrefix("/Vectornext.png")} onClick={()=>{
                        this.props.onSelectClaim(intimation.claimNumber,intimation.healthCardNo)
                    }}/>
                </GridItem>
            </GridContainer>
        </div>
           )
            })}
            </div>
        )
    }
        
}
export default withStyles(claimIntimationListStyle)(ClaimIntimationListForUpload);

ClaimIntimationListForUpload.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.array,
  intiamtionDetails:PropTypes.array,
  onSelectClaim:PropTypes.func
};