import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Close from "@material-ui/icons/Close";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody";
import modalFormStyle from "ps-assets/jss/modalFormStyle";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

Transition.displayName = `Modal Transition`;

class ModalForm extends React.Component {
  handleClose = () => {
    this.props.handleClose();
  };
  render() {
    const { classes, children, title, overFlowXHidden } = this.props;
    return (
      <div>
        <Dialog
          classes={{
            root: this.props.zIndex ? classes.modelZindex : classes.modalRoot,
            paper: this.props.reduceWidth ? classes.modalwidth : classes.modal,
          }}
          open={this.props.show}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.handleClose}
          aria-labelledby="classic-modal-slide-title"
          aria-describedby="classic-modal-slide-description"
        >
          <GridItem>
            {this.props.showBackButton && (
              <Button
                simple
                className={classes.modalArrowBackButton}
                key="ArrowBackIcon"
                aria-label="ArrowBackIcon"
                onClick={this.props.handleBackButton}
              >
                <ArrowBackIcon className={classes.modalArrowBack} />
              </Button>
            )}
            <Button
              simple
              className={classes.modalCloseButton}
              key="close"
              aria-label="Close"
              onClick={this.handleClose}
            >
              <Close className={classes.modalClose} />
            </Button>
            {title && <Card
              className={`${classes.modalHeaderCard} ${
                title && title.length > 14 ? classes.smallHeaderCard : ``
              }`}
            >
              <CardBody cardColor="white" className={`${classes.cardBodySize}`}>
                {title}
              </CardBody>
            </Card>}
          </GridItem>

          <DialogContent
            id="classic-modal-slide-description"
            className={`${classes.modalBody} ${classes[this.props.customStyle]} ${
              overFlowXHidden ? classes.overFlowXHidden : ``
            }`}
          >
            {children}
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

ModalForm.propTypes = {
  handleClose: PropTypes.func,
  classes: PropTypes.object,
  children: PropTypes.object,
  title: PropTypes.string,
  overFlowXHidden: PropTypes.bool,
  zIndex: PropTypes.bool,
  reduceWidth:PropTypes.bool,
  show: PropTypes.bool,
  showBackButton: PropTypes.bool,
  handleBackButton: PropTypes.func,
  customStyle: PropTypes.string
};

export default withStyles(modalFormStyle)(ModalForm);
