import React from "react";
import PropTypes from "prop-types";
import loadingGIF from "ps-assets/img/star2.gif";

function Loading({ classes }) {
  return <img className={classes} src={loadingGIF} alt="loading-img" />;
}

Loading.propTypes = {
  classes: PropTypes.object,
};

export default Loading;
