import React from "react";
import classNames from "classnames";
import Datetime from "react-datetime";
import Tooltip from "@material-ui/core/Tooltip";
import NavPills from "components/NavPills/NavPills.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import PropTypes from "prop-types";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Radio from "@material-ui/core/Radio";
import FormControl from "@material-ui/core/FormControl";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import Captcha from "ps-components/Captcha/Captcha.jsx";
import CustomSnackbar from "components/Snackbar/Snackbar";
import FormHelperText from "@material-ui/core/FormHelperText";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import claimListStyle from "ps-assets/jss/claimListStyle.jsx";
//import { browserWindow } from 'util/localStorageHelper';

var apiBaseUrl = process.env.API_URL;

const policy_no_regex = new RegExp(
  `^P/([0-9]{6})/([0-9]{2})/([0-9]{4})/([0-9]{6})$`,`i`
);

const policyno_new_regex = new RegExp(
  `^([0-9]{13,15})$`,`i`
);

class claimsIntimation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedEnabled: `network`,
      intimation_type: `accident`,
      intimator_name: [``, true],
      // intimator_contact_no: [``, true],
      intimator_relationship: [``, true],
      attender_contact_no: [``, true],
      intimator_email: [``, true],
      policy_no: [``, true],
      proposer_name: [``, false],
      customer_id: [``, false],
      insured_contact_no: [``, true],
      insured_patient_name: [``, true],
      accident_date: ``,
      accident_place: [``, true],
      fir_details: [``, true],
      reason: [``, true],
      admission_date: ``,
      comments: [``, false],
      hospital_type: `network`,
      hospital_name: [``, true],
      hospital_address: [``, true],
      state: [``, true],
      hospital_contact_no: [``, false],
      hospital_fax_no: [``, false],
      doctor_name: [``, false],
      room_category: [``, false],
      refreshCaptcha: true,
      submitStatus: ``,
      submitButton: false,
      captchaText: ``,
      inputCaptchaText: ``
    };
  }
  setCaptchaText = (captchaText, inputCaptchaText) => {
    this.setState({
      captchaText: captchaText,
      inputCaptchaText: inputCaptchaText
    });
  };
  handleChange = e => {
    if (
      e.target.id === `attender_contact_no` ||
      // e.target.id === `intimator_contact_no` ||
      e.target.id === `insured_contact_no`
    ) {
      if (!isNaN(e.target.value) && e.target.value.length <= 10) {
        this.setState({ [e.target.id]: [e.target.value, true] });
      }
    } else if (
      e.target.id === `intimator_name` ||
      e.target.id === `insured_patient_name` ||
      e.target.id === `hospital_name` ||
      e.target.id === `doctor_name`
    ) {
      if (e.target.value.search(`^[a-zA-Z. ']*$`) === 0) {
        this.setState({
          [e.target.id]: [e.target.value, this.state[e.target.id][1]]
        });
      }
    } else if (e.target.id === `customer_id`) {
      if (
        e.target.value.search(`^[a-zA-Z0-9-']*$`) === 0 &&
        e.target.value.length <= 12
      ) {
        this.setState({ [e.target.id]: [e.target.value, false] });
      }
    } else { 
      //P/123456/01/2016/123456
      if(e.target.id==`policy_no`){
        let policy = e.target.value;
        if (policy.substring(0,1).toUpperCase() =='P'){
          switch(policy.length){
            case 1:
            case 8:
            case 11:
            case 16:
              policy=policy+`/`;
              break;
          }
          if(policy.length<=23){
            this.setState({
              [e.target.id]: [policy, this.state[e.target.id][1]]
            });
          }
        }
        else{
          if(policy.length<=15){
            this.setState({
              [e.target.id]: [policy, this.state[e.target.id][1]]
            });
          }
        }
      }
      else{
        this.setState({
          [e.target.id]: [e.target.value, this.state[e.target.id][1]]
        });
      }
    }
  };

  handleChangeEnabled = event => {
    this.setState({ selectedEnabled: event.target.value });
  };

  updateRefreshCaptcha = () => {
    this.setState({
      refreshCaptcha: false
    });
  };
  handleSubmitStatus = () => {
    this.setState({
      submitStatus: ``
    });
  };
  handleaccident = index => {
    this.setState({
      intimation_type: index === 1 ? `accident` : index === 0 ? `health` : ``
    });
  };
  handleSubmit = async e => {
    e.preventDefault();
    let url = `${apiBaseUrl}form/claims`;
    let {
      intimation_type,
      intimator_name,
      // intimator_contact_no,
      intimator_relationship,
      attender_contact_no,
      intimator_email,
      policy_no,
      proposer_name,
      insured_patient_name,
      reason,
      admission_date,
      hospital_type,
      state,
      hospital_name,
      hospital_contact_no,
      hospital_fax_no,
      doctor_name,
      room_category,
      hospital_address,
      comments,
      customer_id,
      insured_contact_no,
      accident_date,
      accident_place,
      fir_details
    } = this.state;

    this.setState({
      submitButton: true
    });

    var splitDate = admission_date && admission_date.split(`-`);
    admission_date = splitDate[0];

    if (accident_date !== ``) {
      splitDate = accident_date.split(`-`);
      accident_date = splitDate[0];
      intimation_type = `accident`;
    }

    let data = {
      intimation_type: intimation_type != `` ? intimation_type : ``,
      intimator_name: intimator_name[0] != `` ? intimator_name[0] : ``,
      customer_id: customer_id[0] != `` ? customer_id[0] : ``,
      insured_contact_no:
        insured_contact_no[0] != `` ? insured_contact_no[0] : ``,
      // intimator_contact_no:
      //   intimator_contact_no[0] != `` ? intimator_contact_no[0] : ``,
      intimator_relationship:
        intimator_relationship[0] != `` ? intimator_relationship[0] : ``,
      attender_contact_no:
        attender_contact_no[0] != `` ? attender_contact_no[0] : ``,
      intimator_email: intimator_email[0] != `` ? intimator_email[0] : ``,
      policy_no: policy_no[0] != `` ? policy_no[0] : ``,
      intimator_contact_no:``,
      proposer_name: proposer_name[0] != `` ? proposer_name[0] : ``,
      insured_patient_name:
        insured_patient_name[0] != `` ? insured_patient_name[0] : ``,
      reason: reason[0] != `` ? reason[0] : ``,
      admission_date: admission_date != `` ? admission_date : ``,
      hospital_type: hospital_type != `` ? hospital_type : ``,
      state: state[0] != `` ? state[0] : ``,
      hospital_name: hospital_name[0] != `` ? hospital_name[0] : ``,
      hospital_contact_no:
        hospital_contact_no[0] != `` ? hospital_contact_no[0] : ``,
      hospital_fax_no: hospital_fax_no[0] != `` ? hospital_fax_no[0] : ``,
      doctor_name: doctor_name[0] != `` ? doctor_name[0] : ``,
      room_category: room_category[0] != `` ? room_category[0] : ``,
      hospital_address: hospital_address[0] != `` ? hospital_address[0] : ``,
      comments: comments[0] != `` ? comments[0] : ``,
      accident_date: accident_date != `` ? accident_date : ``,
      accident_place: accident_place[0] != `` ? accident_place[0] : ``,
      fir_details: fir_details[0] != `` ? fir_details[0] : ``,
      userip: `192.168.1.1`
    };

    let params = {
      method: `POST`,
      mode: `cors`,
      cache: `no-cache`,
      credentials: `same-origin`,
      headers: {
        "Content-Type": `application/json`
      },
      body: JSON.stringify(data)
    };
    let count = 0;
    for (var key in data) {
      if (data.hasOwnProperty(key)) {
        if (key == `intimation_type`) continue;
        if (
          (key == `accident_place` || key == `fir_details`) &&
          this.state.intimation_type == `health`
        ) {
          continue;
        }
        if (this.state[key]) {
          if (this.state[key][1]) {
            if (this.state[key][0]) {
              if (this.state[key][0].length != 0) {
                if (key === `policy_no`) {
                 if ((policy_no_regex.test(this.state.policy_no[0])) || (policyno_new_regex.test(this.state.policy_no[0])) )
                    count = count + 1;
                } else count = count + 1;
              }
            }
          }
        }
      }
    }
    if (
      (count == 13 &&
        intimation_type === `health` &&
        this.state.captchaText.split(` `).join(``) ===
          this.state.inputCaptchaText &&
        this.state.captchaText.split().join(``) != `` &&
        this.state.inputCaptchaText != `` &&
        this.state.hospital_type &&
        this.state.admission_date) ||
      (count == 16 &&
        intimation_type === `accident` &&
        this.state.captchaText.split(` `).join(``) ===
          this.state.inputCaptchaText &&
        this.state.captchaText.split().join(``) != `` &&
        this.state.inputCaptchaText != `` &&
        this.state.hospital_type &&
        this.state.accident_date &&
        this.state.admission_date)
    ) {
      let res = await fetch(url, params);
      if (res.status !== 200) {
        this.setState({
          submitStatus: `error`
        });
      } else {
        this.setState({
          intimation_type: this.state.intimation_type,
          intimator_name: [``, true],
          // intimator_contact_no: [``, true],
          intimator_relationship: [``, true],
          attender_contact_no: [``, true],
          intimator_email: [``, true],
          policy_no: [``, true],
          proposer_name: [``, false],
          insured_patient_name: [``, true],
          accident_date: ``,
          customer_id: [``, false],
          insured_contact_no: [``, true],
          accident_place: [``, true],
          fir_details: [``, true],
          reason: [``, true],
          admission_date: ``,
          comments: [``, false],
          hospital_type: `network`,
          hospital_name: [``, true],
          hospital_address: [``, true],
          state: [``, true],
          hospital_contact_no: [``, false],
          hospital_fax_no: [``, false],
          doctor_name: [``, false],
          room_category: [``, false],
          refreshCaptcha: true,
          submitStatus: `success`,
          submitButton: false,
          captchaText: ``,
          inputCaptchaText: ``
        });
      }
    }
  };
  handleDate = (value, title) => {
    title === `admission_date` && this.setState({ admission_date: value });
    title === `accident_date` && this.setState({ accident_date: value });
  };
  getPersonalForm = () => {
    const { classes } = this.props;
    var phoneno = new RegExp(
      /^[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-/\s.]?[0-9]{4}$/
    );
    var emailRegex = new RegExp(/^\S+@\S+\.\S+$/);

    return (
      <>
        <h4 className={classes.title} style={{ paddingLeft: `0%` }}>
          Patient Information
        </h4>

        <div style={{ paddingTop: `0px`, marginTop: `0px` }}>
          <GridContainer>
            <GridItem xs={12} md={4} sm={12}>
              <CustomInput
                labelText="Customer ID"
                id="customer_id"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={
                  {
                    // required: true
                  }
                }
                onChange={this.handleChange}
                value={this.state.customer_id[0]}
                error={
                  (this.state.submitButton &&
                    this.state.customer_id[1] &&
                    this.state.customer_id[0].length <= 12 &&
                    this.state.customer_id[0].length === 0) ||
                  this.state.customer_id[0].search(`^[a-zA-Z0-9-]*$`) !== 0
                }
              />
            </GridItem>
            <GridItem xs={12} md={4} sm={12}>
              <Tooltip
                id="tooltip-left"
                title="Please enter policy number as P/123456/01/2016/123456 OR 123456789012345"
                placement="bottom"
                classes={{ tooltip: classes.tooltip }}
              >
                <CustomInput
                  labelText="Policy Number *"
                  id="policy_no"
                  formControlProps={{
                    fullWidth: true
                  }}
                  placeholder="P/123456/01/2016/123456 OR 123456789012345"
                  inputProps={
                    {
                      // required: true,
                      // pattern: `[0-9]{10}`
                    }
                  }
                  onChange={this.handleChange}
                  value={this.state.policy_no[0]}
                  error={
                    this.state.submitButton &&
                    this.state.policy_no[1] &&
                    (!policy_no_regex.test(this.state.policy_no[0]) ||
                    !policyno_new_regex.test(this.state.policy_no[0]))
                  }
                />
              </Tooltip>
            </GridItem>
            <GridItem xs={12} md={4} sm={12}>
              <CustomInput
                labelText="Caller/Intimator Name *"
                id="intimator_name"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={
                  {
                    // required: true
                  }
                }
                onChange={this.handleChange}
                value={this.state.intimator_name[0]}
                error={
                  (this.state.submitButton &&
                    this.state.intimator_name[1] &&
                    this.state.intimator_name[0].length === 0) ||
                  this.state.intimator_name[0].search(`^[a-zA-Z. ']*$`) !== 0
                }
              />
            </GridItem>
            {/* <GridItem xs={12} md={4} sm={12}>
              <CustomInput
                labelText="Caller Contact No *"
                id="intimator_contact_no"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={
                  {
                    // required: true,
                    // pattern: `[0-9]{10}`
                  }
                }
                onChange={this.handleChange}
                value={this.state.intimator_contact_no[0]}
                error={
                  (this.state.submitButton &&
                    this.state.intimator_contact_no[1] &&
                    !this.state.intimator_contact_no[0]) ||
                  (this.state.intimator_contact_no[0].length != 0 &&
                    this.state.intimator_contact_no[0].search(phoneno) !== 0)
                }
              />
              {this.state.intimator_contact_no[0].length != 0 &&
                this.state.intimator_contact_no[0].search(phoneno) !== 0 && (
                <FormHelperText id="my-helper-text" error>
                    Mobile Number is Numeric{` `}
                </FormHelperText>
              )}
            </GridItem> */}
            <GridItem xs={12} md={4} sm={12}>
              <CustomInput
                labelText="Relationship with patient *"
                id="intimator_relationship"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={
                  {
                    // required: true
                  }
                }
                onChange={this.handleChange}
                value={this.state.intimator_relationship[0]}
                error={
                  this.state.submitButton &&
                  this.state.intimator_relationship[1] &&
                  this.state.intimator_relationship[0].length === 0
                }
              />
            </GridItem>
            <GridItem xs={12} md={4} sm={12}>
              <CustomInput
                labelText="Attendee's mobile no *"
                id="attender_contact_no"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={
                  {
                    // required: true,
                    // pattern: `[0-9]{10}`
                  }
                }
                onChange={this.handleChange}
                value={this.state.attender_contact_no[0]}
                error={
                  (this.state.submitButton &&
                    this.state.attender_contact_no[1] &&
                    !this.state.attender_contact_no[0]) ||
                  (this.state.attender_contact_no[0].length != 0 &&
                    this.state.attender_contact_no[0].search(phoneno) !== 0)
                }
              />
              {this.state.attender_contact_no[0].length != 0 &&
                this.state.attender_contact_no[0].search(phoneno) !== 0 && (
                <FormHelperText id="my-helper-text" error>
                    Mobile Number is Numeric{` `}
                </FormHelperText>
              )}
            </GridItem>
            <GridItem xs={12} md={4} sm={12}>
              <CustomInput
                labelText="Name of the Proposer"
                id="proposer_name"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={
                  {
                    // required: true
                  }
                }
                onChange={this.handleChange}
                value={this.state.proposer_name[0]}
                error={
                  (this.state.submitButton &&
                    this.state.proposer_name[1] &&
                    this.state.proposer_name[0].length === 0) ||
                  this.state.proposer_name[0].search(`^[a-zA-Z. ']*$`) !== 0
                }
              />
            </GridItem>
            <GridItem xs={12} md={4} sm={12}>
              <CustomInput
                labelText="Insured Patient Name *"
                id="insured_patient_name"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={
                  {
                    // required: true
                  }
                }
                onChange={this.handleChange}
                value={this.state.insured_patient_name[0]}
                error={
                  (this.state.submitButton &&
                    this.state.insured_patient_name[1] &&
                    this.state.insured_patient_name[0].length === 0) ||
                  this.state.insured_patient_name[0].search(
                    `^[a-zA-Z. ']*$`
                  ) !== 0
                }
              />
            </GridItem>
            <GridItem xs={12} md={4} sm={12}>
              <CustomInput
                labelText="Insured E-mail *"
                id="intimator_email"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={
                  {
                    // required: true,
                    // type: `email`
                  }
                }
                onChange={this.handleChange}
                value={this.state.intimator_email[0]}
                error={
                  (this.state.submitButton &&
                    this.state.intimator_email[1] &&
                    !this.state.intimator_email[0]) ||
                  (this.state.intimator_email[0].length !== 0 &&
                    this.state.intimator_email[0].search(emailRegex) !== 0)
                }
              />
              {this.state.intimator_email[0].length != 0 &&
                this.state.intimator_email[0].search(emailRegex) !== 0 && (
                <FormHelperText id="my-helper-text" error>
                    Email Id is invalid{` `}
                </FormHelperText>
              )}
            </GridItem>
            <GridItem xs={12} md={4} sm={12}>
              <CustomInput
                labelText="Insured Contact No *"
                id="insured_contact_no"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={
                  {
                    // required: true
                  }
                }
                onChange={this.handleChange}
                value={this.state.insured_contact_no[0]}
                error={
                  (this.state.submitButton &&
                    this.state.insured_contact_no[1] &&
                    !this.state.insured_contact_no[0]) ||
                  (this.state.insured_contact_no[0].length != 0 &&
                    this.state.insured_contact_no[0].search(phoneno) !== 0)
                }
              />
            </GridItem>
          </GridContainer>
        </div>
      </>
    );
  };

  getAdmissionForm = type => {
    const { classes } = this.props;
    var dateValidtionDefault = Datetime.moment().add(
      type == `health` ? 30 : 0,
      `day`
    );
    var valid_default = function(current) {
      return current.isBefore(dateValidtionDefault);
    };
    return (
      <>
        <h4 className={classes.title} style={{ paddingLeft: `0%` }}>
          Admission Information
        </h4>

        <GridContainer>
          <GridItem xs={12} md={4} sm={12}>
            <CustomInput
              labelText="Reason For Admission *"
              id="reason"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={
                {
                  // required: true
                }
              }
              onChange={this.handleChange}
              value={this.state.reason[0]}
              error={
                this.state.submitButton &&
                this.state.reason[1] &&
                this.state.reason[0].length === 0
              }
            />
          </GridItem>
          <GridItem xs={12} md={4} sm={12}>
            <FormControl fullWidth style={{ marginTop: `11px` }}>
              <Datetime
                //form-control is a mandatory class of Datetime. It is required to include it. The class is defined inside datetime package
                style={{ marginTop: `2px`, color: `#999` }}
                timeFormat={false}
                id="admission_date"
                labelText="Hospital Admission Date"
                inputProps={{
                  className: `form-control ${
                    this.state.submitButton &&
                    this.state.admission_date.length == 0
                      ? classes.dateTimeError
                      : ``
                  }`,
                  placeholder: `Hospital Admission Date *`,
                  readOnly: true
                  // required: true
                }}
                className={`${classes.description} `}
                value={this.state.admission_date}
                onChange={moment =>
                  this.handleDate(moment.format(`DD/MM/YYYY`), `admission_date`)
                }
                isValidDate={valid_default}
                closeOnSelect={true}
              />
            </FormControl>
          </GridItem>
          <GridItem xs={12} md={4} sm={12}>
            <CustomInput
              labelText="Comments"
              id="comments"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={
                {
                  // required: true
                }
              }
              onChange={this.handleChange}
              value={this.state.comments[0]}
              error={
                this.state.submitButton &&
                this.state.comments[1] &&
                this.state.comments[0].length === 0
              }
            />
          </GridItem>
        </GridContainer>
      </>
    );
  };
  getHospitalForm = () => {
    const { classes } = this.props;
    return (
      <>
        <h4 className={classes.title} style={{ paddingLeft: `0%` }}>
          Hospital Information
        </h4>
      
        <GridContainer>
          <div style={{ marginLeft: `30%`, marginTop: `3%` }}>
            Hospital Type
          </div>
          <GridItem
            xs={12}
            md={6}
            sm={12}
            style={{ marginLeft: `3%`, marginTop: `2%` }}
            className={classes.radiobuttonclaimfix}
          >
            Network
            <Radio
              checked={this.state.selectedEnabled === `network`}
              onChange={this.handleChangeEnabled}
              value="network"
              name="hospital_type"
              aria-label="Network"
              icon={<FiberManualRecord className={classes.radioUnchecked} />}
              checkedIcon={
                <FiberManualRecord className={classes.radioChecked} />
              }
              classes={{
                checked: classes.radio,
                root: classes.radioRoot
              }}
            />
            Non Network
            <Radio
              checked={this.state.selectedEnabled === `non-network`}
              onChange={this.handleChangeEnabled}
              value="non-network"
              name="hospital_type"
              aria-label="Non Network"
              icon={<FiberManualRecord className={classes.radioUnchecked} />}
              checkedIcon={
                <FiberManualRecord className={classes.radioChecked} />
              }
              classes={{
                checked: classes.radio,
                root: classes.radioRoot
              }}
            />
          </GridItem> 
          <GridItem xs={12} md={4} sm={12}>
            <CustomInput
              labelText="Hospital Name *"
              id="hospital_name"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={
                {
                  // required: true
                }
              }
              onChange={this.handleChange}
              value={this.state.hospital_name[0]}
              error={
                (this.state.submitButton &&
                  this.state.hospital_name[1] &&
                  this.state.hospital_name[0].length === 0) ||
                this.state.hospital_name[0].search(`^[a-zA-Z. ']*$`) !== 0
              }
            />
          </GridItem>
          <GridItem xs={12} md={4} sm={12}>
            <CustomInput
              labelText="Hospital Address *"
              id="hospital_address"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={
                {
                  // required: true
                }
              }
              onChange={this.handleChange}
              value={this.state.hospital_address[0]}
              error={
                this.state.submitButton &&
                this.state.hospital_address[1] &&
                this.state.hospital_address[0].length === 0
              }
            />
          </GridItem>
          <GridItem xs={12} md={4} sm={12}>
              <CustomInput
                labelText="State *"
                id="state"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={
                  {
                  // required: true
                  }
                }
                onChange={this.handleChange}
                value={this.state.state[0]}
                error={
                  this.state.submitButton &&
                this.state.state[1] &&
                this.state.state[0].length === 0
                }
              />
          </GridItem>
          <GridItem xs={12} md={4} sm={12}>
            <CustomInput
              labelText="Hospital Phone No"
              id="hospital_contact_no"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={
                {
                  // required: true,
                  // pattern: `[0-9]{10}`
                }
              }
              onChange={this.handleChange}
              value={this.state.hospital_contact_no[0]}
              error={
                this.state.submitButton &&
                this.state.hospital_contact_no[1] &&
                !this.state.hospital_contact_no[0]
              }
            />
          </GridItem>
          <GridItem xs={12} md={4} sm={12}>
            <CustomInput
              labelText="Hospital Fax No"
              id="hospital_fax_no"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={
                {
                  // required: true,
                  // pattern: `[0-9]{10}`
                }
              }
              onChange={this.handleChange}
              value={this.state.hospital_fax_no[0]}
              error={
                this.state.submitButton &&
                this.state.hospital_fax_no[1] &&
                this.state.hospital_fax_no[0].length === 0
              }
            />
          </GridItem>
          <GridItem xs={12} md={4} sm={12}>
            <CustomInput
              labelText="Hospital Doctor Name"
              id="doctor_name"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={
                {
                  // required: true
                }
              }
              onChange={this.handleChange}
              value={this.state.doctor_name[0]}
              error={
                (this.state.submitButton &&
                  this.state.doctor_name[1] &&
                  this.state.doctor_name[0].length === 0) ||
                this.state.doctor_name[0].search(`^[a-zA-Z. ']*$`) !== 0
              }
            />
          </GridItem>
          <GridItem xs={12} md={4} sm={12}>
            <CustomInput
              labelText="Room category/Room number"
              id="room_category"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={
                {
                  // required: true
                }
              }
              onChange={this.handleChange}
              value={this.state.room_category[0]}
              error={
                this.state.submitButton &&
                this.state.room_category[1] &&
                this.state.room_category[0].length === 0
              }
            />
          </GridItem>
        </GridContainer>
      </>
    );
  };

  getaccidentForm = () => {
    const { classes } = this.props;
    var dateValidtion = Datetime.moment().add(0, `day`);
    var valid_default = function(current) {
      return current.isBefore(dateValidtion);
    };
    return (
      <>
        <h4 className={classes.title}>Accident Information</h4>

        <GridContainer>
          <GridItem xs={12} md={4} sm={12}>
            <FormControl
              fullWidth
              style={{ marginTop: `11px` }}
              error={
                this.state.submitButton &&
                this.state.accident_date &&
                this.state.accident_date.length === 0
              }
            >
              <Datetime
                style={{ marginTop: `3px`, color: `#999` }}
                timeFormat={false}
                id="accident_date"
                labelText="Date of Accident"
                inputProps={{
                  className: `form-control ${
                    this.state.submitButton &&
                    this.state.accident_date.length == 0
                      ? classes.dateTimeError
                      : ``
                  }`,
                  placeholder: `Date of Accident *`,
                  readOnly: true
                  // required: true
                }}
                onChange={moment =>
                  this.handleDate(moment.format(`DD/MM/YYYY`), `accident_date`)
                }
                isValidDate={valid_default}
                value={this.state.accident_date}
                closeOnSelect={true}
              />
            </FormControl>
          </GridItem>
          <GridItem xs={12} md={4} sm={12}>
            <CustomInput
              labelText="Place of Accident *"
              id="accident_place"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={
                {
                  // required: true
                }
              }
              onChange={this.handleChange}
              value={this.state.accident_place[0]}
              error={
                this.state.submitButton &&
                this.state.accident_place[1] &&
                this.state.accident_place[0].length === 0
              }
            />
          </GridItem>
          <GridItem xs={12} md={4} sm={12}>
            <CustomInput
              labelText="FIR Details *"
              id="fir_details"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={
                {
                  // required: true
                }
              }
              onChange={this.handleChange}
              value={this.state.fir_details[0]}
              error={
                this.state.submitButton &&
                this.state.fir_details[1] &&
                this.state.fir_details[0].length === 0
              }
            />
          </GridItem>
        </GridContainer>
      </>
    );
  };

  getGeneralForm = () => {
    const { classes } = this.props;
    return (
      <GridContainer>
        <GridItem md={12} style={{ paddingTop: 27 }}>
          <Captcha
            fullWidth="true"
            nextLine={true}
            refresh={this.state.refreshCaptcha}
            updateRefresh={this.updateRefreshCaptcha}
            submitButton={this.state.submitButton}
            setCaptchaText={this.setCaptchaText}
          />
        </GridItem>
        <GridItem md={12} style={{ paddingTop: 27 }}>
          <Button
            round
            className={`${classes.buttonfixintimation} ${classes.textCenter} `}
            style={{ backgroundColor: `#35488a`, float: `left` }}
            type={`submit`}
          >
            Submit
          </Button>
        </GridItem>
      </GridContainer>
    );
  };

  swapMonthAndDate(date) {
    date = date.split(`/`);
    let temp = date[0];
    date[0] = date[1];
    date[1] = temp;
    return date.join(`/`);
  }

  checkDateOnLoadAndUpdate(intimation_type) {
    var validTill = Datetime.moment()
      .add(intimation_type == `health` ? 30 : 0, `day`)
      .format(`DD/MM/YYYY`);
    if (
      new Date(this.swapMonthAndDate(this.state.admission_date)) >
      new Date(this.swapMonthAndDate(validTill))
    ) {
      this.setState({
        admission_date: ``
      });
    }
  }

  componentDidMount() {
    this.state.admission_date != `` &&
      this.checkDateOnLoadAndUpdate(this.state.intimation_type);
  }

  componentDidUpdate() {
    this.state.admission_date != `` &&
      this.checkDateOnLoadAndUpdate(this.state.intimation_type);
  }

  render() {
    const { classes } = this.props;
    const health={
      tabButton: `Health`,
      navigateURL: `/claims`,
      tabContent: (
        <form
          className={`${classes.form}`}
          style={{ width: `100%` }}
          onSubmit={this.handleSubmit}
        >
          {this.getPersonalForm()}
          {this.getAdmissionForm(`health`)}
          {this.getHospitalForm()}
          {this.getGeneralForm()}
        </form>
      )
    }
    const accident={
      tabButton: `Accident`,
      tabContent: (
        <form
          className={classes.form}
          style={{ width: `100%` }}
          onSubmit={this.handleSubmit}
        >
          {this.getPersonalForm()}
          {this.getaccidentForm()}
          {this.getAdmissionForm(`accident`)}
          {this.getHospitalForm()}
          {this.getGeneralForm()}
        </form>
      )
    }
    const tabs= [health,accident];
    return (
      <GridContainer
        item
        md={10}
        className={classNames(classes.mrAuto, classes.mlAuto)}
        style={{ paddingTop: `70px` }}
      >
        <div
          className={classNames(classes.main)}
          style={{ marginBottom: 100, width: `100%` }}
        >
          <div className={`${classes.section} `} style={{ padding: `0px` }}>
            <div className={`${classes.container} ${classes.paddingfixclaim}`}>
              {this.state.submitStatus === `success` && (
                <CustomSnackbar
                  open={true}
                  duration={6000}
                  status={`success`}
                  message={`Successfully submitted! We will get in touch with you soon `}
                  flag={this.handleSubmitStatus}
                  style={{ position: `absolute` }}
                />
              )}

              {this.state.submitStatus === `error` && (
                <CustomSnackbar
                  open={true}
                  duration={6000}
                  status={`error`}
                  message={`We are currently facing issues, please try again later`}
                  flag={this.handleSubmitStatus}
                  style={{ position: `absolute` }}
                />
              )}
              <NavPills
               active={1}
                tabs={tabs}
                title={this.handleaccident}
              />
            </div>
          </div>
        </div>
      </GridContainer>
    );
  }
}

export default withStyles(claimListStyle)(claimsIntimation);

claimsIntimation.propTypes = {
  classes: PropTypes.object
};
