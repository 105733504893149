import {
  grayColor,
  whiteColor,
  mlAuto,
  mrAuto,
  hexToRgb,
  primaryColor,
  blackColor,
  cardTitle,
  description,
  title,
} from "assets/jss/material-kit-pro-react.jsx";
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.jsx";
import tooltip from "assets/jss/material-kit-pro-react/tooltipsStyle.jsx";
import { topNavIcons } from "assets/jss/material-kit-pro-react/components/topNavHeaderLinksStyle";

export const showExtendedMenu = {
  maxHeight: "unset !important",
  minHeight: "350px !important",
  zIndex: "1101 !important",
};

const headerLinksStyle = (theme) => ({
  ...modalStyle(theme),
  topNavIcons,
  cardTitle,
  description,
  title,
  list: {
    [theme.breakpoints.up("lg")]: {
      WebkitBoxAlign: "center",
      MsFlexAlign: "center",
      alignItems: "center",
      WebkitBoxOrient: "horizontal",
      WebkitBoxDirection: "normal",
      MsFlexDirection: "row",
      flexDirection: "row",
    },
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
    marginTop: "0px",
    display: "flex",
    paddingLeft: "0",
    marginBottom: "0",
    listStyle: "none",
    padding: "0",
    "& h6": {
      textTransform: "capitalize",
      marginBottom: "0",
      fontSize: "bold",
    },
  },
  accordioDropdownLink: {
    color: blackColor,
    textDecoration: "none",
    display: "flex",
    padding: "0.75rem 1.25rem 0.75rem 0.75rem",
    paddingLeft: "0px",
    "&:hover,&:focus": {
      color: blackColor,
    },
  },
  wellnessTelemed: {
    width: "38px",
    height: "21px",
    marginRight: "-8px",
    paddingLeft: "7px",
    marginTop: "2%",
    "@media (max-width: 780px)": {
      marginRight: "5px",
    },
  },
  customeraccordioDropdownLink: {
    color: blackColor,
    textDecoration: "none",
    display: "flex",
    padding: "0.15rem 1.35rem 0.75rem 0.75rem",
    paddingLeft: "0%",
    "&:hover,&:focus": {
      color: "#35488a",
      background: "#ebebeb",
    },
  },
  modal: {
    maxWidth: '1000px',
    margin: '0px !important',
    width: '100% !important',
    borderRadius: '10px',
    overflow: 'hidden',
  },
  accordioDropdownLinks: {
    color: blackColor,
    textDecoration: "none",
    display: "flex",
    padding: "0.75rem 1.25rem 0.75rem 0.75rem",
    "&:hover,&:focus": {
      color: blackColor,
    },
  },
  listItem: {
    float: "left",
    color: "inherit",
    position: "relative",
    display: "block",
    width: "fit-content",
    margin: "0",
    padding: "0",
    [theme.breakpoints.down("md")]: {
      "& ul": {
        maxHeight: "450px",
        overflow: "hidden",
      },
      width: "100%",
      "&:not(:last-child)": {
        "&:after": {
          width: "calc(100% - 30px)",
          content: '""',
          display: "block",
          height: "1px",
          marginLeft: "15px",
          backgroundColor: grayColor[14],
        },
      },
    },
  },
  listItemText: {
    padding: "0 !important",
  },
  navLink: {
    color: "#000 !important",
    position: "relative",
    padding: "0.5rem",
    fontWeight: "400",
    fontSize: "0.8465rem",
    textTransform: "none",
    lineHeight: "20px",
    textDecoration: "none",
    margin: "0px",
    display: "inline-flex",
    "& span div:after": {
      content: "''",
      position: "absolute",
      zIndex: "1",
      left: "50%",
      right: "50%",
      bottom: "0",
      backgroundColor: primaryColor[0],
      height: "4px",
      transitionProperty: "left, right",
      transitionDuration: "0.3s",
      transitionTimingFunction: "ease-out",
    },
    "&:hover span div:after": {
      left: "0",
      right: "0",
    },
    "& .fab,& .far,& .fal,& .fas,& .material-icons": {
      position: "relative",
      top: "2px",
      marginTop: "-4px",
      marginRight: "4px",
      marginBottom: "0px",
      fontSize: "1.25rem",
    },
    [theme.breakpoints.down("md")]: {
      width: "calc(100% - 30px)",
      marginLeft: "15px",
      marginBottom: "8px",
      marginTop: "8px",
      textAlign: "left",
      "& > span:first-child": {
        justifyContent: "flex-start",
      },
      "&:hover span div:after": {
        content: "",
        left: "51%",
        right: "51%",
      },
    },
    "& svg": {
      marginRight: "3px",
      width: "20px",
      height: "20px",
    },
  },
  navLinkHeader: {
    color: "#000 !important",
    position: "relative",
    padding: "0.9375rem",
    fontWeight: "400",
    fontSize: "0.8465rem",
    textTransform: "none",
    lineHeight: "20px",
    textDecoration: "none",
    margin: "0px",
    display: "inline-flex",
    "@media (max-width: 576px)": {
      padding: "0.4375rem",
    },
    "& span div:after": {
      content: "''",
      position: "absolute",
      zIndex: "1",
      left: "50%",
      right: "50%",
      bottom: "0",
      backgroundColor: primaryColor[0],
      height: "4px",
      transitionProperty: "left, right",
      transitionDuration: "0.3s",
      transitionTimingFunction: "ease-out",
    },
    "&:hover span div:after": {
      left: "0",
      right: "0",
    },
    "& .fab,& .far,& .fal,& .fas,& .material-icons": {
      position: "relative",
      top: "2px",
      marginTop: "-4px",
      marginRight: "4px",
      marginBottom: "0px",
      fontSize: "1.25rem",
    },
    [theme.breakpoints.down("md")]: {
      width: "calc(100% - 30px)",
      marginLeft: "15px",
      marginBottom: "8px",
      marginTop: "8px",
      textAlign: "left",
      "& > span:first-child": {
        justifyContent: "flex-start",
      },
      "&:hover span div:after": {
        content: "",
        left: "51%",
        right: "51%",
      },
    },
    "& svg": {
      marginRight: "3px",
      width: "20px",
      height: "20px",
    },
  },
  innerMenu: {
    marginTop: "-8.5% !important",
    width: "100% !important",
    marginLeft: "16% !important",
    background: "white !important",
    textAlign: "left !important",
  },
  outerMenu: {
    marginTop: `-6% !important`,
    width: "100% !important",
    background: "white !important",
    textAlign: "left !important",
  },
  customNavlink: {
    padding: "0.9375rem",
  },
  navLinkJustIcon: {
    "& .fab,& .far,& .fal,& .fas,& .material-icons": {
      marginRight: "0px",
    },
    "& svg": {
      marginRight: "0px",
    },
  },
  navButton: {
    position: "relative",
    fontWeight: "400",
    fontSize: "0.8465rem",
    textTransform: "none",
    lineHeight: "20px",
    textDecoration: "none",
    margin: "0px",
    display: "inline-flex",
    [theme.breakpoints.down("md")]: {
      width: "calc(100% - 30px)",
      marginLeft: "15px",
      marginBottom: "5px",
      marginTop: "5px",
      textAlign: "left",
      "& > span:first-child": {
        justifyContent: "flex-start",
      },
    },
    "& $icons": {
      marginRight: "3px",
    },
  },
  notificationNavLink: {
    color: "inherit",
    padding: "0.9375rem",
    fontWeight: "400",
    fontSize: "0.8465rem",
    textTransform: "none",
    lineHeight: "20px",
    textDecoration: "none",
    margin: "0px",
    display: "inline-flex",
  },
  registerNavLink: {
    position: "relative",
    fontWeight: "400",
    fontSize: "0.8465rem",
    textTransform: "none",
    lineHeight: "20px",
    textDecoration: "none",
    margin: "0px",
    display: "inline-flex",
  },
  navLinkActive: {
    "&, &:hover, &:focus,&:active ": {
      color: "inherit",
      backgroundColor: "rgba(" + hexToRgb(whiteColor) + ", 0.1)",
    },
  },
  icons: {
    width: "20px",
    height: "20px",
    marginRight: "14px",
  },
  dropdownIcons: {
    width: "24px",
    height: "24px",
    marginRight: "14px",
    opacity: "0.5",
    marginTop: "-4px",
    top: "1px",
    verticalAlign: "middle",
    fontSize: "24px",
    position: "relative",
  },
  socialIcons: {
    position: "relative",
    fontSize: "1.25rem",
    maxWidth: "24px",
  },
  dropdownLink: {
    "&,&:hover,&:focus": {
      color: "inherit",
      textDecoration: "none",
      display: "flex",
      padding: "0.75rem 1.25rem 0.75rem 0.75rem",
    },
  },
  appdropdownLink: {
    fontSize: "15px",
    "&,&:hover,&:focus": {
      color: "#35488a",
      textDecoration: "none",
      display: "flex",
      padding: "0.75rem 1.25rem 0.75rem 0.75rem",
    },
  },
  ...tooltip,
  marginRight5: {
    marginRight: "5px",
  },
  collapse: {
    [theme.breakpoints.up("lg")]: {
      display: "flex !important",
      MsFlexPreferredSize: "auto",
      flexBasis: "auto",
    },
    WebkitBoxFlex: "1",
    MsFlexPositive: "1",
    flexGrow: "1",
    WebkitBoxAlign: "center",
    MsFlexAlign: "center",
    alignItems: "center",
  },
  mrAuto,
  mlAuto,
  subLabel: {
    color: "#3C4858",
    marginTop: `-5px`,
  },
  promotionLabel: {
    color: primaryColor[0],
    fontWeight: "bold",
  },
  divider: {
    borderRight: "solid thin #ddd",
    height: "calc(100% - 30px)",
    marginTop: "10px",
    paddingRight: "0",
  },
  customSvg: {
    "& svg": {
      width: "100%",
      height: "100%",
    },
  },
  labelPink: {
    margin: `0px`,
    textTransform: `capitalize !important`,
    padding: `0px`,
    "& span div:after": {
      content: `''`,
      position: `absolute`,
      zIndex: `1`,
      left: `50%`,
      right: `50%`,
      bottom: `0`,
      backgroundColor: `#E91E63 !important`,
      height: `2px`,
      transitionProperty: `left, right`,
      transitionDuration: `0.3s`,
      transitionTimingFunction: `ease-out`,
    },
    "&:hover span div:after": {
      left: `0`,
      right: `0`,
    },
  },
  labelYellow: {
    margin: `0px`,
    textTransform: `capitalize !important`,
    padding: `0px`,
    "& span div:after": {
      content: `''`,
      position: `absolute`,
      zIndex: `1`,
      left: `50%`,
      right: `50%`,
      bottom: `0`,
      backgroundColor: `#FF9800 !important`,
      height: `2px`,
      transitionProperty: `left, right`,
      transitionDuration: `0.3s`,
      transitionTimingFunction: `ease-out`,
    },
    "&:hover span div:after": {
      left: `0`,
      right: `0`,
    },
  },
  labelPurple: {
    margin: `0px`,
    textTransform: `capitalize !important`,
    padding: `0px`,
    "& span div:after": {
      content: `''`,
      position: `absolute`,
      zIndex: `1`,
      left: `50%`,
      right: `50%`,
      bottom: `0`,
      backgroundColor: `#35488a !important`,
      height: `2px`,
      transitionProperty: `left, right`,
      transitionDuration: `0.3s`,
      transitionTimingFunction: `ease-out`,
    },
    "&:hover span div:after": {
      left: `0`,
      right: `0`,
    },
  },
  labelOrange: {
    margin: `0px`,
    textTransform: `capitalize !important`,
    padding: `0px`,
    "& span div:after": {
      content: `''`,
      position: `absolute`,
      zIndex: `1`,
      left: `50%`,
      right: `50%`,
      bottom: `0`,
      backgroundColor: `#F44336 !important`,
      height: `2px`,
      transitionProperty: `left, right`,
      transitionDuration: `0.3s`,
      transitionTimingFunction: `ease-out`,
    },
    "&:hover span div:after": {
      left: `0`,
      right: `0`,
    },
  },
  labelBlue: {
    margin: `0px`,
    textTransform: `capitalize !important`,
    padding: `0px`,
    backgroundColor: `transparent !important`,
    "& span div:after": {
      content: `''`,
      position: `absolute`,
      zIndex: `1`,
      left: `50%`,
      right: `50%`,
      bottom: `0`,
      backgroundColor: `#35488a !important`,
      height: `2px`,
      transitionProperty: `left, right`,
      transitionDuration: `0.3s`,
      transitionTimingFunction: `ease-out`,
    },
    "&:hover span div:after": {
      left: `0`,
      right: `0`,
    },
    "&:focus": {
      backgroundColor: `transparent !important`,
    },
  },
  ICMRProtocolPosition: {
    paddingBottom: "2.5%",
    "@media (max-width: 576px)": {
      paddingBottom: "7.3%",
    },
  },
  headerSix: {
    margin: `0px`,
    textTransform: `capitalize !important`,
    marginTop: `9px`,
  },
  headerSeven: {
    margin: `0px`,
    textTransform: `capitalize !important`,
    marginTop: `7px`,
  },
  storeIcons: {
    marginBottom: `auto`,
    marginLeft: `auto`,
    marginRight: `auto`,
    [theme.breakpoints.down(`md`)]: {
      marginTop: `5%`,
    },
  },
  covidScore: {
    width: `auto`,
    color: `white !important`,
    padding: `0.35rem 1.2375rem`,
    [theme.breakpoints.down(`md`)]: {
      padding: `5%`,
    },
  },
  storeIconsMargins: {
    marginTop: `11%`,
  },
  showExtendedMenu,
  pointer: {
    cursor: "pointer",
  },
  mtAuto: {
    marginTop: "auto",
  },
  iconSpacing: {
    paddingLeft: `8px`,
    paddingRight: `8px`,
  },
  extendedHeaderHeadings: {
    display: `flex`,
    "& div": {
      width: `25px`,
      height: `25px`,
      marginTop: `auto`,
      marginBottom: `auto`,
    },
    "& h4": {
      marginLeft: `2%`,
    },
  },
  selfServiceContainer: {
    [theme.breakpoints.down(`xs`)]: {
      marginTop: `6%`,
    },
  },
  textCenter: {
    textAlign: `center`,
  },
  downloadButton: {
    height: `50px !important `,
    width: `50px !important`,
    "& svg": {
      float: `left`,
      height: `25px !important`,
      width: `25px !important`,
    },
  },
  selfServiceTitle: {
    marginBottom: `10px`,
  },
  noPolicies: {
    marginTop: `20px`,
    "& h4": {
      marginLeft: `30px`,
      marginRight: `30px`,
    },
  },
  policyContainer: {
    marginTop: `20px`,
  },
  datePicker: {
    marginTop: `20px`,
  },
  errorMessageStyle: {
    "& p": {
      marginTop: `25px`,
      marginBottom: `10px`,
      textAlign: `center`,
    },
  },
  basicButtons: {
    marginTop: `20px`,
    marginBottom: `20px`,
    "& div": {
      textAlign: `center`,
    },
  },
  actionButton: {
    [theme.breakpoints.up(`sm`)]: {
      marginTop: `auto`,
      marginBottom: `auto`,
    },
  },
  dateFeild: {
    marginTop: `10%`,
    [theme.breakpoints.up(`sm`)]: {
      marginTop: `4%`,
    },
  },
  loading: {
    marginTop: `20px`,
  },
  lineAfter: {
    [theme.breakpoints.down("md")]: {
      position: "releative",
      "&:after": {
        width: "calc(100% - 30px)",
        content: '""',
        display: "block",
        height: "1px",
        marginLeft: "15px",
        backgroundColor: grayColor[14],
      },
    },
  },
  lineBefore: {
    [theme.breakpoints.down("md")]: {
      "&:before": {
        width: "calc(100% - 30px)",
        content: '""',
        display: "block",
        height: "1px",
        marginLeft: "15px",
        backgroundColor: grayColor[14],
      },
    },
  },
});

export default headerLinksStyle;
