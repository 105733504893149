import {browserLocalStorage} from "util/localStorageHelper";
const CUSTOMER_PORTAL_API_URL = process.env.CUSTOMER_PORTAL_API_URL;
const APP_VERSION = process.env.APP_VERSION;
async function handleResponse(response) {
    if (response.ok) {
      const claimsDetails = await response.json();
      return claimsDetails;
    }
    if (response.status === 400) {
      const error = await response.json();
      console.log(error);
      return error;
    }
    if (response.status === 404) {
      const error = await response.json();
      console.log(error);
      if (error.details[0] === 'No claims linked to this user') {
        return [];
      }
      throw error;
    }
    if (response.status === 409) {
      const error = await response.json();
      console.log(error);
      if (error && error.message == 'ERR_DUPLICATE_INTIMATION')
        return { status: 'duplicate' };
      if (error.message === 'Resource Already Exists') {
        return [];
      }
      throw error;
    }
    throw new Error('Invalid response from API');
  }
export function searchIntimationHealthIdcardAPI(body) {
  let token = body.token
  browserLocalStorage.setItem(`getClaimToken${body.claimIntimation}`, token );
  let headers = {
    clientId: `7X2hEMm7YlhRXb7S3fjYs4GHXTeroWUr`,
    Authorization: `Bearer ${token}`,
  }
    const uploadUrl = `${CUSTOMER_PORTAL_API_URL}/cp/claim/check/status?claimNo=${body.claimIntimation}&healthId=${body.healthId}`
    return fetch(uploadUrl, {
        method:`GET`,
        headers: headers,
    })
      .then((res) => res.json())
      .then((res) => {
        browserLocalStorage.setItem(`getPolicyToken${res.data[0].policyNo}`, res.data[0].policyToken );
        return res;
      })
      .catch((err) => {
        return err;
      });
  }
  export function searchIntimationAPI(claimNo,mobileNoToken) {
    let token = mobileNoToken
    const uploadUrl = `${CUSTOMER_PORTAL_API_URL}/cp/claim/check/validate?claimNo=${claimNo}`
    let headers = {
      clientId: `7X2hEMm7YlhRXb7S3fjYs4GHXTeroWUr`,
      Authorization: `Bearer ${token}`,
    }
    return fetch(uploadUrl, {
        method:`GET`,
        headers: headers,
    })
      .then((res) => res.json())
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  }
  export function searchOPAPI(claimNo,healthId) {
    const uploadUrl = `${CUSTOMER_PORTAL_API_URL}/cp/claim/check/validate?claimNo=${claimNo}&healthId=${healthId}`
    return fetch(uploadUrl, {
        method:`GET`,
        headers:{clientId: `7X2hEMm7YlhRXb7S3fjYs4GHXTeroWUr`},
    })
      .then((res) => res.json())
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  }
  export function searchHealthIdcardAPI(healthId,token) {
    const uploadUrl = `${CUSTOMER_PORTAL_API_URL}/cp/claim/check/validate?healthId=${healthId}`
    let headers = {
      clientId: `7X2hEMm7YlhRXb7S3fjYs4GHXTeroWUr`,
      Authorization: `Bearer ${token}`,
    }
    return fetch(uploadUrl, {
        method:`GET`,
        headers:headers,
    })
      .then((res) => res.json())
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  }
  export function searchPolicyNumberAPI(policyNumber) {
    const uploadUrl = `${CUSTOMER_PORTAL_API_URL}/cp/claim/check/validate?policyNumber=${policyNumber}`
    return fetch(uploadUrl, {
        method:`GET`,
        headers:{clientId: `7X2hEMm7YlhRXb7S3fjYs4GHXTeroWUr`},
    })
      .then((res) => res.json())
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  }
  export function searchPolicyNumberAPIGroup(policyNumber,healthId) {
    const uploadUrl = `${CUSTOMER_PORTAL_API_URL}/cp/claim/check/validate?policyNumber=${policyNumber}&healthId=${healthId}`
    return fetch(uploadUrl, {
        method:`GET`,
        headers:{clientId: `7X2hEMm7YlhRXb7S3fjYs4GHXTeroWUr`},
    })
      .then((res) => res.json())
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  }
  // claims security fix api change
  export async function searchIntimationAPIPost(claimNo) {
    const uploadUrl = `${CUSTOMER_PORTAL_API_URL}/cp/claim/check/exists?claimNo=${claimNo}`
      return fetch(uploadUrl, {
        method:`POST`,
        headers:{clientId: `7X2hEMm7YlhRXb7S3fjYs4GHXTeroWUr`},
    })
      .then((res) =>  res.json())
      .then((res) => {
        return res
      })
      .catch((err) => {
        return err;
      });
  }
  export function searchHealthIdcardAPIPost(healthId) {
    const uploadUrl = `${CUSTOMER_PORTAL_API_URL}/cp/claim/check/exists?healthId=${healthId}`
    return fetch(uploadUrl, {
        method:`POST`,
        headers:{clientId: `7X2hEMm7YlhRXb7S3fjYs4GHXTeroWUr`},
    })
      .then((res) => res.json())
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  }
  export function searchPolicyNumberAPIPost(policyNumber) {
    const uploadUrl = `${CUSTOMER_PORTAL_API_URL}/cp/claim/check/exists?policyNumber=${policyNumber}`
    return fetch(uploadUrl, {
        method:`POST`,
        headers:{clientId: `7X2hEMm7YlhRXb7S3fjYs4GHXTeroWUr`},
    })
      .then((res) => res.json())
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  }
  export function searchPolicyNumberAPIGroupPost(policyNumber,healthId) {
    const uploadUrl = `${CUSTOMER_PORTAL_API_URL}/cp/claim/check/exists?policyNumber=${policyNumber}&healthId=${healthId}`
    return fetch(uploadUrl, {
        method:`POST`,
        headers:{clientId: `7X2hEMm7YlhRXb7S3fjYs4GHXTeroWUr`},
    })
      .then((res) => res.json())
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  }
  export function searchOPAPIPost(body) {
    const uploadUrl = `${CUSTOMER_PORTAL_API_URL}/cp/claim/check/exists?claimNo=${body.intimationNumber}&healthId=${body.healthId}&category=${body.category}`
    return fetch(uploadUrl, {
        method:`POST`,
        headers:{clientId: `7X2hEMm7YlhRXb7S3fjYs4GHXTeroWUr`},
    })
      .then((res) => res.json())
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  }
  export function searchMobileNumberAPI(mobileNumber) {
    const uploadUrl = `${CUSTOMER_PORTAL_API_URL}/api/portal/policy/user/get/policies/${mobileNumber}`
    return fetch(uploadUrl, {
        method:`GET`,
        headers:{clientId: `7X2hEMm7YlhRXb7S3fjYs4GHXTeroWUr`},
    })
      .then((res) => res.json())
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  }
  export function listClaimsByPolicy(policyNo) {
    const getClaimsListUrl = `${CUSTOMER_PORTAL_API_URL}/cp/claim/list/policy`;
    return fetch(getClaimsListUrl, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        clientId: 'WEB',
        appVersion: APP_VERSION,
      },
      body: JSON.stringify(policyNo),
    })
      .then(handleResponse)
      .catch((err) => {
        return err;
      });
  }
  export function generateOtp(data){
    const uploadUrl = `${CUSTOMER_PORTAL_API_URL}/cp/claim/generate/otp`
    return fetch(uploadUrl, {
        method:`POST`,
        headers:{clientId: `7X2hEMm7YlhRXb7S3fjYs4GHXTeroWUr`},
        body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  }

  export function validateOPStatus(body) {
    const uploadUrl = `${CUSTOMER_PORTAL_API_URL}/cp/claim/check/status?claimNo=${body.intimationNumber}&healthId=${body.idCardNumber}&category=${body.currentClaims.toUpperCase()}`
    let headers = {clientId: `7X2hEMm7YlhRXb7S3fjYs4GHXTeroWUr`,
    Authorization: `Bearer ${body.tokenNo}`,
    }
    return fetch(uploadUrl, {
        method:`GET`,
        headers: headers,
    })
      .then((res) => res.json())
      .then((res) => {
        browserLocalStorage.setItem(`getPolicyToken${res.data[0].policyNo}`, res.data[0].policyToken );
        return res;
      })
      .catch((err) => {
        return err;
      });
  }

  export function getQueryStatus(body) {
    const uploadUrl = `${CUSTOMER_PORTAL_API_URL}/cp/claim/check/queries?claimNo=${body}`
    return fetch(uploadUrl, {
        method:`GET`,
        headers:{clientId: `7X2hEMm7YlhRXb7S3fjYs4GHXTeroWUr`},
    })
      .then((res) => res.json())
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  }

  export function clearExpiredMobileTokens(expiryTimeInMilliSecs = 60 * 60 * 24 * 1000) {
    try{
      const mobileTokenNames = ['instantRenewal','payEmiToken','autoDebitEnachMobileToken','submitCkycMobileToken','linkPolicy','getClaimToken','getPolicyToken'];
      mobileTokenNames.forEach((tokenName) => {
        Object.keys(browserLocalStorage).forEach((key) => {
          //check if localstorage key starts with token name
          if (key.startsWith(tokenName)) {
            //get token timestamp
            const tokenObj = browserLocalStorage.getItem(key);
            const tokenTimeStamp = tokenObj ? JSON.parse(tokenObj).timestamp : null;
      
            //if no timestamp available - remove key from local
            if(!tokenTimeStamp) {
              browserLocalStorage.removeItem(key);
              return;
            }
      
            //compare with current timestamp
            const differenceInMilliSecs = Date.now() - tokenTimeStamp;
      
            //if greater than expiryTimeInMilliSecs - remove token
            if(differenceInMilliSecs > expiryTimeInMilliSecs) browserLocalStorage.removeItem(key);
          }
        })
      })
    } catch(error) {
      console.log(error);
    }
    
    
  }