import React from "react";
// import { Link } from "react-router-dom";
//  import logo from "ps-assets/img/logo.png";
// import CustomHeaderLinks from "ps-components/Header/CustomHeaderLinks.jsx";
// import TopNavHeaderLinks from "ps-components/Header/TopNavHeaderLinks.jsx";
// import ExtendedHeader from "ps-components/Header/ExtendedHeader.jsx";
// import Header from "components/Header/Header.jsx";
import { Helmet } from "react-helmet";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
// import CallComponent from "components/CallComponent";
import withStyles from "@material-ui/core/styles/withStyles";
import layoutStyle from "ps-assets/jss/layoutStyle.jsx";
import Parallax from "components/Parallax/Parallax";
import classNames from "classnames";
import feedbackIcon from "ps-assets/img/feedback.png";
// import PSFooter from "ps-components/Footer/PSFooter";
// import { withPrefix } from "gatsby";
import PropTypes from "prop-types";
import Callback from "ps-components/Callback/Callback";
import Hidden from "@material-ui/core/Hidden";
// import routePath from "forms/twinkle/context-help.json";
import { script3, addNoScript, initiateHotjar, script4 } from 'util/LayoutUtil.jsx';
import { browserDocument, browserLocalStorage, browserWindow } from "util/localStorageHelper";
import { sevaApp } from 'util/LayoutUtil.jsx';
import Newtopnavheaderlinks from "../../ps-components/Header/NewTopnavheaderlinks";
import NewCustomheaderlinks from "../../ps-components/Header/NewCustomheaderlinks";
import NewFooter from "../../ps-components/Footer/NewFooter";
import Cookies from "js-cookie";
import { logoutAPI } from "../../util/logoutAPI"
import { navigate } from '@reach/router';

const zs_open_window = function(url, height, width) {
  console.log(`url`, url);
  var leftPos = 0;
  var topPos = 0;
  if (browserWindow.screen) {
    leftPos = (browserWindow.screen.width - width) / 2;
    topPos = (browserWindow.screen.height - height) / 2;
    browserWindow.open(
      url,
      null,
      `width=` +
        width +
        `,height=` +
        height +
        `,left=` +
        leftPos +
        `,top=` +
        topPos +
        `, toolbar=0, location=0, status=1, scrollbars=1, resizable=1`
    );
  }
};

const CUSTOMER_PORTAL_API_URL = process.env.CUSTOMER_PORTAL_API_URL;
// var enableTwinkle = process.env.ENABLE_TWINKLE;

const SSO = {
  accessToken: 'customer_accessToken',
  accessTokenExpiry: 'customer_accessTokenExpiry',
  refreshToken: 'customer_refreshToken',
  refreshTokenExpiry: 'customer_refreshTokenExpiry',
  ssoId: 'customer_ssoId',
  customerId: 'customer_customerId',
  firstName: 'customer_firstName',
  lastName: 'customer_lastName',
  userProfile : 'customer_userProfile',
  user : 'user'

}; 
class Layout extends React.Component {
  state = {
    // active: 0,
    isLoaded: false,
    feedback: ``,
    autoPopup: 0,
    submitted: false,
    enableShake: false,
    loggedOut: false,
    extendHeader: false
  };

  img = [
    require(`assets/img/twinkle-logo.gif`),
    require(`assets/img/twinkle-close.png`)
  ];

  // toggleActive = (newValue) => {
  //   console.log(`toggle Active - `,newValue);
  //   if(newValue !== 0){
  //     browserDocument.getElementsByClassName(`wrap`)[0].classList.add(`active`);
  //   }else{
  //     browserDocument.getElementsByClassName(`wrap`)[0].classList.remove(`active`);
  //   }
  // }

  // handleRemyClick = (newValue) => {
  //   console.log(`remy click - `,newValue);
  //   this.toggleActive(newValue);
  //   if(newValue === 1){
  //     browserLocalStorage.setItem(`active`, 1);
  //   }
  //   this.setState({
  //     active: newValue,
  //     autoPopup: 0
  //   });
  // };

  // handleRemyClickWithoutFeedback = () => {
  //   this.toggleActive();
  //   this.setState({
  //     autoPopup: 0
  //   });
  // };

  handleLogOut = async (token) => {
    this.setState({
      loggedOut: true
    });
    let response = await logoutAPI()
    console.log(response)
    browserLocalStorage.setItem(`loggedOut${token}`, true);
    browserLocalStorage.setItem(`sessionalive`, false);
    const domain = '.starhealth.in'
    Object.values(SSO).map((eachCookieName) => Cookies.remove(eachCookieName,{ domain : domain}));
    let loginUrl = 'https://customer.starhealth.in/sso/login'
    navigate(loginUrl, `_self`)
    // fetch(
    //   `${CUSTOMER_PORTAL_API_URL}/cp/authentication/user/logout`,
    //   {
    //     method: `GET`,
    //     headers: {
    //       userId: userId
    //     }
    //   }
    // ).then(res => {
    //   console.log(res);
    // });
  };

  componentDidUpdate() {
    if (this.state.submitted) {
      setTimeout(() => {
        this.setState({
          submitted: false
        });
      }, 1500);
    } else {
      this.state.enableShake &&
        setTimeout(() => {
          this.setState({
            enableShake: false
          });
        }, 500);
    }

    let authToken = ``;
    const searchString = browserWindow.location.search;
    const req = searchString && searchString.split(`?`)[1];
    const paramJson = {};
    req &&
      req.split(`&`).map(e => {
        const p = e && e.split(`=`);
        paramJson[p[0]] = p[1];
      });
    let { token, userId } = paramJson;
    authToken = token;
    if (authToken) {
      let logOutToken = browserLocalStorage.getItem(`loggedOut${authToken}`);
      if (!logOutToken) {
        fetch(
          `${CUSTOMER_PORTAL_API_URL}/cp/authentication/user/loginstatus`,
          {
            method: `GET`,
            headers: {
              userId: userId,
              Authorization: `Bearer ${authToken}`
            }
          }
        ).then(async res => {
          let response = await res.json();
          if (response.message === `invalid token`) {
            browserLocalStorage.setItem(`loggedOut${authToken}`, true);
            this.setState({
              loggedOut: true
            });
          }
        });
      }
    } else {
      let user = browserLocalStorage.getItem(`user`);
      let accessToken = Cookies.get('customer_accessToken')
      let userInfo = Cookies.get('user')
      if (user || accessToken) {
        user ? user = JSON.parse(user) : userInfo
        authToken = accessToken ? accessToken : user.token;
        // console.log("logged in",accessToken)
        // userId = user.userId;
        if (!authToken) {
            browserLocalStorage.setItem(`loggedOut${authToken}`, true);
            this.setState({
              loggedOut: true
            });
        }
      }
    }
  }

  addScript=(filename)=>{
    var tags = browserDocument.getElementsByTagName('script');
    let scriptAdded=false;
    for (var i = tags.length; i >= 0; i--){ //search backwards within nodelist for matching elements to remove
     if (tags[i] && tags[i].getAttribute('src') != null && tags[i].getAttribute('src').indexOf(filename) != -1)
     scriptAdded=true
    }
    if(!scriptAdded){
     script3();
      script4();
     addNoScript();
    }
   }

  componentDidMount() {
    // script3();
    // addNoScript();
    // addAnalyticsScript();
    // addRemarkettingScript();
    //star seva app dom manipulation
    const queryParams = new URLSearchParams(window.location.search)
    // let sevaappp;
    if(queryParams.has('sevaapp') && queryParams.get('sevaapp') == 'true'){
      sessionStorage.seva= true
      // sevaappp =true
      // const nonMobileElements = browserDocument.querySelectorAll('[sevaapp="true"]')
      
    }
    
    //GTM is only in production
    this.addScript('https://www.googletagmanager.com/gtm.js?id=GTM-THS5T7T')
    // this.addScript('https://www.googleoptimize.com/optimize.js?id=OPT-WG3RH5D')
    // if (enableTwinkle === `true` && ( !sevaApp()) ) {
    //   let pathName = browserWindow.location && browserWindow.location.pathname;
    //   let hashPath = browserWindow.location && browserWindow.location.hash;
    //   if (routePath[pathName + hashPath]) {
    //     browserLocalStorage.setItem(`context`, routePath[pathName + hashPath]);
    //   } else {
    //     if (pathName && pathName.includes(`proposal`)) {
    //       browserLocalStorage.setItem(`context`, `policies`);
    //     } else if (pathName && pathName.includes(`renew`)) {
    //       browserLocalStorage.setItem(`context`, `renewals`);
    //     } else if (pathName && pathName.includes(`claim`)) {
    //       browserLocalStorage.setItem(`context`, `claims`);
    //     } else {
    //       browserLocalStorage.removeItem(`context`);
    //     }
    //   }
    // //  let twinkleUrl = `${process.env.BASE_URL}twinkle/loader_v1.js?app=Star%20Health`;
    //  let twinkleUrl;
    //  {!sevaApp() ? twinkleUrl = `${process.env.BASE_URL}twinkle/loader_v1.js?app=Star%20Health`
    //  :twinkleUrl=''
    //  }
    // // let twinkleUrl = `https://www.starhealth.in/twinkle/loader_v1.js?app=Star%20Health`;
    //   var urlParams = new URLSearchParams(browserWindow.location.href);
    //   var utm_channel = urlParams.get(`utm_channel`);
    //   var utm_source = urlParams.get(`utm_source`);
    //   var utm_campaign = urlParams.get(`utm_campaign`);
    //   if (utm_channel) twinkleUrl = twinkleUrl + `&utm_channel=${utm_channel}`;
    //   if (utm_source) twinkleUrl = twinkleUrl + `&utm_source=${utm_source}`;
    //   if (utm_campaign)
    //     twinkleUrl = twinkleUrl + `&utm_campaign=${utm_campaign}`;
    //   let loaderScript = browserDocument.createElement(`script`);
    //   loaderScript.type = `text/javascript`;
    //   loaderScript.id = `remy`;
    //   loaderScript.src = twinkleUrl;
    //   let queryParams = decodeURIComponent(browserWindow.location.search);
    //     if(browserWindow.location.search!='' && browserWindow.location.pathname=='/'){
    //     queryParams = queryParams.split(`?`)[1];
    //     queryParams = queryParams.split(`&`);
    //     queryParams.map(param => {
    //       let key = param.split(`=`)[0];
    //       let value = param.split(`=`)[1];
    //       if(key && key=='event' && value && !sevaApp() && value=='callTwinkle'){
    //         loaderScript.twinkle_widget = 'open';
    //       }
    //     })
    //   }
    //   if((!sevaApp())){
    //     browserDocument.body.appendChild(loaderScript);
    //   }
    // }
    let Attempts = 0;
    let checkLC = setInterval(() => {
      console.log(`waiting....`);
      Attempts++;
      if (browserWindow.LC_API) {
        console.log(`got LC API`);
        browserWindow.LC_API.on_chat_window_minimized = function() {
          console.log(`on_chat_window_minimized`);
          browserDocument.getElementById(
            `chat-widget-container`
          ).style.display = `none`;
          browserLocalStorage.setItem(`escalate`, false);
        };
        clearInterval(checkLC);
      } else {
        if (Attempts == 30) {
          console.log(`Max limit reached`);
          clearInterval(checkLC);
        }
      }
    }, 500);

    browserWindow.addEventListener(`storage`, e => {
      console.log(
        `The ` +
          e.key +
          ` key has been changed from ` +
          e.oldValue +
          ` to ` +
          e.newValue +
          `.`
      );
      if (e.key == `escalate`) {
        if (e.newValue == `true`) {
          this.handleRemyClickWithoutFeedback();
          browserDocument.getElementById(
            `chat-widget-container`
          ).style.display = `block`;
          if (browserWindow.LC_API) {
            browserWindow.LC_API.open_chat_window();
          } else {
            let Attempts = 0;
            let checkLC = setInterval(() => {
              Attempts++;
              if (browserWindow.LC_API) {
                console.log(`got LC API`);
                browserWindow.LC_API.open_chat_window();
                clearInterval(checkLC);
              } else {
                if (Attempts == 30) {
                  console.log(`Max limit reached`);
                  clearInterval(checkLC);
                }
              }
            }, 500);
          }
        }
      }
      // if (e.key == `active`) {
      //   console.log(`twinkle local update - `,e.key,0);
      //   this.handleRemyClick(0);
      // }
    });

    if (browserDocument.readyState === `complete`) {
      this.setState({
        isLoaded: true
      });
    }
    browserWindow.addEventListener(`load`, () => {
      console.log(`inside load`);
      if (browserDocument.readyState === `complete`) {
        this.setState({
          isLoaded: true
        });
        setTimeout(() => {
          this.setState({ autoPopup: 1 });
        }, 5000);
      }
    });
    initiateHotjar();
  
  }

  helmetNotInChild = children => {
    for (var key in children) {
      if (children.hasOwnProperty(key) && children[key] != null) {
        if (children[key].hasOwnProperty(`key`))
          if (children[key].key) {
            if (children[key].key == `helmetTags`) {
              return false;
            }
          }
      }
    }
    return true;
  };
  
  handleExtendClick = () => {
    this.setState({
      extendHeader: !this.state.extendHeader
    });
  }


  render() {
    const {
      children,
      classes,
      image1,
      image2,
      small,
      title,
      summary,
      noParallax,
      formatBanner
    } = this.props;
    // let twinkleUrl = `/twinkle/index.html?app=Star%20Health`;
    // let queryParams = `&`+decodeURIComponent(browserWindow.location.search).split(`?`)[1];
    let url = process.env.BASE_URL;
    let urlold = process.env.BASE_URL_OLD;
    let authToken = ``;
    const searchString = browserWindow.location.search;
    const req = searchString && searchString.split(`?`)[1];
    const paramJson = {};
    req &&
      req.split(`&`).map(e => {
        const p = e && e.split(`=`);
        paramJson[p[0]] = p[1];
      });
    let { token, userId } = paramJson;
    authToken = token;
    if (authToken) {
      browserLocalStorage.setItem(`user`, JSON.stringify(paramJson));
      let logOutToken = browserLocalStorage.getItem(`loggedOut${authToken}`);
      if (logOutToken) {
        authToken = ``;
        userId = ``;
      }
    } else {
      let user = browserLocalStorage.getItem(`user`);
      let accessToken = Cookies.get('customer_accessToken')
      let userInfo = Cookies.get('user')
      if (user || accessToken) {
        user ? user = JSON.parse(user) : userInfo;
        authToken = accessToken ? accessToken : user.token;
        // userId = user.userId;
        if (authToken) {
          let logOutToken = browserLocalStorage.getItem(`loggedOut${authToken}`);
          if (logOutToken) {
            authToken = ``;
            userId = ``;
          }
        }
      }
    }

    if (this.state.loggedOut) {
      authToken = ``;
      userId = ``;
    }

    return (
      <>
      <div className={classes.mobileWidth} >
        <Helmet defer={false}>
          {/* <script
            type="text/javascript"
            src="https://js.waybeo.com/v0.1-beta2/waybeo.min.js"
          />
          <script
            type="text/javascript"
            src={withPrefix(`waybeolib/js/jquery-1.9.1.min.js`)}
          /> */}
          <link
            rel="shortcut icon"
            href={require(`images/star-icon.png`)}
            type="image/png"
          />
          <link 
            rel="stylesheet"
            href="https://fonts.googleapis.com/css?family=Roboto|Roboto+Slab"
            media="all" 
          />
          <meta property="og:type" content="website" />
          <meta property="og:site_name" content="Star Health and Allied Insurance" />
          <meta
            property="og:image"
            content={require(`ps-assets/img/logo.png`)}
          />
          <meta name="twitter:site" content="@Starhealth" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="Star Health Insurance: Medical, Accident and Travel insurance policies" />
          <script type="application/ld+json">
            {`{ "@context" : "http://schema.org",
              "@type" : "Organization",
              "name": "Star Health and Allied Insurance",
              "url" : "https://www.starhealth.in",
              "sameAs" : [ "https://www.facebook.com/StarHealth",
              "https://twitter.com/starhealthins",
              "https://www.instagram.com/starhealth.insurance/",
              "https://www.linkedin.com/company/star-health-and-allied-insurance-co--ltd",
              "https://www.youtube.com/user/StarhealthInsurance"],
              "contactPoint" : [ { "@type" : "ContactPoint","telephone" : "+1800-425-2255","contactType" : "Customer Service - 24x7 Toll Free"}] }`}
          </script>
        </Helmet>
        {!sevaApp() && <Hidden implementation="css" mdDown>
        <Newtopnavheaderlinks
                loggedIn={authToken}
                userId={userId}
                customerPortalBaseUrl={`https://retail.starhealth.in`}
                handleLogOut={() => this.handleLogOut(authToken)}
                dropdownHoverColor="primary"
                tokenSearchString={`/?token=${authToken}&id=${userId}`}
                wellnessUrl={`https://star-health-web.getvisitapp.xyz`}
                handleExtendClick={this.handleExtendClick}
                extendHeader={this.state.extendHeader}
              />
        </Hidden>}
        {!sevaApp() && 
            <NewCustomheaderlinks
              loggedIn={authToken}
              userId={userId}
              handleLogOut={() => this.handleLogOut(authToken)}
              tokenSearchString={`/?token=${authToken}&id=${userId}`}
              wellnessUrl={`https://star-health-web.getvisitapp.xyz`}
              customerPortalBaseUrl={
                authToken ? `/customerportal` : `https://retail.starhealth.in`
              }
              dropdownHoverColor="primary" />
            }
        {!noParallax && (
          <Parallax
            image={image1}
            onHoverImage={image2}
            filter="dark"
            small={small}
            transition={true}
            layoutClass={formatBanner}
          >
            <div style={{width:`100%`}}>
              <Hidden implementation="css" smDown>
              <>
              {/* {title == "Senior Citizens Red Carpet Health Insurance Policy" && <h1 className={`${classes.pageTitle} ${classes.contactPosition}`} >For queries call <CallComponent color={'white'}  style={{color:'white'}}  contactNumber="1800 102 4477"/> </h1>} */}
              <div className={classes.container}>
                <GridContainer>
                  <GridItem
                    md={8}
                    sm={12}
                    className={classNames(
                      classes.mlAuto,
                      classes.mrAuto,
                      classes.textCenter
                    )}
                  >
                    <div>
                      <h1 className={classes.pageTitle}>{title}</h1>
                      <p className={classes.summary}>{summary}</p>
                    </div>
                  </GridItem>
                </GridContainer>
              </div>
              </>
              </Hidden>
            </div>
          </Parallax>
        )}
        {children}
        {(!browserWindow.location.href.includes(`#agent`) && !browserWindow.location.href.includes(`callTwinkle`)) && !sevaApp()&& <Callback />}
        {process.env.ENABLE_HELMET == `true` &&
          this.helmetNotInChild(children) && (
          <Helmet>
            <title>
                Star Health Insurance: Medical, Accident and Travel insurance policies
            </title>
            <meta charSet="utf-8" />
            <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
            <meta
              // name="viewport"
              // content="width=device-width, initial-scale=1.0"
              name="viewport" content="width=device-width, height=device-height, initial-scale=0.80"
            />
            <meta name="apple-mobile-web-app-capable" content="yes" />
            <meta name="HandheldFriendly" content="true" />
            <meta name="MobileOptimized" content="width" />
            <link rel="canonical" href={urlold} />
            <meta property="og:image:type" content="image/jpeg" />
            <meta property="og:image:width" content="400" />
            <meta property="og:image:height" content="300" />
            <meta name="theme-color" content="#344985" />
            <meta
              name="description"
              content="Star Health Insurance offers personalised insurance policies and plans across Medical, Accident and Travel for protecting you and your loved ones."
            />
            <meta property="og:description" content="Star Health Insurance offers personalised insurance policies and plans across Medical, Accident and Travel for protecting you and your loved ones." />
            <meta property="og:url" content={url}/>
            <meta
              httpEquiv="Content-Type"
              content="text/html; charset=utf-8"
            />
            <meta name="generator" content="strapi" />
            <meta property="dcterms.type" content="Text" />
            <meta property="dcterms.format" content="text/html" />
            <meta
              property="og:site_name"
              content="Star Health and Allied Insurance"
            />
            <meta property="dcterms.identifier" content={url} />
            <meta
              name="keywords"
              content="Buy Online Health Insurance Plans, Health Insurance, Accident Care, Overseas Travel, Insurance Policy, Insurance Agent Corner, Star Health and Allied Insurance Company Limited, Chennai, India, Portability, Star Health Products, Renew Policy Online, Health Insurance Specialist In India"
            />
            <meta name="robots" content="follow, index" />
            <meta
              property="dcterms.title"
              content="Star Health Insurance, Buy Online Insurance, Best Health Insurance"
            />
            <meta
              property="og:title"
              content="Star Health Insurance: Medical, Accident and Travel insurance policies"
            />
            <meta
              property="twitter:title"
              content="Star Health Insurance: Medical, Accident and Travel insurance policies"
            />
            <meta name="Star Health Insurance: Medical, Accident and Travel insurance policies" />
            <meta name="twitter:description" content="Star Health Insurance offers personalised insurance policies and plans across Medical, Accident and Travel for protecting you and your loved ones." />
            <meta property="twitter:url" content={url} />
          </Helmet>
        )}
      {!sevaApp() && 
      <Hidden mdDown>
      <div className={classes.feedbackIcon}>
          <img
            id="zoho-feedback"
            src={feedbackIcon}
            onClick={() =>
              zs_open_window(`https://survey.starhealth.in/zs/VMDjns`, 648, 700)
            }
          />
        </div>
        </Hidden>}
        {!sevaApp() && <NewFooter/>} 
        </div>
      </>
    );
  }
}

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  classes: PropTypes.object,
  image1: PropTypes.object,
  image2: PropTypes.object,
  small: PropTypes.bool,
  noParallax: PropTypes.bool,
  title: PropTypes.string,
  summary: PropTypes.string,
  formatBanner: PropTypes.string
};

export default withStyles(layoutStyle)(Layout);