import React from 'react';
import PropTypes from 'prop-types';
import GridItem from 'components/Grid/GridItem';
import Button from 'components/CustomButtons/Button.jsx';
import FormHelperText from '@material-ui/core/FormHelperText';
import OtpInput from 'react-otp-input';
import Snackbar from 'components/Snackbar/Snackbar';
import classNames from 'classnames';
import { ERROR_MESSAGES, snackBarTimer } from 'util/SelfService';
import Loading from 'components/Loading/Loading';
import {browserLocalStorage} from "util/localStorageHelper";

const CUSTOMER_PORTAL_API_URL = process.env.CUSTOMER_PORTAL_API_URL;

class OneTimePassword extends React.Component {
  state = {
    otp: ``,
    errorMessage: ERROR_MESSAGES.default,
    enableButton: false,
    otpResent: false,
    enableResend: false,
    loading: false,
    counter: 60
  };

  handleChange = otp => {
    if (otp.length === 6 && otp.search(`^[0-9]*$`) === 0) {
      this.setState({
        enableButton: true,
        otp
      });
    } else if (otp.search(`^[0-9]*$`) === 0 && otp.length <= 7) {
      this.setState({
        enableButton: false,
        otp,
        errorMessage: ERROR_MESSAGES.default
      });
    }
  };

  validateOTP = () => {
    const { basicDetails } = this.props;
    let validateOTP_API = `${CUSTOMER_PORTAL_API_URL}/cp/claim/validate/otp`;
    this.state.otp ? basicDetails.otpValue = this.state.otp : ''
    // if (basicDetails.policyNo === ``) {
    //   validateOTP_API += `mobileNo=${basicDetails.mobile}&otp=${this.state.otp}`;
    // } else {
    //   validateOTP_API += `policyno=${basicDetails.policyNo}&otp=${this.state.otp}&DOB=${basicDetails.dob}`;
    // }
    // if(basicDetails.uwType=='BANCS'){
    //   validateOTP_API=validateOTP_API+`&uwType=${basicDetails.uwType}`
    // }
    this.setState(
      {
        loading: true
      },
      () => {
        fetch(validateOTP_API,{
            method:`POST`,
            headers:{clientId: `7X2hEMm7YlhRXb7S3fjYs4GHXTeroWUr`},
            body: JSON.stringify(basicDetails),
        })
          .then(async res => {
            if (res.status === 200) {
              let resJson = await res.json();
              if(resJson.status == 'success'){
              //  console.log("Validate OTP respones",resJson.mobileNoToken);
               let mobileNoToken = resJson.mobileNoToken
              this.props.updateSelfServiceState({
                mobileNoToken
              });
              browserLocalStorage.setItem(`getClaimToken${basicDetails.claimNo}`, mobileNoToken );
            }
            else{
                this.setErrorMessage(ERROR_MESSAGES.invalid_otp)
            }
            } else {
              res.json().then((res1) => {
                if (res1.message.includes("You have exceeded the maximum number of otp retries")){
                    this.setErrorMessage(ERROR_MESSAGES.timeout_otp)
                }
                else{
                    this.setErrorMessage(ERROR_MESSAGES.invalid_otp);
                }
              })
            //   this.setErrorMessage(ERROR_MESSAGES.invalid_otp);
            }
          })
          .catch(() => {
            this.setState({ errorMessage: ERROR_MESSAGES.network_problem });
          });
      }
    );
  };

  setErrorMessage = errorMessage => {
    this.setState({
      errorMessage,
      loading: false
    });
  };

  resendOTP = () => {
    const { basicDetails } = this.props;
    // console.log("details",basicDetails)
    let otpAPI = `${CUSTOMER_PORTAL_API_URL}/cp/claim/generate/otp`;
    // if (basicDetails.policyNo === ``) {
    //   otpAPI += `?mobileNo=${basicDetails.mobile}&serviceType=prelogin`;
    // } else {
    //   otpAPI += `?policyno=${basicDetails.policyNo}&DOB=${basicDetails.dob}&serviceType=prelogin`;
    // }
    // if(basicDetails.uwType=='BANCS'){
    //   otpAPI=otpAPI+`&uwType=${basicDetails.uwType}`
    // }
    this.setState(
      {
        loading: true
      },
      () => {
        fetch(otpAPI,{
            method:`POST`,
            headers:{clientId: `7X2hEMm7YlhRXb7S3fjYs4GHXTeroWUr`},
            body: JSON.stringify(basicDetails),
        })
          .then(async res => {
            if (res.status === 200) {
                let resJson = await res.json();
                if(resJson.status == 'success'){
              setTimeout(
                () => this.setState({ otpResent: false }),
                snackBarTimer
              );
              this.transition();
              this.setState({
                otpResent: true,
                enableResend: false,
                loading: false,
                counter: 60
              });
              this.resendTimer();
            }
            else{
                if(resJson.message.includes('You have exceeded the maximum number of otp retries')){
                    this.setErrorMessage(ERROR_MESSAGES.timeout_otp);
                }
                else{
                  this.setErrorMessage(ERROR_MESSAGES.invalid_otp);
                }
            }
            } else {
              this.setErrorMessage(ERROR_MESSAGES.network_problem);
            }
          })
          .catch(() => {
            this.setErrorMessage(ERROR_MESSAGES.network_problem);
          });
      }
    );
  };

  resendTimer = () => {
    setTimeout(() => {
      this.setState({
        enableResend: true
      });
    }, 60000);
    if (this.state.enableResend === false) {
      this.myInterval = setInterval(() => {
        if (this.state.counter > 0) {
          this.setState(prevState => ({
            counter: prevState.counter - 1
          }));
        }
      }, 1000);
    }
  };
  transition = () => {
    clearInterval(this.myInterval);
  };

  componentDidMount = () => {
    this.resendTimer();
  };

  render() {
    const { classes } = this.props;
    const { counter } = this.state;

    let mobile = this.props.basicDetails.mobileNum;
    let mobileUi = ``;

    for (let i = 0; i < mobile.length; i += 4) {
      mobileUi += mobile[i] + mobile[i + 1];
      if (i !== mobile.length - 2) {
        mobileUi += '**';
      }
    }

    if (this.state.loading) {
      return (
        <Loading
          classes={classNames(classes.mlAuto, classes.mrAuto, classes.loading)}
        />
      );
    } else {
      return (
        <>
          <GridItem
            md={9}
            sm={9}
            xs={12}
            className={classNames(
              classes.mrAuto,
              classes.mlAuto,
              classes.textCenter
            )}>
            <h2
              style={{
                fontWeight: 200,
                color: 'black'
              }}>
              Thank you.
            </h2>
            <h3
              style={{
                fontWeight: 200,
                color: 'black'
              }}>
              We have sent you the OTP to {mobileUi}
            </h3>
            <OtpInput
              numInputs={6}
              onChange={otp => this.handleChange(otp)}
              value={this.state.otp}
              containerStyle={{
                justifyContent: `center`,
                margin: `25px 0px`
              }}
              inputStyle={{
                fontSize: `1.25rem`,
                border: `0`,
                width: `100%`,
                maxWidth: `45px`,
                borderBottom: `1px solid`,
                color: `#555`
              }}
              separator={<span>&nbsp;&nbsp;&nbsp;</span>}
            />
            <h4
              className={classes.description}
              style={{
                color: 'rgba(0, 0, 0, 0.73)',
                fontSize: '17px'
              }}>
              Did not receive OTP?
            </h4>
            <Button
              simple
              onClick={this.resendOTP}
              className={classes.socialTitle}
              style={{ color: 'black', fontWeight: 400, marginLeft: '-1.8%' }}
              disabled={!this.state.enableResend}>
              Resend OTP
            </Button>
            {counter > 0 && (
              <span
                style={{
                  marginLeft: '-4%',
                  marginRight: '3%',
                  textTransform: 'uppercase',
                  fontSize: '13px'
                }}>
                in {counter} Seconds
              </span>
            )}
          </GridItem>
          <GridItem
            md={9}
            sm={9}
            xs={12}
            className={classNames(
              classes.mrAuto,
              classes.mlAuto,
              classes.errorMessageStyle
            )}>
            {this.state.errorMessage !== ERROR_MESSAGES.default && (
              <FormHelperText
                id='otp-error'
                error
                style={{marginLeft: '-12px',fontSize: '18px'}}
                className={classes.textCenter}>
                {this.state.errorMessage}
              </FormHelperText>
            )}
            {this.state.otpResent && (
              <GridItem
                md={9}
                className={classNames(classes.mlAuto, classes.mrAuto)}>
                <Snackbar
                  duration={snackBarTimer}
                  open={true}
                  status='success'
                  message='OTP Resent'
                  style={{ position: `absolute`, top: `0px` }}
                  flag={() => {}}
                />
              </GridItem>
            )}
          </GridItem>
          <GridItem
            md={12}
            sm={12}
            xs={12}
            style={{ textAlign: `center` }}
            className={classNames(classes.mrAuto, classes.mlAuto)}>
            <Button
              disabled={!this.state.enableButton}
              onClick={this.validateOTP}
              color='primary'
              style={{ marginLeft: '-1.4%' }}>
              Verify
            </Button>
          </GridItem>
        </>
      );
    }
  }
}

OneTimePassword.propTypes = {
  classes: PropTypes.object,
  basicDetails: PropTypes.object,
  updateSelfServiceState: PropTypes.func,
  nextStage: PropTypes.string
};

export default OneTimePassword;
