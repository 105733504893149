import client from './apolloClient';

async function query(query, payload) {
  try {
    const response = await client.query({
      query: query,
      variables: payload,
    });
    return response; // Return the retrieved data
  } catch (error) {
    console.error('Error fetching data**********:', error);
    return {
      responseCode: null,
      responseMessage: `Sorry we are unable to process your request at the moment, Please try again later.`,
      error: error,
    };
  }
}

async function mutation(mutationStr, payload) {
  try {
    const response = await client.mutate({
      mutation: mutationStr,
      variables: payload,
    });

    return response; // RESPONSE // Return the retrieved data
  } catch (error) {
    return {
      responseCode: null,
      responseMessage: defaultErrorMessage,
      error: error,
    };
  }
}

export const defaultErrorMessage =
  'Sorry we are unable to process your request at the moment, Please try again later.';

export async function handlerGraphQlResponse(res, setTokenExpired) {
  try {
    if (res.responseCode === '401') {
      setTokenExpired && setTokenExpired(true);
    }
    if (res.responseCode === '200') {
      return {
        responseCode: res.responseCode,
        responseMessage: res.responseMessage,
        response: res.response,
      };
    } else if (res.responseCode === '2001') {
      //special case were cps profile is incomplete 
      return {
        responseCode: res.responseCode,
        responseMessage: res.responseMessage,
        response: res.response,
      };
    } else {
      return {
        responseCode: res.responseCode,
        responseMessage: res.responseMessage,
        response: null,
      };
    }
  } catch (error) {
    return {
      responseCode: null,
      responseMessage: defaultErrorMessage,
      response: null,
      error: error,
    };
  }
}

const commonAPiGraphql = { query, mutation };
export default commonAPiGraphql;
