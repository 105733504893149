import React from "react";

// nodejs library that concatenates classes
import classNames from "classnames";

// @material-ui/core components
import Accordion from "components/Accordion/Accordion";
import withStyles from "@material-ui/core/styles/withStyles";

import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
import GridContainer from "components/Grid/GridContainer";
import aboutUsStyle from "ps-assets/jss/aboutUsStyle.jsx";

import { Link } from "gatsby";
import Badge from "components/Badge/Badge.jsx";
import { browserDocument } from "util/localStorageHelper";
import { getQueryStatus } from '../util/CLaimApi.js'
const CUSTOMER_PORTAL_API_URL = process.env.CUSTOMER_PORTAL_API_URL;
const CUSTOMERPORTAL_URL = process.env.CUSTOMERPORTAL_URL

class ReimbursementStatusTrail extends React.Component {
  claimData;
  queryData;
  remarks;
  value = false;
  id;
  state = {
    queryRemarks: {},
    downloadFailure:false,
    downloadFailureErrorMsg:''
  }



  handleScrutiny=()=>{
    let downloadAPI = `${CUSTOMER_PORTAL_API_URL}/cp/claim/submission/letter?claimNo=${this.props.data.rodNumber}`;
    fetch(downloadAPI, {
      method: `GET`,
      responseType: `blob`,
      headers: {
        clientId: `WEB`
      }
    })
      .then(res => res.blob())
      .then(res => {
        if (
          res.type!='application/pdf'
        ) {
          this.setState({
            downloadFailure: true,
            downloadFailureErrorMsg: 'No Documents Found',
          });
        } else{
          const fileURL = URL.createObjectURL(res);
          const elem = browserDocument.createElement(`a`);
          browserDocument.body.appendChild(elem);
          elem.style = `display: none`;
          elem.setAttribute(`download`, 'SubmissionLetter-'+this.props.data.rodNumber+'.pdf');
          elem.setAttribute(`href`, fileURL);
          elem.click();
          this.setState({
            downloadFailure: false,
            downloadFailureErrorMsg: '',
          });
        } 
      });
  }
  renderElement(data, intimationNumber, policyNo, classification, rodNo, category,fulldata, recDate){
   
    // if(classification == 'HOSPITALISATION'){
    //   this.claimData =  'Hospitalization'
    // }
    // if(classification == 'PRE_HOSPITALISATION'){
    //   this.claimData =  'PreHospitalization'
     
    // }
    // if(classification == 'POST_HOSPITALISATION'){
    //   this.claimData = 'PostHospitalization'
      
    // }
      this.claimData = classification
    if(data.stageName == "Document Submitted"){
      if(data.stageStatus =="completed"){
        return( 
          <Badge color="success">        
            <Link style={{color: '#FFFFFF'}}
            href={`${CUSTOMERPORTAL_URL}/customerportal/file-upload?policyNumber=${policyNo}&iintimationNo=${intimationNumber}&type=${this.claimData}&rodNumber=${rodNo}&category=${category}&healthCardNo=${fulldata.healthCardNo}&date=${recDate}`}>
            View
            </Link>    
          </Badge>       
        );
      }
    }
    if(category != 'op'){
      if(data.stageName == "Document Scrutiny"){
        if(data.stageStatus =="completed"){  
          this.value = true;
            return( 
              <>
              <Badge color="success">
                <span onClick={this.handleScrutiny} style={{cursor:'pointer'}}>     
                Submission Letter   
                </span> 
              </Badge>  
              {this.state.downloadFailure && (
                  <>
                   <br />
                  <span onClick={this.handleScrutiny} style={{color:'red'}}>     
                  {this.state.downloadFailureErrorMsg}
                  </span> 
                  </>
                )}
              </>     
            );
        }
        else{
          this.value = false;
        }
      }
    } 
    
    
    
    if(data.stageName == "Query"){
      if(data.stageStatus =="pending" && this.value == true){   
       getQueryStatus(rodNo).then((res) => {
        for(let r of res.data){
          if(r.status == "OPEN"){
            this.queryData = r;
            this.remarks =this.queryData.queryRemarks;
            this.id = this.queryData.queryId;
          }
        }
       }).catch((err) => {
        console.log("err", err)
      });
        return(
          <div>
            {this.queryData && (             
             <span style={{wordBreak: 'break-all'}}>{this.queryData.queryRemarks}<br /></span> 
            )}
            <Badge color="warning">      
              <Link style={{color: '#000000'}} 
              href={`${CUSTOMERPORTAL_URL}/customerportal/query-upload?policyNumber=${policyNo}&iintimationNo=${intimationNumber}&type=${this.claimData}&rodNumber=${rodNo}&remarks=${this.remarks}&id=${this.id}&category=${category}&healthCardNo=${fulldata.healthCardNo}`}>
              Response awaited
              </Link> 
              </Badge> 
            </div>        
          );
      }
    }
    
  }

  render() {
    let { classes,fulldata } = this.props;
    let data=this.props.data;
    return (
      <div style={{ margin: `0px 20px` ,width:`100%`}}>
        <Accordion
          active={0}
          collapses={[
            {
              title: `Status Trail`,
              content: (
                <GridContainer
                  className={classNames(classes.mrAuto, classes.mlAuto)}
                  style={{ fontSize: `13px` }}
                >
                  {data && data.statusTrail.map((data1,key) => {
                    return (
                      <GridItem key={key} xs={12} sm={6} md={3}>
                        <b>{data1.stageName}</b>
                        <div style={{ minHeight: 20 }}>
                          {data1 ? data1.stageStatus : ``}
                        </div>
                        {this.renderElement(data1, this.props.intimationNumber, this.props.policyNo, this.props.classification, this.props.rodNumber, this.props.category,fulldata, data.documentReceivedDate, data1.stageName)}
                      </GridItem>
                    );
                  })}
                </GridContainer>
              )
            }
          ]}
        />
      </div>
    );
  }
}

export default withStyles(aboutUsStyle)(ReimbursementStatusTrail);

ReimbursementStatusTrail.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object,
  intimationNumber: PropTypes.string,
  policyNo: PropTypes.string,
  classification: PropTypes.string,
  rodNumber: PropTypes.string,
  claimNo: PropTypes.string,
  date: PropTypes.string,
  healthCard: PropTypes.string,
  category:PropTypes.string,
  fulldata:PropTypes.object,
};
