import React from "react";
import claimListStyle from "ps-assets/jss/claimListStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import CashlessContent from "./CashlessContent.jsx";
import ReimbursementContent from "./ReimbursementContent.jsx";
import Accordion from "components/Accordion/Accordion";
import GridContainer from "components/Grid/GridContainer";
//import {withPrefix } from "gatsby"
//import Datetime from "react-datetime";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import ClaimStatusContent from "components/ClaimStatusContent/ClaimStatusContent";
import PropTypes from "prop-types";
import ModalForm from 'ps-components/ModalForm/ModalForm';
// import OTP from '../ps-components/Header/SelfServiceComponents/OTP';
import Button from "components/CustomButtons/Button.jsx";
  import {searchIntimationHealthIdcardAPI,
    searchPolicyNumberAPIGroupPost,searchPolicyNumberAPIPost,searchHealthIdcardAPIPost,
    searchIntimationAPIPost,
    searchIntimationAPI,searchHealthIdcardAPI,listClaimsByPolicy,generateOtp,searchMobileNumberAPI,
    // searchPolicyNumberAPIGroup,
    validateOPStatus,
    clearExpiredMobileTokens,searchOPAPIPost
    // validateClaimsAPI
  } from '../util/CLaimApi.js'
import FileUploadComponent from './FileUploadComponent'
import ClaimIntimationListForUpload from './ClaimIntimationListForUpload'
import SelectMobileNumber from './SelectMobileNumber'
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import loadingGIF from 'ps-assets/img/star2.gif';
import { browserWindow } from 'util/localStorageHelper';
import ClaimsOTP from '../ps-components/Header/SelfServiceComponents/ClaimsOTP';
class ClaimUploadDocuments extends React.Component {
  claims;
  state = {
    response: ``,
    intimationNumber: ``,
    idCardNumber: ``,
    largeModal: false,
    searchByPolicyNumber:false,
    mobileNumber:``,
    searchByIdCardNumber:false,
    stageValue:``,
    resJsonStatus: false,
    resJsonStatusAfter: false,
    resJson: ``,
    errorMsgforPolicy:``,
    resJsonStatusError: false,
    errorMessage: ``,
    policyNumber:``,
    dob:``,
    Loading: false,
    selectInput: `IntimationNumber`,
    searchByIntimationNumber:true,
    screenSize: 4,
    claimCategory: '',
    classification: [],
    claimtype: '',
    loading: false,
    tokenNo: '',
  };

  componentDidMount(){
    clearExpiredMobileTokens();
    let {currentClaims}=this.props   
    this.claims = null; 
    const searchString = browserWindow.location.href;
    if(browserWindow.location.hash != '#1?Type=withoutLogin'){
      const req = searchString.split('?')[1];
      const req1 = searchString.split('?')[0];
      const req2 = req1.split('#')[1];
    if(req != undefined && req != null){
      if(req2 == 4){
        const paramJson = {};
        req.split('&').map((e) => {
          const p = e.split('=');
          paramJson[p[0]] = p[1];
        });
        let type = paramJson && paramJson.category && paramJson.category.toLowerCase();
        this.claims = type == 'health'? 'other': type;
        currentClaims = this.claims;
        this.setState({
          intimationNumber: paramJson.claimNumber,
          idCardNumber: paramJson.healthCardNo,
          loading: true
        }, () => {
          this.claimStatusFunction('','')
        });
      }
          
    }
  }
    
    this.setState({
      claimtype: currentClaims,
    })     
   
  }
  componentWillReceiveProps(nextProps) {
    if(this.claims == null){
      if(nextProps.currentClaims != this.state.claimtype){
        if(nextProps.currentClaims == 'group' && this.state.selectInput  == 'policyNo' ){
          this.setState({
            claimtype: nextProps.currentClaims,
            screenSize: 3,
          }) 
        }
        else{
          this.setState({
            claimtype: nextProps.currentClaims,
            screenSize: 4,
          }) 
        }      
      }
    }
  }

  handleClose(modal) {
    var x = [];
    x[modal] = false;
    this.setState({largeModal:false,response:``});
  }
  handleOTP=async(otpMobileNumber)=>{
    let {currentClaims}=this.props
    let data = {
      mobileNum: otpMobileNumber,
    }
    if(this.state.intimationNumber){
      data.claimNo = this.state.intimationNumber
    }
    if(this.state.idCardNumber){
      data.healthCardNo = this.state.idCardNumber
    }
    if(this.state.policyNumber){
      data.policyNumber = this.state.policyNumber
    }
    if(currentClaims == 'op'){
      data.category = currentClaims
    }
  let res=await generateOtp(data)
this.setState({
  val:res,
  stageValue:'OTP sent',
  otpMobileNumber:otpMobileNumber,
  loading: false
})
  }

  handleChange = e => {
    if(e.target.value[e.target.value.length-1] != " ")
      this.setState({ [e.target.id]: e.target.value });
  };

  handleMobileNumberChange = (e) =>{
    if(e.target.value[e.target.value.length-1] != " "){
      if (!isNaN(e.target.value) && e.target.value.length <= 10) {
        this.setState({ [e.target.id]: e.target.value });
      }
      else{
        e.target.value = ''
      }
    }      
  }

  onClickPolicyNumber=()=>{
    this.setState({
      searchByPolicyNumber:true
    })
  }
  onClickIntimationNumber=()=>{
    this.setState({
      searchByPolicyNumber:false
    })
  }
  handleDate = value => {
    let dateSeperator='-'
    // let dob=moment().format('DD/MM/YYY')
    switch (typeof value) {
      case `string`:
        if(value.search(`^[0-9-]*$`) === 0){
        if(this.state.dob !== value+dateSeperator){
        if(!value.endsWith(dateSeperator)){
        switch (value.length) {
          case 2:
          case 5:
            value = value + dateSeperator;
            break;
        }
        }else{
          value = value.substr(0,value.length-1);
        }
        }
        if (value.length <= 10) {
          this.setState({ dob: value });
        }
        }
        break;
      case `object`:
        this.setState({ dob: value.format(`DD-MM-YYYY`) });
        break;
    }
    // this.setState({ dob: dob });
  };
  onClickback=()=>{
    let claimList= sessionStorage.getItem('claimList')
if(claimList)
{
  claimList=JSON.parse(claimList)
  this.setState({
    searchByPolicyNumber:true,
    response:claimList
  })
}
  }
  claimStatusFunction=async(intimatonNumber,healthId,mobileNoToken)=>{
    let claimNumberfromAPI=``   
     if(this.state.intimationNumber && this.state.intimationNumber.length>0)
    {
    let res=await searchIntimationAPI(this.state.intimationNumber,mobileNoToken)
      if(res && res.status && res.status =="failure"){
        this.setState({
          loading: false,
          showError: true,
          errorMsg: "Please upload documents"
        })
      }
      else{
          claimNumberfromAPI=res && res[0] && res[0].claims &&  res[0].claims[0] && res[0].claims[0].healthCardNo
      }

    }
  
   
      let body= {
      claimIntimation:intimatonNumber && intimatonNumber.length>0?intimatonNumber:this.state.intimationNumber,
      healthId:healthId && healthId.length>0 ? healthId:claimNumberfromAPI,
      token:mobileNoToken
          }
          let intimation=intimatonNumber && intimatonNumber.length>0?intimatonNumber:this.state.intimationNumber;
          let response=await searchIntimationHealthIdcardAPI(body)
          //console.log("response", response)
          if(response.status == "failure"){
            this.setState({
              loading: false,
              showError: true,
              errorMsg:'Please upload documents'
            })
          }
          else{
            let remData = response.data[0].reimbursementDetails;
            let arrayClassification = []
            for (let resp of remData) {
              if(!resp.classification){
                console.log("empty")
              }
              else{
                arrayClassification.push(resp.classification)
              }
            }
            this.setState({
              claimStatusResponse:response.data,
              stageValue:'Claim Details',
              intimationNumber:intimation,
              classification: arrayClassification,
              loading: false
            })
          }
    
      
    
          
  }
  isCorporatePlan = (plan) => {
    return (
      plan &&
      plan.polType === 'Corporate' &&
      plan.productCode === 'Group'
    );
  };
  getHealthCardNo = (policyDetails) => {
    try {
      let insurers = policyDetails.insurers.policyInsuredDetail;
      let isCorporatePlan = this.isCorporatePlan(
        policyDetails.policyIdentification
      );
      for (let insurer of insurers) {
        if (insurer.insurerRelationship === 'REL_09' && isCorporatePlan)
          return insurer.idCardNo;
      }
      return insurers[0].healthCardNo;
    } catch (error) {
      return ``;
    }
  };
  datafromMobileNumber=async(mobileNumber)=>{
    if(mobileNumber.length != 10){
      this.setState({
        showError:true,
        loading: false,
        errorMsg:'Enter valid mobile number'
      })
    }
    else{
    let mobileres=await searchMobileNumberAPI(mobileNumber)
    let policyArray=[]
        if(mobileres == undefined || mobileres == null || mobileres.length == 0){
          this.setState({
            showError:true,
            loading: false,
            errorMsg:'No Claims Found'
          })
        }
        else{
        mobileres && mobileres.map((mobile)=>{
        policyArray.push({
          policyNumber:mobile.policyIdentification.policyNumber,
          healthId:''
        })
        })
        // let policyArray=[ { policyNumber: 'P/700002/01/2022/036169', healthId: '' } ]
        let claimArray=[]
        if(policyArray && policyArray.length>0)
        {
          let policyRes=await listClaimsByPolicy(policyArray)
          // if(policyRes)
          // {
          policyRes.data &&policyRes.data.map((policy)=>{
            if(policy.claimsList && policy.claimsList.length>0)
            {
              policy.claimsList.map((claim)=>{
        claimArray.push(claim)
              })
            }
          })
        // }
        }
        this.setState({
          stageValue:'Policy Details',
          policyDetailsResponse:claimArray,
          loading: false

        })
      }
    }
  }
  updateSelfServiceState=async(mobileNoToken)=>{
    let tokenNo = mobileNoToken.mobileNoToken
    let {currentClaims}=this.props
    if(this.state.RegisteredmobileNo && this.state.RegisteredmobileNo.length>0)
    {
      this.datafromMobileNumber(this.state.RegisteredmobileNo)
    }
    else if(currentClaims == 'op'){
      let body = {
        intimationNumber: this.state.intimationNumber,
        idCardNumber: this.state.idCardNumber,
        currentClaims: currentClaims,
        tokenNo: tokenNo
      }
      let res=await validateOPStatus(body) 
    if(res && res.status && res.status =="failure"){
      this.setState({
        loading: false,
        showError: true,
        errorMsg: "No data found"
      })
    }
    else{
    let remData = res.data[0].reimbursementDetails;
    let arrayClassification = []
    for (let resp of remData) {
      if(!resp.classification){
        console.log("empty")
      }
      else{
        arrayClassification.push(resp.classification)
      }
    }
    this.setState({
      claimStatusResponse:res.data,
      stageValue:'Claim Details',
      intimationNumber: this.state.intimationNumber,
      claimCategory: currentClaims,
      classification: arrayClassification,
      loading: false
    })
    }
    }
   else if(this.state.intimationNumber && this.state.intimationNumber.length>0)
    {
   
    this.claimStatusFunction('','',tokenNo)
    }
    else if(this.state.policyNumber && this.state.policyNumber.length>0)
    {
    let RequestBody=[{
      policyNumber:this.state.policyNumber,
      healthId:this.props.currentClaims=='group' ?this.state.idCardNumber:'',
      }]
    let res= await listClaimsByPolicy(RequestBody)
    // let body= {
      // claimIntimation:'CIR/2022/700002/0307316',
      // healthId:'23062346-1'
      //     }
      //     let response=await claimIntimationAPI(body)
      //     this.setState({
      //       claimStatusResponse:response.data,
      //       stageValue:'Claim Details'
      //     })
          // console.log('responseofpolicy',response)
    this.setState({
      policyDetailsResponse:res &&res.data &&  res.data[0] && res.data[0].claimsList,
      tokenNo:tokenNo,
      stageValue:'Policy Details',
      loading: false
    })
  }
  else if(this.state.idCardNumber && this.state.idCardNumber.length>0)
  {
    let res=await searchHealthIdcardAPI(this.state.idCardNumber,tokenNo)
    this.setState({
      policyDetailsResponse:res &&res[0] &&  res[0].claims &&  res[0].claims,
      tokenNo:tokenNo,
      stageValue:'Policy Details',
      loading: false
    })
  }

}
claimIntimationCallback=(intimationNumber,healthIdCard)=>{
  let token
  if(this.state.tokenNo){
    token = this.state.tokenNo
  }
  this.claimStatusFunction(intimationNumber,healthIdCard,token)
// this.setState({
//   intimationNumber:intimationNumber,
//   stageValue:'Claim Details'
// })
}
handleSelectNumber=async()=>{ 
  this.setState({
    loading: true,
  });
let {currentClaims}=this.props
 let mobileNumber;
 let showError=false;
 if(currentClaims=='op'){
  if(currentClaims=='op' && (this.state.intimationNumber.length==0 || this.state.idCardNumber.length==0)){
    showError=true
    this.setState({
      showError:true,
      errorMsg:'Enter both claim intimation number and healthid card number',
      loading: false
    })
   }
   else{
    let body = {
      intimationNumber: this.state.intimationNumber,
      healthId: this.state.idCardNumber,
      category: currentClaims
    }
    // let res=await validateOPStatus(body) 
    let res=await searchOPAPIPost(body) 
    if(res && res.status && res.status =="failure"){
      this.setState({
        loading: false,
        showError: true,
        errorMsg: "No data found"
      })
    }
    else {
      mobileNumber=res.mobileNumbers
    // let remData = res.data[0].reimbursementDetails;
    // let arrayClassification = []
    // for (let resp of remData) {
    //   if(!resp.classification){
    //     console.log("empty")
    //   }
    //   else{
    //     arrayClassification.push(resp.classification)
    //   }
    // }
    // this.setState({
    //   claimStatusResponse:res.data,
    //   stageValue:'Claim Details',
    //   intimationNumber: this.state.intimationNumber,
    //   claimCategory: currentClaims,
    //   classification: arrayClassification,
    //   loading: false
    // })
     }
   }
   if (mobileNumber && mobileNumber.length > 0) {
    this.setState({
      stageValue: 'mobile number',
      mobileNumber: mobileNumber,
      loading: false
    })
  }
 }
 else{
  if(this.state.searchByIntimationNumber == true && this.state.intimationNumber.length==0){
    showError=true
    this.setState({
      showError:true,
      errorMsg:'Enter claim intimation number ',
      loading: false
    })
  }
  if(this.state.searchByIdCardNumber == true && this.state.idCardNumber.length==0){
    showError=true
    this.setState({
      showError:true,
      errorMsg:'Enter healthid card number ',
      loading: false
    })
  }
 
  if(currentClaims=='group' &&this.state.searchByPolicyNumber==true && (this.state.policyNumber.length==0 || this.state.idCardNumber.length==0))
 {
  showError=true
   this.setState({
     showError:true,
     errorMsg:'enter both  policy number and healthid card number  ',
     loading: false
   })
 }
//   if(this.state.searchByPolicyNumber==true && currentClaims!='group' && (this.state.policyNumber.length==0 ||this.state.dob.length==0))
//   {
//    showError=true
//    this.setState({
//      showError:true,
//      errorMsg:'Enter both policy number and Date of birth '
//    })
//  }
//   else if(currentClaims=='group' &&this.state.searchByPolicyNumber==false && this.state.searchByMobileNumber==false && (this.state.intimationNumber.length==0 || this.state.idCardNumber.length==0))
//   {
//    showError=true
//     this.setState({
//       showError:true,
//       errorMsg:'enter both claim intimation number and healthid card number '
//     })
//   }
  // if(currentClaims=='group' &&this.state.searchByPolicyNumber==true &&this.state.searchByMobileNumber==false && (this.state.policyNumber.length==0 || this.state.dob.length==0))
  // {
  //  showError=true
  //   this.setState({
  //     showError:true,
  //     errorMsg:'enter both  policy number and Date of birth'
  //   })
  // }
  else if(showError==false && this.state.RegisteredmobileNo && this.state.RegisteredmobileNo.length>0)
  {
   //  this.datafromMobileNumber(this.state.mobileNumber)
   if(this.state.RegisteredmobileNo.length != 10){
    this.setState({
      showError:true,
      loading: false,
      errorMsg:'Enter valid mobile number'
    })
  }
  else{
    let data = {  
      mobileNum: this.state.RegisteredmobileNo,
    }
    let mobileres = await generateOtp(data)
   if(mobileres == undefined || mobileres == null || mobileres.length == 0){
    this.setState({
      showError:true,
      loading: false,
      errorMsg:'No data Found'
    })
  }
  else{
   this.setState({
     stageValue:'OTP sent',
     otpMobileNumber:this.state.RegisteredmobileNo,
     loading: false
   })
  }
}
   
  }
   else if(showError==false && this.state.searchByIntimationNumber ==true && this.state.intimationNumber && this.state.intimationNumber.length>0)
   {
      let res = await searchIntimationAPIPost(this.state.intimationNumber)

     if(res){
       if(res && res.status && res.status =="failure"){
      this.setState({
        loading: false,
        showError: true,
        errorMsg: "No data found"
      })
     }
     else{
      mobileNumber=res.mobileNumbers
     }
    }
   
 
 }
 else if((currentClaims=='group' &&this.state.searchByPolicyNumber==true && this.state.policyNumber.length>0 && this.state.idCardNumber.length>0))
 {
 
  if(this.state.idCardNumber.toString().slice(-2) != '00'){
    showError=true
    this.setState({
      showError:true,
      errorMsg:'Please enter primary health ID card number ',
      loading: false
    })
  }
  else {
    let res=await searchPolicyNumberAPIGroupPost(this.state.policyNumber,this.state.idCardNumber)
    if(res && res.status && res.status =="failure"){
      this.setState({
        loading: false,
        showError: true,
        errorMsg: "No data found"
      })
    }
    else{
      mobileNumber=res.mobileNumbers;
    }

  }

 
 }
 else if(showError==false &&currentClaims=='group'&& this.state.policyNumber && this.state.policyNumber.length>0)
 { 
   let res= await searchPolicyNumberAPIPost(this.state.policyNumber)
   mobileNumber=res.mobileNumbers;
 }
 else if(showError==false && this.state.idCardNumber && currentClaims=='group' && this.state.idCardNumber.length>0)
 {
   let res=await searchHealthIdcardAPIPost(this.state.idCardNumber)
  mobileNumber=res.mobileNumbers;
 } 
 else if(showError==false &&currentClaims!='group'&& this.state.policyNumber && this.state.policyNumber.length>0)
 {
   let res= await searchPolicyNumberAPIPost(this.state.policyNumber)
   mobileNumber=res.mobileNumbers;
 
 }
 else if(showError==false && this.state.searchByIdCardNumber == true && this.state.idCardNumber && currentClaims!='group' && this.state.idCardNumber.length>0)
 {
   let res=await searchHealthIdcardAPIPost(this.state.idCardNumber)
   if(res && res.status && res.status =="failure"){
    this.setState({
      loading: false,
      showError: true,
      errorMsg: "No data found"
    })
  }
  else{
    mobileNumber=res.mobileNumbers;
  }
 } 
 if(mobileNumber && mobileNumber.length>0)
 {  
 this.setState({
   stageValue:'mobile number',
   mobileNumber:mobileNumber,
   loading: false
 })
 }

 }
 
 
}
  getStage=()=>{
    const { classes,currentClaims } = this.props;
    let data = {}
    data.mobileNum = this.state.otpMobileNumber
    if(this.state.intimationNumber){
      data.claimNo = this.state.intimationNumber
      data.requestType = 'intimationNumber'
    }
    if(this.state.idCardNumber){
      data.healthCardNo = this.state.idCardNumber
      data.requestType = 'healthId'
    }
    if(this.state.policyNumber){
      data.policyNumber = this.state.policyNumber
      data.requestType = 'policyNumber'
    }
    if(currentClaims == 'op'){
      data.category = currentClaims
    }
    switch(this.state.stageValue)
  {
    case 'mobile number':
      return(
        <SelectMobileNumber
        handleOTP={this.handleOTP}
        mobileNumbers={this.state.mobileNumber}
         />
      )
    case 'OTP sent':
     return( <ClaimsOTP
      classes={classes}
      // nextStage={this.state.nextStage}
      updateSelfServiceState={( mobileNoToken) => 
        this.updateSelfServiceState(mobileNoToken)
      }
      basicDetails = {data}
    />
     )
    case 'Claim Details':
      return(
        <Accordion
        active={0}
        collapses={
            this.state.claimStatusResponse[0].eligibleForRoD==true || this.state.claimCategory == 'op' 
            ? [
          {
              title: `Upload Documents`,
              content: (
                <FileUploadComponent
                  data={this.state.claimStatusResponse[0]}
                  intimationNumber={this.state.intimationNumber}
                  classification={this.state.classification}
                  category={this.state.claimtype}
                  />
              )
            },
          {
            title: `Intimation`,
            content: (
              <ClaimStatusContent
                data={this.state.claimStatusResponse[0]}
              />
            )
          },
          {
            title: `Cashless Details`,
            content: (
              <CashlessContent
                data={
                  this.state.claimStatusResponse[0].cashlessDetails
                }
              />
            )
          },
          {
            title: `Reimbursement Details`,
            content: (
              <ReimbursementContent
                data={
                  this.state.claimStatusResponse[0]
                    
                }
                intimationNumber={this.state.intimationNumber}
                category={this.state.claimtype}
              />
            )
          }
        ]:
        [
          {
            title: <span style={{color: '#FF0000'}}>{this.state.claimStatusResponse&&   this.state.claimStatusResponse[0] && 
              this.state.claimStatusResponse[0].RODIneligibilityReasonMsg && this.state.claimStatusResponse[0].RODIneligibilityReasonMsg}</span>
          },
          
          {
            title: `Intimation`,
            content: (
              <ClaimStatusContent
                data={this.state.claimStatusResponse[0]}
              />
            )
          },
          {
            title: `Cashless Details`,
            content: (
              <CashlessContent
                data={
                  this.state.claimStatusResponse[0].cashlessDetails
                }
              />
            )
          },
          {
            title: `Reimbursement Details`,
            content: (
              <ReimbursementContent
                data={
                  this.state.claimStatusResponse[0]
                    
                }
                intimationNumber={this.state.intimationNumber}
                category={this.state.claimtype}
              />
            )
          }
        ]
      }
      />
      )
    case 'Policy Details':
        return(
        <>
        <GridItem md={12}>
        <ClaimIntimationListForUpload 
        intiamtionDetails={this.state.policyDetailsResponse}
        onSelectClaim={this.claimIntimationCallback}
        />   
        </GridItem>
        </>
        )
  }  }
  toggleSelfService=()=>{
    let {currentClaims}=this.props   
    this.claims = null;
    this.setState({
      stageValue:'',
      intimationNumber:'',
      idCardNumber:'',
      policyNumber:'',
      dob:'',
      errorMsg:'',
      showError:false,
      loading: false, 
      claimtype: currentClaims  
    })
  }
  onClickHealthIdcard=()=>{
    this.setState({
      searchByIdCardNumber:true
    })
  }
  onClickClaimIntimation=()=>{
    this.setState({
    searchByIdCardNumber:false
    })
  }
  onClickMobileNumberforGroup=()=>{
    this.setState({
    searchByMobileNumber:true
    })
  }
  onClickIntimationHealthID=()=>{
    this.setState({
      searchByMobileNumber:false
    })
  }

  handleSimple= event => {
    if(event.target.value == 'IntimationNumber'){
      this.setState({ 
        selectInput: event.target.value, 
        searchByIntimationNumber:true,
        searchByMobileNumber:false,
        searchByIdCardNumber:false,
        searchByPolicyNumber:false, 
        showError:false,
        screenSize: 4
      });
    }
    if(event.target.value == 'MobileNumber'){
      this.setState({ 
        selectInput: event.target.value,
        searchByIntimationNumber:false,
        searchByMobileNumber:true,
        searchByIdCardNumber:false,
        searchByPolicyNumber:false, 
        showError:false,
        screenSize: 4
      });
    }
    if(event.target.value == 'healthIdCard'){
      this.setState({ 
        selectInput: event.target.value,
        searchByIntimationNumber:false,
        searchByMobileNumber:false,
        searchByIdCardNumber:true,
        searchByPolicyNumber:false, 
        showError:false,
        screenSize: 4
      });
    }
    if(event.target.value == 'policyNo'){
      if(this.state.claimtype == `group`){
        this.setState({ 
          selectInput: event.target.value,
          searchByIntimationNumber:false,
          searchByMobileNumber:false,
          searchByIdCardNumber:false,
          searchByPolicyNumber:true, 
          showError:false, 
          screenSize: 3
        });
      }
      else{
        this.setState({ 
          selectInput: event.target.value,
          searchByIntimationNumber:false,
          searchByMobileNumber:false,
          searchByIdCardNumber:false,
          searchByPolicyNumber:true,  
          showError:false,
          screenSize: 4
        });
      }
    }
  }

  renderSelectElement=()=>{
    const { classes} = this.props;
    //let {currentClaims}=this.props
    if(this.state.claimtype == `group`){
      return(
        <Select
          MenuProps={{
            className: classes.selectMenu
          }}
          classes={{
            select: classes.select
          }}
          value={this.state.selectInput}
          onChange={this.handleSimple}
          style={{ display: `block`}}
          inputProps={{
            name: `city`,
            id: `city`
          }}
          input={<Input id="name-error" />}
        >
          <MenuItem
            classes={{
              root: classes.selectMenuItem
            }}
            value="IntimationNumber"
          >
            Intimation Number
          </MenuItem>
          <MenuItem
            classes={{
              root: classes.selectMenuItem,
              selected: classes.selectMenuItemSelected
            }}
            value="policyNo"
          >
            Policy Number
          </MenuItem>
        </Select>
      );
    }
    else{
      return(
        <Select
        MenuProps={{
          className: classes.selectMenu
        }}
        classes={{
          select: classes.select
        }}
        value={this.state.selectInput}
        onChange={this.handleSimple}
        style={{ display: `block`}}
        inputProps={{
          name: `city`,
          id: `city`
        }}
        input={<Input id="name-error" />}
      >
        <MenuItem
          classes={{
            root: classes.selectMenuItem
          }}
          value="IntimationNumber"
        >
          Intimation Number
        </MenuItem>
        {/* <MenuItem
          classes={{
            root: classes.selectMenuItem
          }}
          value="MobileNumber"
        >
          Mobile Number
        </MenuItem> */}
        <MenuItem
          classes={{
            root: classes.selectMenuItem,
            selected: classes.selectMenuItemSelected
          }}
          value="healthIdCard"
        >
          Health ID Card
        </MenuItem>
        <MenuItem
          classes={{
            root: classes.selectMenuItem,
            selected: classes.selectMenuItemSelected
          }}
          value="policyNo"
        >
          Policy Number
        </MenuItem>
      </Select>
      );
    }

  }


  render() {
    let customModalStye = ``;
    const { classes } = this.props;
    // let {currentClaims}=this.props;
    //var dateValidtion = Datetime.moment().add(0, `day`);
    // var valid_default = function(current) {
    //   return current.isBefore(dateValidtion);
    // };
    return (
      <>
      <GridItem xs={12} md={12}>
        <Card className={classes.card}>
          <CardBody
            formHorizontal
            style={{ paddingTop: `0px`, paddingBottom: `0px` }}
          >
            <form>
              <GridContainer>
              {this.props.isGroupProduct== false && this.state.loading == false &&(
              <>
            <GridItem xs={12} sm={4} md={this.state.screenSize} style={{ marginTop: `11px`}}>
                            <FormControl
                              className={classes.formControl}
                              // error={
                              //   this.state.submitButton &&
                              //   this.state.city.length === 0
                              // }
                              fullWidth
                            >
                              <InputLabel
                                htmlFor="city"
                                className={classes.selectLabel}
                              >
                                Choose
                              </InputLabel>
                              {this.renderSelectElement()}
                            </FormControl>
                          </GridItem>
                  {this.state.searchByMobileNumber==true &&(
                 
                  <GridItem xs={12} sm={4} md={this.state.screenSize}>
                  <CustomInput
                    id="RegisteredmobileNo"                   
                    inputProps={{
                      //type:"tel",
                      placeholder: `Registered mobile number`,
                      required: true,
                      pattern: `[0-9]{10}`,
                      maxLength: `10`,
                    }}
                    formControlProps={{
                      fullWidth: true,
                      className: classes.formControl,
                    }}
                    value={this.state.RegisteredmobileNo}
                    onChange={this.handleMobileNumberChange}
                  />
                 
                </GridItem>
               
                )}
                {this.state.searchByIntimationNumber==true &&(
                  <GridItem xs={12} sm={4} md={this.state.screenSize}>
                  <CustomInput
                      id="intimationNumber"
                      inputProps={{
                        placeholder: `Intimation Number`,
                      }}
                      formControlProps={{
                        fullWidth: true,
                        className: classes.formControl,
                      }}
                      value={this.state.intimationNumber}
                      onChange={this.handleChange}
                    />
                  </GridItem>)}

                  {this.state.searchByIdCardNumber==true &&(
                    <GridItem xs={12} sm={4} md={this.state.screenSize}>
                    <CustomInput
                      id="idCardNumber"
                      inputProps={{
                        placeholder: `Primary ID Card Number`,
                      }}
                      formControlProps={{
                        fullWidth: true,
                        className: classes.formControl,
                      }}
                      value={this.state.idCardNumber}
                      onChange={this.handleChange}
                    />
                    </GridItem>
                  )}

{this.state.searchByPolicyNumber==true && this.state.claimtype != `group`&&(
                    <>
                    <GridItem xs={12} sm={4} md={this.state.screenSize}>
                        <CustomInput
                        
                          id="policyNumber"
                          inputProps={{
                            placeholder: `Policy Number`,
                          }}
                          formControlProps={{
                            fullWidth: true,
                            className: classes.formControl,
                          }}
                          value={this.state.policyNumber}
                          onChange={this.handleChange}
                        />
                      </GridItem>
                
                      
                      </>
                  )}

                  {this.state.searchByPolicyNumber==true && this.state.claimtype == `group`&&(
                    <>
                    <GridItem xs={12} sm={4} md={this.state.screenSize}>
                        <CustomInput
                        
                          id="policyNumber"
                          inputProps={{
                            placeholder: `Policy Number`,
                          }}
                          formControlProps={{
                            fullWidth: true,
                            className: classes.formControl,
                          }}
                          value={this.state.policyNumber}
                          onChange={this.handleChange}
                        />
                      </GridItem>
                
                      <GridItem xs={12} sm={4} md={this.state.screenSize}  >
                      {/* <Datetime style={{ marginTop: `11px`}}
                              style={{ marginTop: `10px`, color: `#999`}}
                              timeFormat={false}
                              id='dob'
                              inputProps={{
                                placeholder: `Date of birth of the policy holder(DD-MM-YYYY)`
                              }}
                              className={`${classes.description}`}
                              value={this.state.dob}
                              onChange={
                                this.handleDate
                              }
                              isValidDate={valid_default}
                              closeOnSelect={true}
                            /> */}
                           <CustomInput
                              id="idCardNumber"
                              inputProps={{
                                placeholder: `Primary ID Card Number`,
                              }}
                              formControlProps={{
                                fullWidth: true,
                                className: classes.formControl,
                              }}
                              value={this.state.idCardNumber}
                              onChange={this.handleChange}
                            />
                       
                      </GridItem>
                      </>
                  )}
                  </>
            )}
            {this.props.isGroupProduct==true && this.state.loading == false &&(
              <>
              <GridItem xs={12} sm={4} md={4}>
              <CustomInput
                  id="intimationNumber"
                  inputProps={{
                    placeholder: `Intimation Number`,
                  }}
                  formControlProps={{
                    fullWidth: true,
                    className: classes.formControl,
                  }}
                  value={this.state.intimationNumber}
                  onChange={this.handleChange}
                />
              </GridItem>
                          <GridItem xs={12} sm={4} md={4}>
                          <CustomInput
                            id="idCardNumber"
                            inputProps={{
                              placeholder: `Health ID Card Number`,
                            }}
                            formControlProps={{
                              fullWidth: true,
                              className: classes.formControl,
                            }}
                            value={this.state.idCardNumber}
                            onChange={this.handleChange}
                          />
                          </GridItem>
                        </>
                      )}

            {this.state.loading == false &&  (
              <>
                <GridItem
                  xs={12}
                  sm={4}
                  md={3}
                  style={{
                    alignSelf: `center`
                  }}
                >
                      <Button
                        block
                        color="primary"
                        className={classes.button}
                        onClick={() => this.handleSelectNumber(`largeModal`,'','')}
                      >
                        UPLOAD DOCUMENTS
                      </Button>
                          <>
                    </>
                </GridItem>
                </>
              )}
                
              </GridContainer>
            </form>
            {this.state.loading && (
                    <GridContainer>
                      <div
                        style={{ margin: `0 auto` }}
                        className={classes.loadingGIF}>
                        <img src={loadingGIF} />
                      </div>
                    </GridContainer>
                  )}
          </CardBody>
        </Card>
      </GridItem>
        {this.state.showError==true && (  
          <div id="unMatchedRecords">
            <span
              style={{
                fontSize: `13px`,
                color: `red`,
                textAligin: `center`,
                paddingLeft: `20px`
              }}
            >
              {this.state.errorMsg}
            </span>
          </div>
        )}
        
                         <ModalForm
                 customStyle={customModalStye}
                 title={this.state.stageValue=='OTP sent'?'OTP VALIDATION':this.state.stageValue=='Claim Details'?`Status of Intimation Number ${this.state.intimationNumber}`:this.state.stageValue=="Policy Details"?`Check status for one of the claims of ${this.state.policyNumber ? this.state.policyNumber : this.state.idCardNumber}`:this.state.stageValue=='mobile number'?'Choose Your mobile number':"Please upload the documents to move ahead with your claim."}
                 show={this.state.stageValue=='OTP sent'?true:this.state.stageValue=='Claim Details'?true:this.state.stageValue=='Policy Details'?true:this.state.stageValue=='mobile number'?true:false}
                 handleClose={this.toggleSelfService}
                 showBackButton={
                false
                 }
                 handleBackButton={() => {
                 this.toggleSelfService
                 }}
                 overFlowXHidden={true}>
                 <GridContainer className={this.props.classes.selfServiceContainer}>
                
               {this.getStage()}
                 </GridContainer>
               </ModalForm>
        {this.state.resJsonStatusAfter && (
          <div id="unMatchedRecords">
            <span
              style={{
                fontSize: `13px`,
                color: `red`,
                textAligin: `center`,
                paddingLeft: `20px`
              }}
            >
              {this.state.resJson.message}
            </span>
          </div>
        )}
        {this.state.resJsonStatusError && (
          <div id="unMatchedRecords">
            <span
              style={{
                fontSize: `13px`,
                color: `red`,
                textAligin: `center`,
                paddingLeft: `20px`
              }}
            >
              {
                this.state.errorMessage === `` ?  
                  `System unavailable, please try again later.` :
                  this.state.errorMessage
              }
            </span>
          </div>
        )}
      </>
    );
  }
}

ClaimUploadDocuments.propTypes = {
  classes: PropTypes.object,
  apiBaseUrl: PropTypes.string,
  currentClaims:PropTypes.string,
  isGroupProduct:PropTypes.bool,
};

export default withStyles(claimListStyle)(ClaimUploadDocuments);
