/* eslint-disable */
import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import headerLinksStyle from "assets/jss/material-kit-pro-react/components/topNavHeaderLinksStyle";
import GracePeriodPolicy from "ps-components/Header/GracePeriodPolicyComponent.jsx"
import ModalForm from "ps-components/ModalForm/ModalForm";
import { sevaApp } from 'util/LayoutUtil.jsx';
// import  '../Header/Headerstyle.css'
import 'ps-assets/css/Headerstyle.css'
import treedata from '../../jsons/partner.json'
import treedata1 from '../../jsons/Intermediaries.json'
import treedata2 from '../../jsons/ServiceProviders.json'
import treedata3 from '../../jsons/Employees.json'
import treedata4 from '../../jsons/corporatecustomer.json'
// import PdAccordionTree from "components/PdAccordion/PdAccordionTree";
import Accordion from "components/Accordion/Accordion";
import HeaderAccordian from "components/PdAccordion/HeaderAccordian";

const AGENT_REDIRECTION_URL = process.env.AGENT_REDIRECTION_URL;
const OLD_URL = process.env.BASE_URL_OLD;

class TopNavHeaderLinks extends React.Component {

  state = {
    GracePeriod: false 
  }

  GracePeriodShowService = () => {
  this.setState({
    GracePeriod: !this.state.GracePeriod ,
  })
  } 

  render() {
    const {
       classNamees, 
      tokenSearchString,
       wellnessUrl,
       loggedIn, 
       userId,
       handleLogOut } = this.props;

    return (
      <>
       {this.state.GracePeriod && !sevaApp() ( 
      <ModalForm
            show={this.state.GracePeriod}
            customStyle={`freeQuote`}
            handleClose={() => this.setState({ GracePeriod: false})}
          >
          <GracePeriodPolicy
          GracePeriod={this.state.GracePeriod}
          GracePeriodShowService={this.GracePeriodShowService}
          />
      </ModalForm>
        )}
    <div className="Header_notification-header__a8twB">
      <div className="ant-row ant-row-space-between">
        <div className="ant-col ant-col-sm-8">
          <div className="text-14 flex align-center">
            <div className="ant-row">
              <div className="ant-col ant-col-2 marquee-other-background">
                <div className="text-14 flex align-center">
                  <span className="Header_new-tag__d3zVz text-white mr-8">New</span>
                </div>
              </div>
              <div className="ant-col ant-col-18 overflow-hidden">
                <div className="marquee flex">
                   <p
                    className="bull text-26"></p> &nbsp; <a href="https://bimabharosa.irdai.gov.in/Home/Home">"IGMS is now
                    BIMA BHAROSA "- An Integrated Grievance Management System to facilitate the policy holders and
                    complainants
                    to file their grievances with IRDAI - Click here </a>
                  {/* <!-- <span className="pl-8 button"><a className="cursor-pointer font-700 text-14 pointer"
                      href="tel:7676905905">7676 905 905</a></span> --> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="ant-col ant-col-xs-24 ant-col-sm-16">
          <div className="flex align-center justify-end account-info marquee-other-background" style={{position: 'absolute',left: '-80px'}}>
            <div>
              <a className="text-14 ml-26 flex align-center" href="tel:04469006900">Buy Policy - 044 6900 6900</a>
            </div>
            <div>
              <a className="text-14 ml-26 flex align-center">Download </a>
              </div>
              <div>
              <a href="https://apps.apple.com/us/app/star-health/id1477621177"><img className="img-size" src="./img/apple-img.svg"
                alt="" /></a>
                </div>
                <div>
                <a href="https://play.google.com/store/apps/details?id=com.star.customer_app&hl=en&gl=US"> <img className="img-size" src="./img/playstore-icon.svg" alt="" />
              </a>
            </div>
            {/* <div>
              <a className="text-14 ml-26 flex align-center" href="https://uat.starhealth.in/portability/">Port-In</a>
            </div> */}
            <div>
              <a className="text-14 ml-26 flex align-center" href="https://www.starhealth.in/blog/">Blog</a>
            </div>
            <div className="Header_login-accordion__OlO0O custom-collapse relative">
              <div className="ant-collapse ant-collapse-icon-position-end ant-collapse-ghost">
                <div className="ant-collapse-item">
                  <div className="ant-collapse-header" aria-expanded="false" aria-disabled="false" role="button"
                    tabindex="0">
                    <div className="ant-collapse-expand-icon">
                      <span role="img" aria-label="right" style={{marginTop: '3px'}} className="anticon anticon-right ant-collapse-arrow"><svg
                          viewBox="64 64 896 896" focusable="false" data-icon="right" width="1em" height="1em"
                          fill="currentColor" aria-hidden="true">
                          <path
                            d="M765.7 486.8L314.9 134.7A7.97 7.97 0 00302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 000-50.4z">
                          </path>
                        </svg>
                      </span>
                    </div>
                    <span className="ant-collapse-header-text cursor-pointer">Investors</span>
                    <div className="dropdown-menu">
                    <ul  style={{marginLeft: '20px',marginRight: '20px'}}>
                        <li>
                          <a className="font-14" href="https://www.starhealth.in/investors/about-us/">About Us</a>
                        </li>
                        <li>
                          <a className="font-14" href="https://www.starhealth.in/investors/annual-report/">Annual Report</a>
                        </li>
                        <li>
                          <a className="font-14"
                            href="https://d28c6jni2fmamz.cloudfront.net/DISCLOSURE_UNDER_REG_46_62_OF_SEBI_LODR_85c0993833.pdf">Disclosure
                            Under Regulation 46 & 62 Of SEBI
                            LODR</a>
                        </li>
                        <li>
                          <a className="font-14" href="https://www.starhealth.in/investors/policies/">Policies</a>
                        </li>
                        <li>
                          <a className="font-14" href="https://www.starhealth.in/investors/contact-us/">Contact Us</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="Header_login-accordion__OlO0O custom-collapse relative">
              <div className="ant-collapse ant-collapse-icon-position-end ant-collapse-ghost"  >
                <div className="ant-collapse-item">
                  <div className="ant-collapse-header" aria-expanded="false" aria-disabled="false" role="button"
                    tabindex="0">
                    <div className="ant-collapse-expand-icon">
                      <span role="img" aria-label="right" style={{marginTop: '3px'}} className="anticon anticon-right ant-collapse-arrow"><svg
                          viewBox="64 64 896 896" focusable="false" data-icon="right" width="1em" height="1em"
                          fill="currentColor" aria-hidden="true">
                          <path
                            d="M765.7 486.8L314.9 134.7A7.97 7.97 0 00302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 000-50.4z">
                          </path>
                        </svg>
                      </span>
                    </div>
                    <span className="ant-collapse-header-text cursor-pointer">Partners</span>
                    <div className="dropdown-menu">
                      <ul>
                      <Accordion
                      collapses={[
                        {
                          title: `Agents`,
                          content: (<>{
                            <HeaderAccordian treedata={treedata} />
                          }</>)
                        }
                      ]}
                      headerAccordian={true}
                      icon="./img/right_arrow.png"
                      >
                      </Accordion>
                      <Accordion
                      collapses={[
                        {
                          title: `Intermediaries`,
                          content: (<>{
                            <HeaderAccordian treedata={treedata1} />
                          }</>)
                        }
                      ]}
                      headerAccordian={true}
                      icon="./img/right_arrow.png"
                      >
                      </Accordion>
                      <Accordion
                      collapses={[
                        {
                          title: `Service Providers`,
                          content: (<>{
                            <HeaderAccordian treedata={treedata2} />
                          }</>)
                        }
                      ]}
                      headerAccordian={true}
                      icon="./img/right_arrow.png"
                      >
                      </Accordion>
                      <Accordion
                      collapses={[
                        {
                          title: `Employees`,
                          content: (<>{
                            <HeaderAccordian treedata={treedata3} />
                          }</>)
                        }
                      ]}
                      headerAccordian={true}
                      icon="./img/right_arrow.png"
                      >
                      </Accordion>

                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="Header_login-accordion__OlO0O custom-collapse relative">
              <div className="ant-collapse ant-collapse-icon-position-end ant-collapse-ghost"  style={{marginLeft: '13px',width:'100px'}} >
                {!loggedIn && (<div className="ant-collapse-item Header_sign-in-icon__RY2nt pl-10 Header_dropdown-show__wX_fA" >
                  <div className="ant-collapse-header" aria-expanded="false" aria-disabled="false" role="button"
                    tabindex="0">
                    <div className="ant-collapse-expand-icon">
                      <span role="img" aria-label="right" style={{marginTop: '3px'}} className="anticon anticon-right ant-collapse-arrow"><svg
                          viewBox="64 64 896 896" focusable="false" data-icon="right" width="1em" height="1em"
                          fill="currentColor" aria-hidden="true">
                          <path
                            d="M765.7 486.8L314.9 134.7A7.97 7.97 0 00302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 000-50.4z">
                          </path>
                        </svg>
                      </span>
                    </div>
                    <span className="ant-collapse-header-text" >Sign in</span>
                    <div className="dropdown-menu">
                      <ul>
                        <li style={{marginLeft: '26px'}}>
                          <a target="_blank" className="font-14"
                            href="https://customer.starhealth.in/customerportal/login">Retail Customers</a>
                        </li>
                        <li>
                        <Accordion
                      collapses={[
                        {
                          title: `Corporate Customers`,
                          content: (<>{
                            <HeaderAccordian treedata={treedata4} />
                          }</>)
                        }
                      ]}
                      headerAccordian={true}
                      icon="./img/right_arrow.png"
                      >
                      </Accordion>
                          {/* <div>
                            <div className="flex justify-between relative cursor-pointer">
                              Corporate Customers
                              <span role="img" aria-label="right" className="anticon anticon-right ant-collapse-arrow"><svg
                                  viewBox="64 64 896 896" focusable="false" data-icon="right" width="1em" height="1em"
                                  fill="currentColor" aria-hidden="true">
                                  <path
                                    d="M765.7 486.8L314.9 134.7A7.97 7.97 0 00302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 000-50.4z">
                                  </path>
                                </svg>
                              </span>
                            </div>
                            <ul className="sub-dropdown">
                              <li>
                                <a target="_blank" href="https://portal.starhealth.in/CorpClient/wfrm_Login.aspx"
                                  className="flex align-center">
                                  <img src="./img/employee.svg" className="mr-8" alt="" />
                                  Employee
                                </a>
                              </li>
                              <li>
                                <a target="_blank" href="https://portal.starhealth.in/CorpClient/wfrm_Login.aspx"
                                  className="flex align-center">
                                  <img src="./img/hr.svg" className="mr-8" alt="" />
                                  HR
                                </a>
                              </li>
                            </ul>
                          </div> */}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>)}
                {loggedIn && (<div className="ant-collapse-item pl-10 Header_dropdown-show__wX_fA" >
                  <div className="ant-collapse-header" aria-expanded="false" aria-disabled="false" role="button"
                    tabindex="0">
                      <span className="cursor-pointer" onClick={handleLogOut}>
                      <span>
                    <div className="ant-collapse-expand-icon">
                      <span role="img" aria-label="right" className="anticon anticon-right ant-collapse-arrow"><svg
                          viewBox="64 64 896 896" focusable="false" data-icon="right" width="1em" height="1em"
                          fill="currentColor" aria-hidden="true">
                          <path
                            d="M765.7 486.8L314.9 134.7A7.97 7.97 0 00302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 000-50.4z">
                          </path>
                        </svg>
                      </span>
                    </div>
                    </span>
                    <span className="ant-collapse-header-text">Sign out</span>
                    </span>
                  </div>
                </div>)}
              </div>
            </div>
            {/* <div className="Header_login-accordion__OlO0O custom-collapse relative">
              <div className="ant-collapse ant-collapse-icon-position-end ant-collapse-ghost">
                <div className="ant-collapse-item">
                  <div className="ant-collapse-header" aria-expanded="false" aria-disabled="false" role="button"
                    tabindex="0">
                    <div className="ant-collapse-expand-icon">
                      <span role="img" aria-label="right" className="anticon anticon-right ant-collapse-arrow"><svg
                          viewBox="64 64 896 896" focusable="false" data-icon="right" width="1em" height="1em"
                          fill="currentColor" aria-hidden="true">
                          <path
                            d="M765.7 486.8L314.9 134.7A7.97 7.97 0 00302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 000-50.4z">
                          </path>
                        </svg>
                      </span>
                    </div>
                    <span class="ant-collapse-header-text">
					<div class="Header_lang-icon__DgerT">
					<img height="30px"  width="30px" src="./img/lang-icon.svg" alt="" />
					</div>
					</span>
                    <div className="dropdown-menu">
                      <ul>
                        
						<li style={{marginLeft: '26px'}}>
                          <a target="_self" className="font-14"
                            href="https://uat.starhealth.in/ta/">Tamil</a>
                        </li>
					            	<li style={{marginLeft: '26px'}}>
                          <a target="_self" className="font-14"
                            href="https://uat.starhealth.in/">English</a>
                        </li>
						            <li style={{marginLeft: '26px'}}>
                          <a target="_self" className="font-14"
                            href="https://uat.starhealth.in/hi/">Hindi</a>
                        </li>
						
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
      </>
    );
  }
}

export default withStyles(headerLinksStyle)(TopNavHeaderLinks);