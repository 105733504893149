import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import PdAccordionStyles from "./PdAccordionStyles";
import ExpandMore from "@material-ui/icons/ExpandMore";

const PdAccordion = ({ title, children, classes, mobileView }) => {
    const [open, setOpen] = React.useState(false)
    return (
      <div
        onClick={(e) => {
          e.stopPropagation();
          setOpen(!open);
        }}
      >
        {open ? (     
          <div className={classes.accordion} style={{ display: `flex `, flexDirection: `row`}}>
            {!mobileView && (<div style={{ fontSize: `0.7rem` }}>
              &#9660;&nbsp;
            </div>)}
            <div> {title}</div>
            <div><img src='./img/right_arrow.png' style={{width: '10px', height: '10px', marginLeft: '3px'}} ></img></div>
          </div>
        ) : (
            <div className={classes.accordion} style={{ display: `flex `, flexDirection: `row`}}>
            {!mobileView && (<div style={{ fontSize: `0.7rem` }}>
              &#9654;&nbsp;
            </div>)}
            <div> {title}</div>
            <div><ExpandMore/></div>
          </div>
        )}
        {open && children}
      </div>
    );
}
PdAccordion.propTypes = {
    classes: PropTypes.object,
  };
export default withStyles(PdAccordionStyles)(PdAccordion);