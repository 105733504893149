import React from "react";
import PropTypes from "prop-types";
import GracePeriodPolicyPageStyle from "ps-assets/jss/GracePeriodPolicyPageStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem.jsx";

class GracePeriodPolicyComponent extends React.Component {
    render() {
    let rows = [
      [
        `1`,
        `Family Health Optima Insurance Plan`,
        `SHAHLIP21211V042021`,
        `27.11.2017`,
      ],
      [
        `2`,
        `Medi classic Insurance Policy (Individual) `,
        `SHAHLIP21215V052021`,
        `27.11.2017`,
      ],
      [
        `3`,
        `Star Comprehensive Insurance Policy`,
        `SHAHLIP21263V062021`,
        `27.11.2017`,
      ],
    ];
    rows = rows
      .sort(
        (a, b) =>
          new Date(
            b[3]
              .split(`.`)
              .reverse()
              .join(`-`)
          ) -
          new Date(
            a[3]
              .split(`.`)
              .reverse()
              .join(`-`)
          )
      )
      .map((a, key) => {
        a.shift();
        a.unshift(key + 1);
        a[3] = a[3].split(`.`).join(`-`);
        return a;
      });
    const { classes } = this.props;
    // try {
    //   image = data.allStrapiImages.edges[0].node.content.childImageSharp.fluid;
    // } catch {
    //   image = null;
    // }
    return (
     <>
       
          {/* <Layout image1={image} image2={image} small={true}> */}
            {/* <Helmet key="helmetTags">
              <title>List of Products | StarHealth.in</title>
            </Helmet> */}
            {/* {process.env.ENABLE_HELMET == `true` && (
              <Helmet key="helmetTags">
                <link rel="canonical" href={url + `grace-period-policy`} />
                <meta name="title" content="List of Products | StarHealth.in" />
                <meta
                  name="twitter:title"
                  content="List of Products | StarHealth.in"
                />
                <meta
                  name="description"
                  content="List of Health Insurance Policy, Accident Insurance, Travel Insurance and Combi Products available in Star Health"
                />
                <meta
                  name="keywords"
                  content="Star Health Insurance Plans,Insurance Policy,Star Health Products,Health Insurance Specialist in India, Star Health and Allied Insurance Company Limited, Chennai, India"
                />
                <meta
                  property="og:title"
                  content="List of Products | StarHealth.in"
                />
                <meta
                  property="og:description"
                  content="List of Health Insurance Policy, Accident Insurance, Travel Insurance and Combi Products available in Star Health"
                />
                <meta property="og:url" content={url + `grace-period-policy`} />
                <meta name="List of Products | StarHealth.in" />
                <meta
                  name="twitter:description"
                  content="List of Health Insurance Policy, Accident Insurance, Travel Insurance and Combi Products available in Star Health"
                />
                <meta
                  property="twitter:url"
                  content={url + `grace-period-policy`}
                />
              </Helmet>
            )} */}
            <div>
                <div style = {{
                  paddingBottom: '12px'
                }}
                className={classes.container}>
                  <GridContainer className={classes.container}>
                    <GridItem
                    style={{
                      padding:'0px'
                    }}
                      md={9}
                      className={`${classes.mrAuto} ${classes.mlAuto} ${classes.textCenter}`}
                    >
                      <div
                        style={{
                          width: "100%",
                          textAlign: "center",
                          lineHeight: "30px",
                        }}
                      >
                        <div style={{ fontWeight: "800"}}>
                          <div style={{ fontSize: "17px", textAlign: 'left' }}>
                          1. The Following Policies which have expired on or after 1st July 2020 to 31st March 2021, Grace Period with continuity benefit will be available until 30th June 2021.
                          </div>
                          <div style={{ fontSize: "17px", textAlign: 'left' }}>
                            2. The actual cover will commence prospectively from
                            the date of receipt of premium by Star Health
                          </div>
                        </div>
                      </div>
                    </GridItem>
                    <GridItem
                      md={10}
                      className={`${classes.mrAuto} ${classes.mlAuto} ${classes.textCenter}`}
                    >
                      <div style={{ maxWidth: `100%` }}>
                        <table style={{width:'100%'}}>
                          <tr>
                            <th className={classes.cursorHover}>
                            S.No
                            </th>
                            <th  className={classes.cursorHover}>
                            Product Name
                            </th>
                            <th  className={classes.cursorHover}>
                            Unique ID
                            </th>
                          </tr>
                          {rows && rows.map((res, index) => {
                            return (
                            <tr key ={index}>
                              <td className={classes.contentStyle}>
                               {res[0]}
                              </td>
                              <td className={classes.contentStyle}>
                              {res[1]}
                              </td>
                              <td  className={classes.contentStyle}>
                              {res[2]}
                              </td> 
                            </tr>
                          )})}
                        </table>
                      </div>
                    </GridItem>
                  </GridContainer>
                </div>
            </div>
          {/* </Layout> */}
          </>
    );
  }
}

GracePeriodPolicyComponent.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(GracePeriodPolicyPageStyle)(
  GracePeriodPolicyComponent
);

// GracePeriodPolicyComponent.propTypes = {
//   classes: PropTypes.object,
//   data: PropTypes.object,
// };

// export const termsBenefit = graphql`
//   query GracePeriodPolicyComponent {
//     allStrapiImages(filter: { category: { eq: "grace_period" } }) {
//       edges {
//         node {
//           content {
//             publicURL
//             childImageSharp {
//               fluid(quality: 100) {
//                 ...GatsbyImageSharpFluid_withWebp
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// `;
