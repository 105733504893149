import React from "react";

// nodejs library that concatenates classes
import classNames from "classnames";

// @material-ui/core components
import Accordion from "components/Accordion/Accordion";
import withStyles from "@material-ui/core/styles/withStyles";

import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
import GridContainer from "components/Grid/GridContainer";
import aboutUsStyle from "ps-assets/jss/aboutUsStyle.jsx";

class CashlessContent extends React.Component {
  render() {
    let { classes, data } = this.props;
    return (
      <div style={{ margin: `0px 20px` }}>
        <Accordion
          active={0}
          collapses={data.map(data => {
            var date = new Date(data.documentReceivedDate);
            return {
              title: data ? data.cashlessType : ``,
              content: (
                <GridContainer
                  className={classNames(classes.mrAuto, classes.mlAuto)}
                  style={{ fontSize: `13px`, paddingLeft: `20px` }}
                >
                  <GridItem xs={12} sm={4} md={4}>
                    <b>Claimed Amount</b>
                    <div style={{ minHeight: 20 }}>
                      {data ? data.claimedAmount : ``}
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={4} md={4}>
                    <b>Approved Amount</b>
                    <div style={{ minHeight: 20 }}>
                      {data ? data.approvedAmount : ``}
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={4} md={4}>
                    <b>Document Received Date</b>
                    <div style={{ minHeight: 20 }}>
                      {data && date.length>0 ? date.toLocaleDateString() : `-`}
                    </div>
                  </GridItem>
                </GridContainer>
              )
            };
          })}
        />
      </div>
    );
  }
}

export default withStyles(aboutUsStyle)(CashlessContent);

CashlessContent.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object
};
