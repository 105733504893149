import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";

// @material-ui/icons
import ExpandMore from "@material-ui/icons/ExpandMore";

import accordionStyle from "assets/jss/material-kit-pro-react/components/accordionStyle.jsx";

class Accordion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: props.active,
      single: false
    };
  }

  componentWillMount() {
    // am facut array din numar ca metoda .find sa functioneze indiferent de ce se intampla.
    if (this.state.active.length === undefined) {
      this.setState({
        active: [this.state.active],
        single: true
      });
    }
  }

  handleChange = (panel, onChangeHandler) => (event, expanded) => {
    let newArray;

    //if forceInActive is true - make newArray empty - basically shrink all panels
    if (this.state.single) {
      if (this.state.active[0] === panel) {
        newArray = [];
      } else {
        newArray = [panel];
      }
    } else {
      if (this.state.active.indexOf(panel) === -1) {
        newArray = [...this.state.active, panel];
      } else {
        newArray = [...this.state.active];
        newArray.splice(this.state.active.indexOf(panel), 1);
      }
    }

    if(this.props.onChangeHandler) this.props.onChangeHandler(newArray);
    //check if panel part of newArray - i.e if panel is expanded
    const isPanelExpanded = newArray.includes(panel);

    //send the info to onChangeHandler - prop sent by parent to get notified about the expanded status of panel
    if(onChangeHandler) onChangeHandler(isPanelExpanded);

    this.setState({
      active: newArray
    });
  };

  render() {
    const { classes, collapses, activeColor, borderBottomColor , login , icon } = this.props;
    this.checkForceInActiveAndResetActiveArray();
    return (
      <div className={this.props.noBorderIcon=='rm_Margin' ? classes.withoutMarginBottomroot : classes.root}>
        {collapses.map((prop, key) => {
          return (
            <ExpansionPanel
              expanded={
                !this.props.forceInActive && (
                this.state.active === key ||
                this.state.active.indexOf(key) !== -1)
              }
              onChange={this.handleChange(key,prop.onChangeHandler)}
              key={key}
              classes={{
                root: classes.expansionPanel,
                expanded: classes.expansionPanelExpanded
              }}
            >
              <ExpansionPanelSummary
                expandIcon={(this.props.noBorderIcon && this.props.noBorderIcon!='rm_Margin') ? '':<ExpandMore />}
                classes={{
                  root: `${classes[login + (this.props.headerAccordian ? `headerAccordian` : this.props.removeSummaryPadding ? `rmpaddingexpansionPanelSummary` : `expansionPanelSummary`)]} ${
                    classes[activeColor + (this.props.headerAccordian ? `headerAccordian` : this.props.removeSummaryPadding ? `rmpaddingexpansionPanelSummary` :`ExpansionPanelSummary`)]
                  }`,
                  expanded: `${classes.expansionPanelSummaryExpaned} ${
                    classes[activeColor + `ExpansionPanelSummaryExpaned`]
                  }`,
                  content: classes.expansionPanelSummaryContent,
                  expandIcon: classes[icon + `expansionPanelSummaryExpandIcon`]
                }}
                // style={{
                //   borderBottom:
                //   (this.props.noBorderIcon && this.props.noBorderIcon!='rm_Margin') ? "0px" : (login === ``
                //       ? borderBottomColor
                //         ? "1px solid " + borderBottomColor
                //         : "1px solid #ddd"
                //       : "none"),
                // }}
                style={(this.props.headerAccordian || this.props.whiteText) ? {borderBottom: 'none'} : (!this.props.noBorderIcon || this.props.noBorderIcon=='rm_Margin') ? {borderBottom: login === `` ? borderBottomColor ? "1px solid " + borderBottomColor: "1px solid #ddd" : "none"} :{
                borderBottom:'0px',
                padding:'1px 10px 5px 0px'
              }}
              >
                <h4 className={`${classes[login + `title`]}  ${this.props.mobileView && classes.mobileView}`} style={(!this.props.noBorderIcon || this.props.noBorderIcon=='rm_Margin') ? this.props.whiteText == true ? {textAlign: `left`,color:'white',fontFamily: 'Manrope',fontSize: '16px'} : this.props.whiteText == false ? {textAlign: `left`,fontFamily: 'Manrope',fontSize: '16px', color: 'rgba(0,0,0,.85)'}: { textAlign: `left` } : 
                this.props.whiteText ? {
                  textAlign: `left`,
                  color:'white',
                  fontFamily: 'Manrope',
                  fontSize: '16px'
                } 
                : {
                  textAlign: `left`,
                  color:'#35488a'
                }
                }>
                  {prop.title}
                </h4>
                {this.props.headerAccordian ? <img srcc={icon}></img> : ''}
              </ExpansionPanelSummary>
              <div style={{ textAlign: `left` }}>
                <ExpansionPanelDetails
                  className={this.props.noBorderIcon=='true'? classes.lessPaddingexpansionPanelDetails : classes.expansionPanelDetails}
                >
                 { prop.content}
                </ExpansionPanelDetails>
              </div>
            </ExpansionPanel>
          );
        })}
      </div>
    );
  }

  checkForceInActiveAndResetActiveArray() {
    if (this.props.forceInActive && this.state.active.length > 0) {
      this.setState({
        active: []
      });
    }
  }
}

Accordion.defaultProps = {
  active: -1,
  activeColor: `primary`,
  login: ``,
  icon: ``
};

Accordion.propTypes = {
  classes: PropTypes.object.isRequired,
  // index of the default active collapse
  active: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.number)
  ]),
  collapses: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      content: PropTypes.node
    })
  ).isRequired,
  activeColor: PropTypes.oneOf([
    `primary`,
    `secondary`,
    `warning`,
    `danger`,
    `success`,
    `info`,
    `rose`
  ]),
  login: PropTypes.string,
  icon: PropTypes.string
};

export default withStyles(accordionStyle)(Accordion);
