import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import wizardStyle from "ps-assets/jss/wizardStyle.jsx";
import Button from "components/CustomButtons/Button";
import CustomInput from "components/CustomInput/CustomInput";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem.jsx";
import CanvasComponent from "ps-components/Canvas/Canvas";
import PropTypes from "prop-types";
import FormHelperText from "@material-ui/core/FormHelperText";
import Refreshs from "assets/img/reload.png";
class Captcha extends React.Component {
  state = {
    captchaText: ``,
    submitted: false,
    inputText: ``
  };

  setCaptcha = () => {
    let newCaptcha = this.getCaptcha(7);
    this.setState({
      captchaText: newCaptcha,
      inputText: ``
    });
    this.props.updateRefresh();
  };

  getCaptcha = length => {
    var result = ``;
    var characters = `ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789`;
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result.split(``).join(` `);
  };

  handleChange = event => {
    let inputValue = event.target.value;
    this.props.setCaptchaText(this.state.captchaText, inputValue);
    this.setState({
      inputText: inputValue
    });
  };

  componentDidMount() {
    this.setCaptcha();
  }

  componentDidUpdate() {
    if (this.props.refresh)
      this.setCaptcha();
  }

  render() {
    let { captchaText } = this.state;

    return (
      <GridContainer>
        <GridItem md={2}>
          <div
            style={{
              textAlign: `center`
            }}
          >
            <CanvasComponent text={captchaText} height={45} />
          </div>
        </GridItem>
        <GridItem md={2} style={{ marginLeft: `20px`, marginRight: `20px`, textAlign: `center` }}>
          <Button
            simple
            onClick={this.setCaptcha}
            style={{ marginLeft: `33px`, marginTop: `0px` }}
          >
            <img src={Refreshs} style={{ marginLeft: `auto`, marginRight: `auto` }} />
          </Button>
        </GridItem>
        <GridItem md={this.props.nextLine ? 12 : 7}>
          <div>
            <CustomInput
              labelText="Enter the captcha text*"
              formControlProps={{
                fullWidth: this.props.fullWidth,
                style: {
                  paddingTop: `10px`
                }
              }}
              labelProps={{
                style: {
                  top: `-8px`,
                  fontSize: `11.5px !important`
                }
              }}
              inputProps={
                {
                  // pattern: captchaText.split(` `).join(``)
                }
              }
              onChange={this.handleChange}
              value={this.state.inputText}
              error={
                this.props.submitButton &&
                this.state.captchaText.split(` `).join(``) !==
                this.state.inputText
              }
            />
            {this.props.submitButton &&
              this.state.inputText &&
              this.state.captchaText.split(` `).join(``) !==
              this.state.inputText && (
              <FormHelperText id="my-helper-text" error>
                  Match the requested format
              </FormHelperText>
            )}
          </div>
        </GridItem>
      </GridContainer>
    );

  }
}
Captcha.propTypes = {
  updateRefresh: PropTypes.func,
  refresh: PropTypes.bool,
  nextLine: PropTypes.bool,
  fullWidth: PropTypes.bool,
  submitButton: PropTypes.bool,
  setCaptchaText: PropTypes.func
};

export default withStyles(wizardStyle)(Captcha);
