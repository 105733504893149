import React from "react";
// import { withPrefix } from "gatsby";

// import $ from "jquery"
import "lib/waybeolib/css/normalpop.css";
import { wizardLMSCall } from 'util/lmsRequest';
import "lib/waybeolib/css/intlTelInput.css";
// import root from 'window-or-global';
// import { lmsRequestCall } from "util/lmsRequest";
import { browserWindow,browserDocument } from "util/localStorageHelper";

class Callback extends React.Component {
  state = {
    name: ``,
    mobile: ``,
    isLoaded: false,
    loading:false,
    openThankyou:false,
    error:false,
    errMsg:'',
    isValidMobNo:true
  }

  componentDidMount(){
    browserWindow.addEventListener(`load`, () => {
      if(browserDocument.readyState === `complete`){
        this.setState({
          isLoaded: true
        });
      }
    });
  }

  handleChange = e => {
    if(e.target.id == 'normalName'){
      if(e.target.value[0] != ' '){
        this.setState({
          [e.target.name]: e.target.value
        });
      }
    }
    else{
      this.setState({
        [e.target.name]: e.target.value
      });
    }
  }
  
  handleClick = async() => {
    let { name, mobile} = this.state;
      if((mobile && mobile.includes('+') && mobile.split(' ')[1].length < 10) || (mobile && !mobile.includes('+') && mobile.length < 10)){
        this.setState({
          isValidMobNo:false
        })
    }else{
    this.setState({
          loading:true
    })
    if(!mobile){
      this.setState({
        loading:false,
        openThankyou:false,
        error:true,
        errMsg : 'Number verification failed.'
      })
    }
    else{
    let response = await wizardLMSCall(
      name,
      mobile,
      "",
      "",
      "",
      "",
      "",
      "Click to Call"
    );
    response = await response.json();
      if(response && response.status=='success')
      {
        this.setState({
          loading:false,
          openThankyou:true,
          error:false
        })
      }else{
        this.setState({
          loading:false,
          openThankyou:false,
          error:true,
          errMsg : response && response.status=='error' ? response.message : 'Number verification failed.'
        })
      }
    }
  }
  }

  handleMobileNumberChange = (e) => {
    let mobNumber = e.target.value.split(` `)[1];
    if(e.target.value.split(` `).length ===1){
      this.setState({
        [e.target.name]: e.target.value+` `
      });
    }
    else{
      if(!isNaN(mobNumber)){
        if(mobNumber.length <= 10){
          this.setState({
            [e.target.name]: e.target.value
          });
        }
      }
    }
    if((mobNumber && mobNumber.includes('+') && mobNumber.split(' ')[1].length == 10) || (mobNumber && !mobNumber.includes('+') && mobNumber.length == 10))
    {
      this.setState({
        isValidMobNo:true
      })
    }
  }
  handleClose=()=>{
    this.setState({
      ...this.initialState
    })
  }
  componentWillMount=()=>{
    this.initialState=this.state
  }

  
  render() {
    return (
      <div>
        <div className="wbf-screen">
          <div className="wbf-container theme-peter-river">
            <div className="wbf-header">
              <div className="wbf-mainheader">Welcome to Star Health</div>
              <div className="wbf-availability available">
                <div className="wbf-availability-icon" />
                <div className="wbf-availability-msg ">Available</div>
              </div>
              <div className="wbf-clear" />
            </div>
            {browserDocument.readyState !== `complete` && <div style={{textAlign: `center`, marginLeft: `auto`, marginRight: `auto`, padding: `20px`}}>
              <img  src={require(`ps-assets/img/star2.gif`)}></img>
            </div>}
            {browserDocument.readyState === `complete` && <div className="wbf-form">

              {this.state.loading ? (<div  className="callback" 
                  style={{textAlign:'center',opacity: 1,
                  background: '#fff',
                  color:'black',
                  fontFamily: 'Roboto,Times New Roman,sans-serif',
                  fontSize: '16px'}}
                  >
                 Connecting now...
                   </div>) : this.state.error ? (<div  className="callback" 
                  style={{textAlign:'center',opacity: 1,
                  background: '#fff',
                  color:'black',
                  fontFamily: 'Roboto,Times New Roman,sans-serif',
                  fontSize: '16px'}}
                  >
              {this.state.errMsg}
              </div>) : this.state.openThankyou ? (<div  className="callback" style={{textAlign:'center',opacity: '1',
                    color:'black',
                    fontFamily: 'Roboto,Times New Roman,sans-serif',
                    fontSize: '16px',}}>
                Thanks for showing your interest. Our expert will get in touch with you shortly.
              </div>) : (<div className="wbf-window">
                <div className="wbf-status">
                  <div className="wbf-message wbf-centered">
                    Please select your country and enter your phone number
                  </div>
                </div>
                <div className="intl-tel-input pretty inside">
                  <label htmlFor="normalName" />
                  <input
                    aria-invalid={`false`}
                    aria-label={`normalName`}
                    type="text"
                    className="wbf-numberinput"
                    size="60"
                    name="name"
                    id="normalName"
                    placeholder="Enter Your Name"
                    required
                    value={this.state.name}
                    onChange={this.handleChange}
                  />
                </div>
                <div>
                  <label htmlFor="normalMobile" />
                  <input
                    aria-invalid={`false`}
                    aria-label={`normalMobile`}
                    type="tel"
                    className="wbf-numberinput"
                    size="15"
                    maxLength="20"
                    name="mobile"
                    id="normalMobile"
                    placeholder="Enter Phone Number"
                    value={this.state.mobile}
                    onChange={this.handleMobileNumberChange}
                  />
                </div>
                {!this.state.isValidMobNo && (
                  <div style={{color:'red',width:'100%',textAlign:'center',opacity: 1,
                  background: '#fff',
                  fontFamily: 'Roboto,Times New Roman,sans-serif',
                  fontSize: '16px'}} className="ValidMobNo">
                  Please enter a valid phone number
                </div>
                )}
                <div className="wbf-privacy">
                  *I hereby authorize Star Health Insurance to contact me. It
                  will override my registry on the NCPR.   
                </div>
                <div className="callback">
                  <input
                    type="button"
                    style={{width: 'auto',
                      color: '#fff',
                      fontWeight: '600',
                      background: '#35488a',
                      textTransform: 'uppercase',
                      borderRadius: '5px',
                      height: '40px',
                      margin: '15px auto 0',
                      padding: '0 20px',
                      display: 'block',
                      border: '1px solid #9d9d9d',
                      cursor: 'pointer',}}
                    value="Talk to an Expert"
                    id="callback"
                    onClick={this.handleClick}
                  />
                </div>
              </div>)}
              <div className="wbf-livestatus">
                <div className="wbf-livemsg-connecting">Connecting now...</div>
                <div className="wbf-livemsg-connected">
                  Connection Established.
                </div>
                <div className="wbf-livemsg-verifying">
                  Please verify your number using the code below.
                  <div className="wbf-verificationcode">11001</div>
                </div>
                <div className="wbf-livemsg-verification-success">
                  Number verification successful
                </div>
                <div className="wbf-livemsg-verification-failed">
                  Number verification failed.
                </div>
                <div className="wbf-livemsg-in-progress">Call in progress.</div>
                <div className="wbf-livemsg-completed">
                  Call Completed Successfully.
                </div>
                <div className="wbf-livemsg-ended">Call ended.</div>
                <div className="wbf-livemsg-agent-busy">Agent busy.</div>
                <div className="wbf-livemsg-oops">
                  Thanks for showing your interest. Our expert will get in touch with you shortly.
                </div>
                <div className="wbf-livemsg-timer timer">00:00:00</div>
              </div>
            </div>}
            <div className="wbf-footer">
              <div className="wbf-close" onClick={this.handleClose}>
                <b style={{ color: `#000000` }}>Close</b>
              </div>
              <div className="wbf-clear" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Callback;
