import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import PdAccordion from "./PdAccordion";
import * as treeclasses from "./PdAccordionTreeStyles.module.css";

const HeaderAccordian = ({ treedata, styletype }) => {
    return (
      <div className={treeclasses[styletype]} >
        {treedata &&
          treedata.map((item) => {

            return (
              <a
                className={treeclasses.headerLink}
                href={item.link ? item.link : ``}
                target="_blank"
                alt="public disclosure link"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <img src={item["img"]} style={{marginRight: '10px'}}/>{item["title"]}
              </a>
            );
          })}
      </div>
    );
}
HeaderAccordian.propTypes = {
    classes: PropTypes.object,
  };
export default withStyles()(HeaderAccordian);