import {
  container,
  title,
  description
} from "assets/jss/material-kit-pro-react.jsx";

import imagesStyles from "assets/jss/material-kit-pro-react/imagesStyles.jsx";
import styles from "assets/jss/material-kit-pro-react/views/componentsSections/footerStyle.jsx";

const footerSectionStyle = {
  ...styles,
  ...imagesStyles,
  container,
  title,
  description,
  sectionTitle: {
    fontFamily: `Roboto !important`,
    fontSize: `15px !important`,
    fontWeight: `500 !important`,
    fontStyle: `normal`,
    fontStretch: `normal`,
    lineHeight: `normal`,
    letterSpacing: `normal`,
    textAlign: `left`,
    color: `rgba(255, 255, 255, 0.8) !important`,
    // "@media (max-width: 310px)": {
    //   fontSize: `1.3em !important`
    // }
  },
  footerlinks: {
    fontSize: `12px`
    // "@media (max-width: 310px)": {
    //   fontSize: `1.0em !important`
    // }
  },
  sectionBody: {
    width: `100% !important`,
    whiteSpace: `nowrap`,
    "& li": {
      display: `block !important`,
      color: `rgba(180, 180, 180, 1) !important`
    },
    "& li a": {
      display: `inline-block !important`,
      marginBottom: `10px`,
      padding: `0px !important`,
      paddingRight: `1.7rem !important`,
      fontSize: `12px`
    }
  },
  customfooterLink: {
    minWidth: `48px`,
    paddingLeft: `0 !important`,
    paddingRight: `0 !important`
  },
  linksHorizontal: {
    "& li": {
      display: `inline !important`,
      "& a": {
        display: `inline !important`,
        padding: `5px !important`
      }
    }
  }
};

export default footerSectionStyle;
