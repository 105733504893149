import {
  container,
  main,
  mainRaised,
  title,
  whiteColor,
  grayColor,
  section,
  mrAuto,
  mlAuto,
  cardTitle,
  primaryColor
} from "assets/jss/material-kit-pro-react.jsx";

const layoutStyle = theme => ({
  main,
  mainRaised,
  mrAuto,
  mlAuto,
  title,
  cardTitle,
  pageTitle: {
    ...title,
    marginTop: `0`,
    marginBottom: `0`,
    color: whiteColor,
    fontSize:'36px'
  },
  summary: {
    marginTop: `6px`,
    marginBottom: `0`,
    color: whiteColor,
    fontSize: '18px'
  },
  textCenter: {
    textAlign: `center !important`
  },
  container: {
    ...container,
    zIndex: `2`
  },
  customCarousel: {
    height: `100%`
  },
  mediafooter: {
    fontSize: `15px !important`
    // "@media (max-width: 576px)": {
    //   fontSize: `1.3em !important`
    // }
  },
  brand: {
    marginBottom: `0`,
    paddingLeft: `20px`,
    height: `60px`,
    [theme.breakpoints.down(`sm`)]: {
      height: `40px`,
      paddingLeft: `0px`
    },
    "& h2, & h4": {
      color: whiteColor
    }
  },
  link: {
    textDecoration: `none`
  },
  description: {
    color: grayColor[0],
    fontWeight: `300`
  },
  contactPosition:{
    textAlign:'right',
    marginTop: '-7%',
    paddingBottom: '4%',
    paddingRight:'4%',
    },
  section: {
    ...section,
    padding: `70px 0px`,
    "& h4$description": {
      fontSize: `1.5em`
    }
  },
  list: {
    marginBottom: `0`,
    padding: `0`,
    marginTop: `0`
  },
  inlineBlock: {
    display: `inline-block`,
    padding: `0px`,
    width: `auto`
  },
  left: {
    float: `left!important`,
    display: `block`
  },
  right: {
    padding: `15px 0`,
    margin: `0`,
    float: `right`
  },
  aClass: {
    textDecoration: `none`,
    backgroundColor: `transparent`
  },
  block: {
    color: `inherit`,
    padding: `0.9375rem`,
    fontWeight: `500`,
    fontSize: `12px`,
    textTransform: `uppercase`,
    borderRadius: `3px`,
    textDecoration: `none`,
    position: `relative`,
    display: `block`
  },
  footerBrand: {
    height: `50px`,
    padding: `15px 15px`,
    fontSize: `18px`,
    lineHeight: `50px`,
    marginLeft: `-15px`,
    color: grayColor[1],
    textDecoration: `none`,
    fontWeight: 700,
    fontFamily: `Roboto Slab,Times New Roman,serif`
  },
  pullCenter: {
    display: `inline-block`,
    float: `none`
  },
  rightLinks: {
    float: `right!important`,
    "& ul": {
      marginBottom: 0,
      padding: 0,
      listStyle: `none`,
      "& li": {
        display: `inline-block`
      },
      "& a": {
        display: `block`
      }
    },
    "& i": {
      fontSize: `20px`
    }
  },
  linksVertical: {
    "& li": {
      display: `block !important`,
      marginLeft: `-5px`,
      marginRight: `-5px`,
      "& a": {
        padding: `5px !important`
      }
    }
  },
  footer: {
    "& ul li": {
      display: `inline-block`
    },
    "& h4, & h5": {
      color: grayColor[1],
      textDecoration: `none`
    },
    "& ul:not($socialButtons) li a": {
      color: `inherit`,
      padding: `0.9375rem`,
      fontWeight: `500`,
      fontSize: `12px`,
      textTransform: `uppercase`,
      borderRadius: `3px`,
      textDecoration: `none`,
      position: `relative`,
      display: `block`
    },
    "& small": {
      fontSize: `75%`,
      color: grayColor[10]
    },
    "& $pullCenter": {
      display: `inline-block`,
      float: `none`
    }
  },
  iconSocial: {
    width: `41px`,
    height: `41px`,
    fontSize: `24px`,
    minWidth: `41px`,
    padding: 0,
    overflow: `hidden`,
    position: `relative`
  },
  copyRight: {
    padding: `15px 0px`
  },
  socialButtons: {
    "& li": {
      display: `inline-block`
    }
  },
  pageHeader: {
    position: `relative`,
    backgroundPosition: `50%`,
    backgroundSize: `cover`,
    margin: `0`,
    padding: `0`,
    border: `0`,
    display: `flex`,
    transition: `background-image 1s ease-in-out`,
    "&:hover ": {
      backgroundImage: `url(${require(`ps-assets/img/LP_Banner1_CLR.png`)})`
    },
    "@media (max-width: 768px)": {
      height: `auto !important`
    }
  },
  socialIcon: {
    // padding: `10px`,
    "& svg": {
      width: `100%`,
      height: `100%`,
      "& path": {
        fill: whiteColor
      },
      "& rect": {
        fill: whiteColor
      }
    }
  },
  headerHeight: {
    marginTop: 70,
    [theme.breakpoints.down(`md`)]: {
      marginTop: 50
    }
  },
  paddingSocial: {
    padding: 10
  },
  accordionStyle: {
    backgroundColor: `transparent`,
    "& svg": {
      fill: `gray`
    }
  },
  disable: {
    display: `none`
  },
  removeShake: {
    animation: `none !important`,
    [theme.breakpoints.down(`xs`)]:{
      bottom: `0px`,
      right: `0px`,
      top: `30px`
    }
  },
  feedbackIcon: {
    zIndex: `9999`,
    position: `fixed`,
    cursor: `pointer`,
    top: `42%`,
    bottom: `42%`,
    right: `0`,
    "& img": {
      width: `80%`,
      float: `right`
    }
  },
  autoPopup: {
    zIndex: 99999,
    width: `300px`,
    position: `fixed`,
    overflow: `hidden`,
    cursor: `pointer`,
    right: `1.3em`,
    bottom: `4em`,
    padding: `5px`
  },
  notification: {
    position: `absolute`,
    right: `0.5em`,
    bottom: `2.0em`,
    backgroundColor: `white`,
    color: primaryColor[0],
    border: `solid 2px ` + primaryColor[0],
    borderRadius: `50%`,
    width: `25px`,
    height: `25px`,
    textAlign: `center`
  },
  remyContainer: {
    height: `540px`,
    width: `380px`,
    [theme.breakpoints.down(`xs`)]: {
      height: `calc(100% - 55px)`,
      width: `100%`,
      right: 0,
      top: `25px`,
      "& iframe":{
        borderRadius: `0 !important`
      }
    }
  },
  titlePadding: {
    paddingBottom: `200px`
  },
  instantHeaderHeight: {
    marginTop: `1%`
  },
  grid: {
    width: `100%`,
    display: `flex`,
    flexWrap: `wrap`,
    boxSizing: `border-box`
  },
  instantBuyFooter: {
    width: `auto`
  },
  instantBuyFooterContent: {
    position: `relative`,
    minHeight: `1px`,
    paddingLeft: `15px`,
    paddingRight: `15px`
  },
  mobileWidth:{

    "@media (max-width: 768px)": {

      width: '400px'

    }

  }
});

export default layoutStyle;
