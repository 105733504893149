import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import ReactSVG from "react-svg";

import navPillsStyle from "assets/jss/material-kit-pro-react/components/navPillsStyle.jsx";
import { browserWindow } from 'util/localStorageHelper';
import { sevaApp } from 'util/LayoutUtil.jsx';
import { navigate } from '@reach/router';

const CUSTOMERPORTAL_URL = process.env.CUSTOMERPORTAL_URL
class NavPills extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: props.active,
      hashRoute: null
    };
  }
  handleChange = (event, active) => {
    const {tabs}=this.props;
    let navigateURL=tabs ? tabs[active] ? tabs[active].navigateURL ? tabs[active].navigateURL : '' : '' : '';
    let claimitimationURL = sevaApp() ? `${CUSTOMERPORTAL_URL}/customerportal/claimIntimationWithoutLogin?sevaapp=true` : `${CUSTOMERPORTAL_URL}/customerportal/claimIntimationWithoutLogin`
    if(tabs[active].tabButton == `Claims Intimation`){
      navigate(claimitimationURL, `_self`);
      // browserWindow.location.href='/customerportal/claimIntimationWithoutLogin';
      }
    else if(tabs[active].tabButton == `Claims Process` || tabs[active].tabButton == `Claims Helpdesk`){
      browserWindow.location.href = navigateURL
    }
    // else if(tabs[active].tabButton == `Claims Status` || tabs[active].tabButton == `Claim Upload`){
    //   browserWindow.location.href = "/under-maintenance"
    // }
    else{
    this.props.changeMetaTag && this.props.changeMetaTag(active);
    this.props.onTabSwitch && this.props.onTabSwitch(active);
    this.props.title && this.props.title(active);
    this.setState({ active });
    }
  };
  handleChangeIndex = index => {
    this.setState({ active: index });
      };

  componentDidUpdate() {
    if (
      this.props.location &&
      this.props.location.hash &&
      this.props.location.hash.split("#")[1] &&
      this.state.hashRoute !== parseInt(this.props.location.hash.split("#")[1])
    ) {
      var activeStep = parseInt(this.props.location.hash.split("#")[1]);
      this.setState({ active: activeStep, hashRoute: activeStep });
    }
  }

  componentDidMount() {
    if(this.props.active){
      this.setState({
        active:this.props.active
      })
    }
    if (
      this.props.location &&
      this.props.location.hash &&
      this.props.location.hash.split("#")[1]
    ) {
      var activeStep = parseInt(this.props.location.hash.split("#")[1]);
      this.setState({ active: activeStep, hashRoute: activeStep });
    }
  }
  render() {
    const {
      classes,
      tabs,
      direction,
      color,
      horizontal,
      alignCenter,
      round,
      overflowY,
      variant
    } = this.props;
    const flexContainerClasses = classNames({
      [classes.flexContainer]: true,
      [classes.flexContainerLarge]:this.props.largeNavPill ? true : false,
      [classes.horizontalDisplay]: horizontal !== undefined,
      [classes.overflowY]: overflowY !== undefined
    });
    const tabButtons = (
      <>
      {/* {!sevaApp() && <Tabs */}
      { <Tabs
        classes={{
          root: classes.root,
          fixed: classes.fixed,
          flexContainer: flexContainerClasses,
          indicator: classes.displayNone
        }}
        value={this.state.active}
        onChange={this.handleChange}
        centered={!variant ? alignCenter : false}
        variant={variant ? "scrollable" : "standard"}
        scrollButtons="on"
      >
        {tabs.map((prop, key) => {
          var icon = {};
          if (prop.tabIcon !== undefined) {
            icon["icon"] = <prop.tabIcon className={classes.tabIcon} />;
          }

          if (prop.tabCustomIcon !== undefined) {
            icon["icon"] = (
              <img src={prop.tabCustomIcon} width="30" height="30" />
            );
          }
          if (prop.tabImage !== undefined) {
            // let res = await fetch((prop.tabImage))
            // var svg = await res.text()
            // console.log(svg)
            icon["icon"] = (
              <ReactSVG
                src={prop.tabImage}
                style={{ width: 40, height: 40 }}
              ></ReactSVG>
            );
          }
          const pillsClasses = classNames({
            [classes.round]: round,
            [classes.pills]: true,
            [classes.horizontalPills]: horizontal !== undefined,
            [classes.pillsWithIcons]:
              prop.tabIcon !== undefined || prop.tabImage !== undefined
          });
          return (
            <Tab
              label={<h2 style={{fontSize:"12px"}}>{prop.tabButton}</h2>}
              key={key}
              {...icon}
              classes={{
                root: pillsClasses,
                labelContainer: classes.labelContainer,
                label: classes.label,
                selected: classes[color]
              }}
            />
          );
        })}
      </Tabs>} 
      </>
    );
    const tabContent = (
      <div className={classes.contentWrapper}>
        {/* {!sevaApp() && <SwipeableViews */}
        { <SwipeableViews
          axis={direction === "rtl" ? "x-reverse" : "x"}
          index={this.state.active}
          onChangeIndex={this.handleChangeIndex}
          style={{ overflowY: "hidden" }}
        >
          {tabs.map((prop, key) => {
            return (
              <div className={classes.tabContent} key={key}>
                {prop.tabContent}
              </div>
            );
          })}
        </SwipeableViews>}
      </div>
    );
    return horizontal !== undefined ? (
      <GridContainer>
        <GridItem {...horizontal.tabsGrid}>{tabButtons}</GridItem>
        {this.props.navPillTitle && <GridItem>{this.props.navPillTitle}</GridItem>}
        <GridItem {...horizontal.contentGrid}>{tabContent}</GridItem>
      </GridContainer>
    ) : (
      <div>
        {tabButtons}
        {tabContent}
         {this.props.navPillTitle && <GridItem>{this.props.navPillTitle}</GridItem>}
         
        
      </div>
    );
  }
}

NavPills.defaultProps = {
  active: 0,
  color: "primary"
};

NavPills.propTypes = {
  classes: PropTypes.object.isRequired,
  // index of the default active pill
  active: PropTypes.number,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      tabButton: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      tabIcon: PropTypes.node,
      tabContent: PropTypes.node,
      tabImage: PropTypes.node
    })
  ).isRequired,
  color: PropTypes.oneOf([
    "primary",
    "warning",
    "danger",
    "success",
    "info",
    "rose",
    "white"
  ]),
  direction: PropTypes.string,
  horizontal: PropTypes.shape({
    tabsGrid: PropTypes.object,
    contentGrid: PropTypes.object
  }),
  alignCenter: PropTypes.bool,
    round: PropTypes.bool,
  colorCode: PropTypes.string,
  variant: PropTypes.bool,
  changeMetaTag: PropTypes.func
};

export default withStyles(navPillsStyle)(NavPills);
